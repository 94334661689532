import React,{Fragment} from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import ListReviewer from '../../../utils/components/listReviewer';
import capitalize from 'lodash/capitalize';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';
import RateText from '../../../utils/components/rate_text';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import { Listbox, Transition } from '@headlessui/react'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { arry_of_strings_to_text } from '../../../utils/text';

class ReconSummary extends React.PureComponent<any,any>{
    items: any[];
    private _ref: any;
    is_express: boolean;


    constructor(props:any){
        super(props);
        this.items = [];
        ['beliefs','thoughts','emotions','actions'].map((key:string,index:number)=>{
            let options:any[] = [];
            Object.keys(this.props[key]).map((key2:any)=>{
                options.push({
                    id:key2,
                    // icon:key==='emotions'?key2:null,
                    title:`${capitalizeFirstLetter(id_to_text(key2))}.`,
                    intensity:this.props[key][key2],
                });
            });
            this.items.push({
                id:key,
                titles:index===0?[`Your new ${key}`,'Commitment']:[`Your new ${key}`],
                options:options.sort((a,b)=>b.intensity-a.intensity)
            });
        });
        this.state = {
            value:this.props.ratings
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself')


        this._ref = null;

    };

    _set_ref = (ref:any) => this._ref = ref;

    _print_page = async() => {
        const content = this._ref;

        if (!content) {
          return;
        }
    
        

        const printWindow = window.open('', '', 'width=600,height=600');

        if(!printWindow){
            return;
        };
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        const stylesheets = document.getElementsByTagName('link');

        for (let i = 0; i < stylesheets.length; i++) {
          const stylesheet = stylesheets[i];
          if (stylesheet.getAttribute('rel') === 'stylesheet') {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            // @ts-ignore
            link.href = stylesheet.getAttribute('href');
            printWindow.document.head.appendChild(link);
          }
        }
      
        
        printWindow.document.write('<div style="margin: 0; padding: 0;">');
        printWindow.document.write(content.innerHTML);
        printWindow.document.write('</div>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
       
    };

    _on_pdf = async(print?:boolean) => {
        const pdfContent = this._ref;

        if (!pdfContent) {
          return;
        }
        const opt = {
            margin: [15,10,15,10],
            filename: 'new_way_to_be.pdf',
            pagebreak:{
                mode:['avoid-all','css','legacy']
            },
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true }, // Enable this if images are from external URLs
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };
      
          const customCss = `
           .span_list {
                display: flex !important;
                margin-bottom: 15px !important;
            }
            .new_way_to_bg_items span {
                margin-top:-10px !important;
            }
            .pl_text {
                padding-top:20px !important;
            }
            .pdf_title_class {
                margin-top:5px !important;
                margin-bottom: 15px !important;
            }
            .rate_slider {
                width: 280px !important;
            }
            .logo_container_pdf {
                display: flex !important;
            }
            .title_list_viewer {
                font-weight: bold !important;
            }
            .header_container {
                margin-top: 15px !important;
            }
            .descriptoin_container {
                margin-top: 15px !important;
                margin-bottom: 15px !important;

            }
           
          `;

            const logoContainer = document.createElement('div');
            logoContainer.className = 'self-end logo_container_pdf';
            const logoImg = document.createElement('img');
            logoImg.className = 'h-10';
            logoImg.src = require('../../../../../assets/logo.png');
            logoContainer.appendChild(logoImg);
      
            const styleElement = document.createElement('style');
            styleElement.innerHTML = customCss;

            const clonedContent = pdfContent.cloneNode(true) as HTMLDivElement;


            clonedContent?.prepend(styleElement, logoContainer);
         
            
            if(print){
                // window.print();
                this._print_page();
            }else{
                html2pdf().from(clonedContent).set(opt).save();
            }


    };

    _on_change = (id:string, value:number) => {
        this.setState({value});
    }


    _next = () => {
        return {
            go: true,
            value:{
                ratings:this.state.value
            }
        };
    }
    getPageMargins = () => {
        return `@page { margin: 15px !important; background: }
                @media print {
                    .rate_slider {
                        width: 280px !important;
                    }
                    .logo_container_print {
                        display: flex !important;
                    }
                  
                }`;
    };

    render(): React.ReactNode {
        return (
            <div  className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
               <ReactToPrint
                content={() => this._ref}
                >
            <PrintContextConsumer>
            {({ handlePrint }) => (
               <DownloadBtn onPdf={(print:boolean)=>!print?this._on_pdf():handlePrint()}/>

            //   <button onClick={handlePrint}>Print this out!</button>
            )}
          </PrintContextConsumer>
            </ReactToPrint>
                <div ref={this._set_ref} id="new_way_to_bg" className='w-full new_way_to_bg_items flex flex-col'>
                <style>{this.getPageMargins()}</style>
                        <div className='self-end  hidden logo_container_print'>
                                <img className='h-10' src={require('../../../../../assets/logo.png')}/>
                        </div>
                    
                        <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">{this.is_express?'New Approach Summary':'New Way To Be'}</span>

                        <span className="font-quick text-text text-[12px] leading-[17px] flex font-medium flex-col">
                       {this.is_express?
                        <>
                            Here are 3 tips to follow to help you align to this new approach:
                            <br />
                            <br />
                            <span className='mb-1 span_list'>1. Continue to reinforce your new approach by imagining and rehearsing in your mind you being this way leading up to the circumstance.</span>
                            <span className='mb-1 span_list'>2. Seek advice from trusted sources where you feel you need further mentoring or coaching to be this way.</span>
                            <span className='mb-1 span_list'>3. Where relevant, practice this approach with someone you trust for feedback.</span>
                            {/* <ul className='list-decimal ml-5'>
                                <li className='mb-1 list_down_item'>Continue to reinforce your new approach by imagining and rehearsing in your mind you being this way leading up to the circumstance.</li>
                                <li className='mb-1 list_down_item'>Seek advice from trusted sources where you feel you need further mentoring or coaching to be this way.</li>
                                <li className='mb-1 list_down_item'>Where relevant, practice this approach with someone you trust for feedback. </li>
                            </ul> */}
                        </>
                        :
                        <>
                            Great work! 
                            <br />
                            <br />
                            You have reconstructed an empowering new version of yourself to create a breakthrough in this circumstance.
                            <br />
                            <br />
                            Please review this summary of your empowering beliefs, thoughts, emotions, and actions. As you do, think about how committed you feel to being this new way.
                            <br />
                        </>
                        }
                        {/* <br /> */}
                        {/* <span className=' font-bold'>Tip: For this Demo version, please print or screenshot this summary for your ongoing reference.</span> */}
                        </span>
                        <div className='flex w-full flex-col header_container'>
                            <span className='font-quick font-bold text-title text-[12px] leading-[17px] flex mt-2'>Circumstance Title</span>
                            <div className='bg-bg border border-br-gray p-4 rounded-2xl mt-[9px]'>
                                <span className='font-quick text-[13px] leading-[20px] flex text-text font-medium'>
                                {/* ${external_they_keys.includes(this.props.domain)?'my':'my'} */}
                            {this.is_express?`My ${this.props.theme} in a ${this.props.theme_parent} context relating to ${this.props.subtheme} that will happen in ${this.props.duration}.`:`${capitalize(this.props.domain)} relating to my ${this.props.subdomain} defined by themes: ${`${this.props.theme}`.toLowerCase()}, ${this.props.subtheme.toLowerCase()}.`}
                                {/* {`My ${this.props.domain}, specifically related to my ${this.props.subdomain} is affected by ${this.props.theme} that is associated with ${this.props.subtheme}.`} */}
                                </span>
                            </div>
                            <div className='descriptoin_container flex flex-col'>
                                <span className='font-quick font-bold text-text text-[12px] leading-[17px] flex mt-4'>Description</span>
                                <div className='flex flex-col header_container'>
                                    <span className=' font-raleway flex text-[13px] leading-[17px] font-medium text-sub-text mt-2'>
                                    {this.props.summary_fetched}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ListReviewer items={this.items}/>

                        <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4 mb-4 pl_text">{this.is_express?'Please rate how helpful this preparation process was for you.':`Please rate how well this list of empowering beliefs, thoughts, emotions, and actions represents how you want to be in this circumstance:`}</span>
                        <RateText no_color defaultRate={this.props.ratings} is_helpfulness no_select rl_text="Rate how relevant:" id="text" onChange={()=>{}} onChangeRate={this._on_change}/>

                </div>
                {/* <div   onClick={this._on_pdf} className='mt-5 bg-sub-green p-1.5 px-2.5 space-x-1 rounded-full flex flex-row items-center justify-center self-end cursor-pointer'>
                    <img className='w-3 mr-1' src={require('../../../../../assets/icons/download_black.png')} />
                    <span className=' text-[0.6rem] font-semibold text-green '>Download</span>
                </div> */}
               {/* <DownloadBtn onPdf={this._on_pdf}/> */}
               <div className='relative flex items-end justify-end pb-10 w-full'>
                    <ReactToPrint
                                content={() => this._ref}
                                >
                                    <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                    <DownloadBtn onPdf={(print:boolean)=>!print?this._on_pdf():handlePrint()}/>

                                    //   <button onClick={handlePrint}>Print this out!</button>
                                    )}
                                </PrintContextConsumer>
                        </ReactToPrint>
              </div>

                {/* <div id="cloned-container" className=''></div> */}
            </div>
        );
    };
};

// {id:'print',label:'Print'}
const options = [ { id:'pdf',label:'Download PDF'},{id:'print',label:'Print'}];

const DownloadBtn = ({onPdf}:any) => {

    const _render_lists = (item:any) => {
    
        return (
            <Listbox.Option
                key={item.id}
                value={item.id}
            >
                
                {({ selected }) => (
                    <div className={`w-full p-2  flex items-start flex-row rounded-full cursor-pointer ${selected?'bg-sub-btn':''}`}>
                        {/* {selected?<img className='w/-3 h-3 ml-1 mr-2 mt-1' src={require('../../../../../assets/icons/drop_down_selected.png')}/>:null} */}
                        <span className={`flex font-quick text-text text-[12px] leading-[17px] font-medium`}>
                                {item.label}
                        </span>
                    </div>
                )}
            </Listbox.Option>
        )
    };

    const _on_change = (val:any) => {
         if(val==='pdf'){
            return onPdf();
        };
        if(val==='print'){
            return onPdf(true)
        };
    };

    return (
        <div className='flex self-end'>
            <Listbox onChange={_on_change}>
                <Listbox.Button  className='bg-sub-green p-1.5 px-2.5 space-x-1 rounded-full flex flex-row items-center justify-center self-end cursor-pointer'>
                    <img className='w-3 mr-1' src={require('../../../../../assets/icons/download_black.png')} />
                    <span className=' text-[0.6rem] font-semibold text-green '>Download</span>
                </Listbox.Button>
                 <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Listbox.Options className="absolute z-50 mt-5 max-h-44 overflow-auto rounded-2xl bg-bg container_shadow p-2  ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {options.map(_render_lists)}
                    </Listbox.Options>
                 </Transition>

            </Listbox>
        </div>
    )
}


const convert_array_to_object = (array:string[])=>{
        const obj:any = {};
        for (const str of array) {
            obj[str as any] = 1;
        };
        return obj;
};


const map_state_to_props = (state: RootState) => {
    return {
        beliefs: {
            ...state.app['recon_belief']
            // 'i_have_the_time_to_connect_fully_to_begin_the_process_of_understanding_the_situation_and_reasons_others_are_against_me_as_this_is_going_against_their_good_nature_by_being_attentive_to_their_reasons_and_suspending_judgement_this_will_provide_opportunities_for_better_treatment_to_be_restored':state.app['recon_belief']?.rate
        },
        thoughts:{
            ...state.app["recon_thoughts"],
        },
        emotions:state.app?.circgen_type?.selected==='prepare_myself'?convert_array_to_object(state.app['recon_empowering_emotions'].emotional_rating):{
            ...state.app['recon_empowering_emotions_rate']||{}
        },
        actions:{
            ...state.app["recon_empowering_actions"],
        },

        domain:id_to_text(state.app['circgen_vision_wheel']?.struggle_intense||''),
        subdomain:state.app['circgen_vision_wheel']?.focus?.replaceAll("_","-"),
        theme:id_to_text(state.app['circgen_theme']?.theme?.id||''),
        theme_parent:state.app?.circgen_type?.selected==='prepare_myself'?state.app['circgen_theme']?.theme?.parent:'',
        subtheme:arry_of_strings_to_text(id_to_text(state.app['circgen_theme']?.subtheme||'')),
        // recon_summary
        summary_fetched:state.response['summary_fetched']?.response||state.response['summary_fetched'],

        ratings:state.app['recon_summary']?.ratings||1,
        circ:state.app?.circgen_type?.selected
    };
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(ReconSummary);