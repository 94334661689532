import React from 'react';
import {motion} from 'framer-motion';

interface IOption {
    id:string,
    label:string
};

interface IProps {
    options:IOption[],
    selected:string[],
    defaultValue?:string[],
    onChange:(selected:string[])=>void,
    show_count?:boolean,
    single?:boolean,
    count_prefix?:string,
    label?:string
    small?:boolean
};


const InlineMultiSelect = ({options,count_prefix,show_count,single,label,defaultValue,onChange,small}:IProps) => {

    const [selected,setSelected] = React.useState<string[]>(defaultValue||[]);

    const _on_select = (id:string) => {
        if(single){
            onChange([id]);
            setSelected([id]);
            return;
        };
        if(selected.includes(id)){
            const _selected = selected.filter((item)=>item !== id);
            setSelected(_selected);
            onChange(_selected);
        }else{
            onChange([...selected,id]);
            setSelected([...selected,id]);
        }
    };

    const _render_option = (item:IOption) => {
        return <Option {...item} key={item.id} small={small} selected={selected.includes(item.id)} onChange={_on_select} />
    };
    return (
        <div className='flex flex-col'>
            {show_count?<span className=' font-raleway text-[10px] leading-[12px] text-title'>{selected.length} of {options.length} {count_prefix||''}</span>:null}
            {label?<span className='font-quick text-title text-[13px] leading-[16px] -mb-1 font-medium'>{label}</span>:null}
            <div className='flex flex-row flex-wrap pt-3'>
                {options.map(_render_option)}
            </div>
        </div>
    )
};


const Option = ({id,label,onChange,selected,small}:IOption&{onChange:(id:string)=>void,selected:boolean,small?:boolean}) => {

    const _on_change = (ev:any) => {
        onChange(id);
        ev.stopPropagation();
        ev.preventDefault();
    };

    return (
        <motion.div layout onClick={_on_change} className={`flex flex-row items-center border ${small?'px-5 py-[5.5px]':'px-7 py-[7.5px]'} cursor-pointer ${selected?'pl-2.5 bg-sub-btn border-br-green':'pl-7 border-br-light-cream'} rounded-full mr-1.5 mb-1.5`}>
            {selected?<motion.img initial={{opacity:0,scale:0}} animate={{opacity:1,scale:1}} layout src={require('../../../../assets/icons/selected.png')} className="w-3 mr-1.5"/>:null}
            <span className='text-text font-quick text-[12px] leading-[17px] flex font-medium'>{label}</span>
        </motion.div>
    );
};            

export default InlineMultiSelect;
