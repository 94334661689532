import React, { useEffect } from 'react';
import { Routes, Route,BrowserRouter, useLocation,useNavigationType,createRoutesFromChildren,matchRoutes, Navigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { default_path, home_path, login_path, privacy_info } from './utils/navigation/path';
import Fallback from './common/componenets/fallback';
import RequireAuth from './utils/wrappers/require_auth';
import { Hub, Logger } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { on_user_login, on_user_logout } from './db/redux/features/auth/auth.slice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { clear_state, set_state } from './db/redux/features/app/app.slice';
import PrivacyInfo from './screens/privacy_info';
import { clear_response_state, propogate_response_state } from './db/redux/features/gpt_response/response.slice';
// import { get_last_db } from './utils/dynamoDB/fetch';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clear_prompt_state } from './db/redux/features/prompt_meta/prompt_meta.slice';


const Login = React.lazy(()=>import('./screens/login'));
const Home = React.lazy(()=>import('./screens/home'));

const App = () => {
    const dispatch = useDispatch();

    const _handle = async(payload:any,data:any) => {
        // const item:any = await get_last_db(payload.data.username);
        // if(item){
        //     let new_item:any = {...item.app};
        //     let new_response:any = {...item.gpt};
       
        //     delete new_item.created_at;
        //     delete new_item.id;
        //     delete new_item.update_at;
        //     delete new_item.userID;

        //     delete new_response.session_id;

        //     new_item = {
        //         ...new_item,
        //         session_seed:item.app.id
        //     };

        //     console.log(new_item);
        //     console.log(new_response);
        //     dispatch(set_state(new_item));
        //     dispatch(propogate_response_state(new_response));
        // }
        const accepted_terms = data.payload&&data.payload.data&&data.payload.data.attributes?Boolean(data.payload.data.attributes['custom:accepted']):false;
        dispatch(on_user_login({uid:payload.data.username,has_accepted_terms:accepted_terms}));
    }

    const _append_auth_screen = () => {
        // Select the form element using the specified selector
        setTimeout(()=>{
            const form = document.querySelector('[data-amplify-authenticator] [data-amplify-form]');
            if (form) {
            const spanElement = document.createElement('span');
            spanElement.classList.add('auth_screen_bold_text'); 
            spanElement.textContent = 'Can be used on other devices, but optimised for smartphone only.';
            form.insertBefore(spanElement, form.firstChild);
            }
        },500)
        

    };

    useEffect(()=>{
        // _append_auth_screen();
        return Hub.listen('auth', (data) => {
            const { payload } = data;
            // console.log(payload)
            if (payload.event === 'signIn') {
                _handle(payload,data);
            }   
            if (payload.event === 'signOut') {
                dispatch(on_user_logout());
                setTimeout(()=>{
                    dispatch(clear_state());
                    dispatch(clear_prompt_state());
                    dispatch(clear_response_state());
                //  _append_auth_screen();
                },200);
    
            }
        });
          
    });
    return (
            <BrowserRouter>
                <div className='flex justify-center items-center w-full h-full bg-white'>
                    {/* <div className='w-full md:w-[400px] lg:w-[410px] h-full md:h-[100vh] lg:h-[70vh] bg-bg'> */}
                    <div className='w-full md:w-[400px] lg:w-[410px] h-full md:h-[100vh] lg:h-[95%] xl:h-[90%] bg-bg'>
                        <AnimatedRoutes />
                        {/* <div className="flex w-full h-full flex-col justify-between overflow-hidden">
                             <div className="w-full h-full px-2.5 py-5">
                            hello
                            </div>

                            <div className='flex flex-col sticky bottom-0 z-50'>
                              <div className='w-full rounded-tl-md rounded-tr-md container_shadow bg-sub-bg flex flex-row items-center justify-between px-4 py-3' >
                                Test
                                </div>
                            </div>
                        </div> */}
                         <ToastContainer />

                    </div>
                </div>
            </BrowserRouter>
    );
};  



const AnimatedRoutes = () => {

    return (
        <AnimatePresence mode='wait'>
            <Routes>
                   <Route path={login_path} element={AuthWithSuspenseWrapper(Login)} />
                   <Route path={privacy_info} element={AuthWithSuspenseWrapper(PrivacyInfo)} />
                   <Route path={`app/*`} element={AuthWithSuspenseWrapper(Home)} />
                   <Route path={`express/*`} element={AuthWithSuspenseWrapper(Home)} />
                   <Route path='*' element={<Navigate to={login_path} replace />} />
             </Routes>
        </AnimatePresence>
    )
};


function AuthWithSuspenseWrapper(Component:React.ComponentType<any>|null):any {
    const COMP = () => (
        <RequireAuth>
            <React.Suspense fallback={<Fallback/>}>
                    {!Component?null:<Component />}
            </React.Suspense>
        </RequireAuth>

    );
    return <COMP />;
};



export default App;