import React from 'react'

class DeconBeliefsIntroduction extends React.PureComponent{
    _next = () => {
        return {
            go:true,
            value:{

            }
        }    
    };
    
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                    <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">Deconstructing your Beliefs</span>
                    <img src='https://images.unsplash.com/photo-1578301978018-3005759f48f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80' className='object-cover w-full h-40 mt-4 mb-4'/>
                    <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>Great work on deconstructing how you act. You are now up to the exciting reveal of what assumptions are making when you think, feel and act this way. Watch this quick video to help you understand your subconscious beliefs.</span>
            </div>
        )
    }
}

export default DeconBeliefsIntroduction;