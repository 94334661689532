import Amplify, {API, Auth} from 'aws-amplify';
import AWS from 'aws-sdk';
import { update_db } from '../dynamoDB/fetch';
import { store } from '../../db/redux/store';


AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: 'AKIAQHQC2ROGD7CT5EAG',
      secretAccessKey: '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',
    }),
});
const get_response = (body:{type:string,values:any,include_prompt?:boolean,isExpress?:boolean}) => {
    return new Promise(async(resolve,reject)=>{
        try{
            const lambda = new AWS.Lambda();

            const start_time = new Date().getTime();
            // We need improve this by passing id while calling this function
            const id = store.getState().app.session_seed;
            const params = {
              FunctionName:'gptRequest-stage',//'gptRequest-main',
              Payload: JSON.stringify({ body }),
            };

            const response = await lambda.invoke(params).promise();
            // console.log(response);
            const end_time = new Date().getTime();
            const elapsed_time = (end_time - start_time)/1000;
            console.log('elapsed_time',elapsed_time);
            const time_taken_in_seconds = (end_time - start_time) / 1000;
            const payload = JSON.parse(response.Payload as string);
            const res = JSON.parse(payload.body);


            if(payload&&(payload.statusCode&&payload.statusCode!==200)){
                reject(payload);
                return;
            };

            if(body.include_prompt&&id){
                // updating the db here rather in the server side to avoid passing the session id to server, to avoid security issues and to keep annonimity
                update_db({prompt:res.prompt},id,body.type,'verve_demo_session_gpt_prompts');
            };

            if(time_taken_in_seconds <5&&body.type!=='sub_themes'){
                const delta = (5 - time_taken_in_seconds)*1000;
                setTimeout(()=>{
                    resolve(res);
                },delta);
                return;
            };
    
            resolve(res);
        }catch(e){
            // get status code
            reject(e);
        };
    }); 
};


export {
    get_response
}