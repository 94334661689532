import React from "react";


class FullImpact extends React.PureComponent<any,any>{
    _next = () => {
        return {
            go:true,
            value:{}
        };
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex flex-col overflow-scroll action_container pb-40">
                <img className="w-full rounded-tl-2xl rounded-tr-2xl h-32" src={require('../../../../assets/bg/full_impact.png')}/>
                <div className="flex flex-col p-6">
                        <span className="font-quick text-text text-[16px] leading-[20px] font-semibold">Full Impact</span>
                        <span className="font-quick text-title text-[13px] leading-[16px] flex mt-4 font-medium">We're now going to generate a specific Circumstance for this Situation to assess the full impact.</span>
                </div>
            </div>
        )
    }
};
export default FullImpact;