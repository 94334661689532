import React, { useState } from 'react';
import RateText from '../../../utils/components/rate_text';
import {motion} from 'framer-motion';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import { getHighestNumber } from '../../../../../utils/number/get_highest';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { get_response } from '../../../../../utils/request/get';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';




class BeliefRating extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;

    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        this.state = {
            loading:this.props.belief_decon_fetched&&this.props.belief_decon_fetched.length>0?false:true,
            error:false,
            options:this.props.belief_decon_fetched||[],
            selected:[...default_keys],
            status_code:0
        };
        this.selected = { 
            ...df
        };  
        this.is_express = Boolean(this.props.circ==='prepare_myself')
    };


    componentDidMount(): void {
        if(!this.props.belief_decon_fetched||(this.props.belief_decon_fetched&&this.props.belief_decon_fetched.length===0)){
            this._fetch_beliefs();
        }
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_beliefs();
    };


    _fetch_beliefs = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'decon_beliefs',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            let options:any[] = [];
            data.res.map((txt:string)=>{
                options.push({
                    id:`${txt}`.replaceAll(' ','_'),
                    label:txt
                });
            });
            console.log(data);
            this.props.update_state(options,data.prompt,data.tools);
            update_db({value:options},this.props.session_id,'belief_decon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false});
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            console.log(e);
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };


    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'belief_decon_fetched','verve_demo_session_gpt_response')
    };

    _next = () =>{
        // const value = getHighestNumber(Object.values(this.selected));

        // if(!value){
        //     alert('Should only have one highest rated belief.')
        //     return {
        //         go:false,
        //         value:{

        //         }
        //     };
        // };

        return {
            go:this.state.selected.length>0,
            value:{
                ...this.selected
            }
        }
    };


    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };

    _on_select = (id:string) => { 
        if(this.state.selected.includes(id)){
            this.setState((state:any)=>({
                selected:state.selected.filter((item:string)=>item!==id)
            }));
            delete this.selected[id];
        }else{
            this.setState((state:any)=>({
                selected:[...state.selected,id]
            }));
            this.selected[id] = 1;
        }
    };    

    _render_ratings = (item:any) => {
        return (
            <RateText is_rl defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} onChangeRate={this._on_change_rate} {...item} key={item.id} selected={this.state.selected.includes(item.id)} onChange={this._on_select}/>
        )
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                {
                    this.state.loading?
                    <PromptLoader message={`Remember, we believe things that aren't true!\nSo the beliefs for thinking, feeling and acting this way may appear illogical.\n\nThis is perfectly normal 😊`} isNew={false} title='Deconstructing your Beliefs' image='https://images.unsplash.com/photo-1578301978018-3005759f48f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80'/>
                    :
                    // <PromptLoader message="Remember, we believe things that aren't true! So the beliefs for thinking, feeling and acting this way may appear illogical. This is perfectly normal :)"/>:
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_beliefs}/>
                    :
                <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                    <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">What You Believe</span>
                    <span className="flex flex-col font-quick text-title font-medium text-[13px] leading-[16px] mt-4 mb-4">
                        {this.is_express?
                        <>
                        Here are typical disempowering beliefs that commonly disrupt our ability to handle challenges.
                        <br />
                        <br />
                        Remember, these beliefs won’t make sense to your better judgment, but your brain is trying to protect you.
                        <br />
                        <br />
                        Do these beliefs shed light on and explain why you think, feel, and act this way? Choose the ones that do and rate the relevance of how well they explain it.
                        </> 
                        :
                        <>
                        Here are typical beliefs that commonly cause us to be this way. 
                        <br/>
                        <br/>
                        These beliefs reveal the:
                        <br />
                        <ol className='list-decimal pl-5 mt-2'>
                            <li>Many reasons that cause you to have these thoughts, feelings, and actions</li>
                            <li>Underlying assumptions that attempt to justify being this way</li>
                            <li>Disempowering extremes existing in the assumptions</li>
                            <li>Disregard for the disempowering impact this causes</li>
                        </ol>
                        <br/>
                        Therefore, don’t be shocked if you’re shocked by the absurdity of these beliefs! Your brain is trying to protect you and it genuinely believes that these extreme assumptions are true and helpful. 
                        <br />
                        <br />
                        Do these beliefs shed light on and explain why you think, feel, and act this way? Choose the ones that do and rate the relevance of how well they explain it.
                        <br />
                        <br />
                        If none of these beliefs explain it, we recommend talking this through with a trusted friend or professional.
                        </>
                        }
                        </span>
                    <motion.div layout className='space-y-2'>
                        {this.state.options.map(this._render_ratings)}
                    </motion.div>
                </motion.div>
                }
            </div>
        )
    }
};



const get_items = (i:any) => {
    const keys = Object.keys(i);
    let array:any[] = [];
    keys.map((key:string)=>{
        array.push({
            id:key,
            rate:i[key]
        });
    });
    const sorted = array.sort((a:any,b:any)=>b.rate-a.rate);
    let array2:any[] = [];
    sorted.map((item:any)=>{
        array2.push(item.id);
    });
    return array2;
};


const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["decon_belief_rating"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            actions:get_items(state.app["decon_action_relevance"]),
            thoughts:get_items(state.app["decon_thoughts_relevance"])
        },
        belief_decon_fetched:state.response['belief_decon_fetched']?.response||state.response['belief_decon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    }
};




const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'belief_decon_fetched',value}));
            dispatch(update_prompt_state({key:'belief_decon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'belief_decon_fetched',value:tools}));
         },
    }    
};




export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(BeliefRating);