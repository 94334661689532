import React, { useState } from 'react';
import {motion} from 'framer-motion';
import MultiSelect from '../../../utils/components/multiSelect';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import capitalize from 'lodash/capitalize'


const emotions = [
    {
        id:'burdended',
        label:'Burdended',
        icon:'burdended'
    },
    {
        id:'crazy',
        label:'Crazy',
        icon:'crazy'
    },
   
]

class EmotionalRatingSelectTop extends React.PureComponent<any,any>{
    options: any[];
    constructor(props:any) {
        super(props);
        const df = this.props.top;
        this.state = {
            selected:df||null
        };
        this.options = [];

        this.props.emotional_rating.map((item:any) => {
            this.options.push({
                id:item,
                icon:item,
                label:capitalize(item)
            })
        });
        

    };

    _next = () => {
        return {
            go: Boolean(this.state.selected),
            value:{
                top:this.state.selected
            }
        };
    };

    _on_change = (id:string) =>{
        this.setState({
            selected:id
        })
    }
    // const [selected,setSelected] = useState<null|string>(null);

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col justify-between">
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Please select 1 emotion you would like to focus on.</span>
                <motion.div layout className="w-full h-full flex justify-evenly flex-col">
                        <MultiSelect selected={this.state.selected} onChange={this._on_change} options={this.options}/>
                        <div />
                </motion.div>
            </div>
        );
    }
}

const map_state_to_props = (state:RootState) =>{
    return {
        emotional_rating:state.app['decon_emotions_rate']?.emotional_rating,
        top:state.app['decon_emotions_top_emotion']?.top
    }
}
export default connect(map_state_to_props,null,null,{forwardRef:true})(EmotionalRatingSelectTop);