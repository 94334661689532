import React, { ReactNode, useState } from "react";
import {motion} from 'framer-motion';
import HorizontalSlider from "../../utils/components/HorizontalSlider";
import { connect } from "react-redux";
import { RootState } from "../../../../db/redux/store";
import { subdomains,struggle_from,struggle_intense_external_domains,struggle_intense_internal_domains } from "../../../../utils/vision_wheel";
// import MultiSelect from "../../utils/components/multiSelect";
import InlineMultiSelect from "../../utils/components/inlineMultiSelect";


class VisionWheel extends React.PureComponent<any,any>{
    private _focus_container: any;
    private _parent_container: any;
    constructor(props:any){
        super(props);
        this.state = {
            struggle_from:this.props.struggle_from||null,
            struggle_intense:this.props.struggle_intense||null,
            focus:this.props.focus||null,
        }
    };

    _set_focus_ref = (ref:any) => this._focus_container = ref;
    _set_parent_ref = (ref:any) => this._parent_container = ref;

    _next = () => {
        return {
            go: Boolean(this.state.struggle_from&&this.state.struggle_intense&&this.state.focus),
            value:{
                ...this.state
            }
        };
    };
    // const scrollOffset = this._focus_container.offsetTop - this._parent_container.offsetTop;
    // this._parent_container.scrollTo({
    //   top: scrollOffset,
    //   behavior: 'smooth'
    // });
    scrollToDiv = (id:string) => {
        const element = document.getElementById(id);
        const offset = (element?.offsetTop||0) - this._parent_container.offsetTop;
        if(offset){
            this._parent_container.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        };
    };

    _on_value_change = (key:string,value:string|null) => {
        requestAnimationFrame(()=>{
            if(key==='struggle_from'){
                this.setState({
                    struggle_from:value,
                    struggle_intense:null,
                    focus:null
                });
                setTimeout(()=>{
                    this.scrollToDiv('intense')
                },100);
                return;
                // intense
            };
            if(key==='struggle_intense'){
           
                this.setState({
                    struggle_intense:value,
                    focus:null
                });
                setTimeout(()=>{
                    this.scrollToDiv('focus_section')
                },100);
                return;
            };
            this.setState({
                [key]:value
            });
        });
    };


    render(): ReactNode {
        return (
            <div  ref={this._set_parent_ref} className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40">
                <StruggleFrom defaultValue={this.props.struggle_from} onChange={this._on_value_change}/>
                {this.state.struggle_from&&<StruggleIntense defaultValue={this.props.struggle_intense} key={this.state.struggle_from} struggle={this.state.struggle_from}  onChange={this._on_value_change}/>}
                {this.state.struggle_intense&&<div ref={this._set_focus_ref}>
                    <Focus struggleFrom={this.state.struggle_from} defaultValue={this.state.focus}  key={this.state.struggle_intense} domain={this.state.struggle_intense} onChange={this._on_value_change}/>
                </div>}
            </div>
        )
    }
}

const map_state_to_props = (state:RootState) => {
    return {
        ...state.app['circgen_vision_wheel']
    }
};
export default connect(map_state_to_props,null,null,{forwardRef:true})(VisionWheel);

const focuses = [
    {
        id:'self_worth',
        image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=849.5&q=80',
        label:'Self Worth'
    },
    {
        id:'self_love',
        image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        label:'Self Love'
    }
];

const Focus = ({onChange,domain,defaultValue,struggleFrom}:{struggleFrom:string,defaultValue:string, domain:string,onChange:(key:string,id:string|null)=>void}) => {
    const _on_change = (id:string[]) => {
        if(id.length<=0){
            return;
        };
        onChange('focus',id[0]);
    };

    const _on_change_id = (id:string|null) => {
        onChange('focus',id);
    };

    return (
        <motion.div id="focus_section"  layout animate={{opacity:1}} exit={{opacity:0,y:100}}  className="flex flex-col border-b pb-4 border-dashed border-br-cream pt-4 w-full">
        <span className={`font-quick text-text text-[13px] leading-[16px] font-medium ${struggleFrom ==='my_inner_self'?'mb-5':''}`}>What area would you like to focus on?</span>
        {
            struggleFrom ==='my_inner_self'?
            <HorizontalSlider defaultValue={defaultValue} onChange={_on_change_id} options={subdomains[domain as keyof typeof subdomains]?subdomains[domain as keyof typeof subdomains]:[]}/> 
            :
            <InlineMultiSelect single show_count={false} options={subdomains[domain as keyof typeof subdomains]?subdomains[domain as keyof typeof subdomains]:[]} defaultValue={[defaultValue]} selected={[]} onChange={_on_change} />
            // <MultiSelect selected={defaultValue} onChange={_on_change} options={subdomains[domain as keyof typeof subdomains]?subdomains[domain as keyof typeof subdomains]:[]}/>
        }
        </motion.div>    
    )
};




const StruggleIntense = ({onChange,struggle,defaultValue}:{defaultValue:string, struggle:string, onChange:(key:string,id:string|null)=>void}) => {

    const _on_change = (id:string|null) => {
        onChange('struggle_intense',id);
    };

    return (
        <motion.div id="intense" layout animate={{opacity:1}} exit={{opacity:0,y:30}} initial={{opacity:0}} className="flex flex-col border-b pb-4 border-dashed border-br-cream pt-4 w-full">
            <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Where is the struggle most intense?</span>
            <HorizontalSlider defaultValue={defaultValue} onChange={_on_change} options={struggle==='my_inner_self'?struggle_intense_internal_domains:struggle_intense_external_domains}/>
        </motion.div>    
    );
};





const StruggleFrom = ({onChange,defaultValue}:{defaultValue:string,onChange:(key:string,id:string)=>void}) => {

    const [selected,set_selected] = useState<string|null>(defaultValue||null);

    const _on_change = (id:string) => {
        if(id===selected){
            return;
        };
        onChange('struggle_from',id);
        set_selected(id);
    };

    const _render_card = (item:typeof struggle_from[0]) => {
        return <StruggleCard {...item} key={item.id} selected={selected===item.id} onChange={_on_change}/>
    };

    return (
        <div className="flex flex-col border-b pb-4 border-dashed border-br-cream">
             <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Where is the struggle or challenge starting from?</span>
             <div className="w-full flex flex-row justify-between space-x-1.5 mt-4">
                 {struggle_from.map(_render_card)}
             </div>
        </div>
    );
};


const StruggleCard = ({id,label,description,image,onChange,selected}:typeof struggle_from[0]&{onChange:(id:string)=>void,selected:boolean}) => {

    const on_click = () => {
        onChange(id);
    };

    return (
        <motion.div layout onClick={on_click} className={`flex flex-col border rounded-2xl w-[49%] border-br-gray relative ${selected?'border-br-green':''} cursor-pointer`}>
                {!selected?null:<motion.img animate={{scale:1,opacity:1}} initial={{scale:0,opacity:0}} layout className="w-5 h-5 absolute -left-1.5 -top-1.5 bg-white" src={require('../../../../assets/icons/selected_circle.png')}/>}
                <img className="m-[5px] w-[9rem] flex self-center" src={image}/>
                <div className="px-2 py-4 pt-2">
                    <span className="flex font-quick text-[13px] leading-[16px] text-text font-medium">{label}</span>
                    <span className="flex font-raleway text-[12px] leading-[14px] text-title opacity-80 font-medium">{description}</span>
                </div>
        </motion.div>
    );
};