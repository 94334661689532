import React from 'react';
import {motion} from 'framer-motion';


const error_codes = [401, 429, 500, 503];
const PromptError = ({onRetry,statusCode}:{statusCode:number, onRetry?:any}) => {

    const _on_re_try = () => {
        onRetry&&onRetry();
    };

    const message  = error_codes.includes(statusCode)?statusCode===503? `Our provider is currently experiencing a usage overload.\n\nPlease wait a few moments, and then click “Try again” below.\n\nIf this issue is ongoing, please return to your circumstance later.`:`Our servers are currently experiencing an outage.\nPlease wait a few moments, and then click “Try again” below.\n\nIf this issue is ongoing, please return to your circumstance later.`:'An error has occurred. Please try again.';
    
    return (
        <motion.div layout animate={{opacity:1}} initial={{opacity:0}} className='flex w-full h-full justify-center items-center flex-col'>
            <span className='font-raleway text-sub-text text-[12px] leading-[14px] text-center whitespace-pre-wrap px-5'>{message}</span>
            <span onClick={_on_re_try} className='font-raleway text-danger font-bold text-[12px] leading-[14px] mt-2'>Try again.</span>
        </motion.div>
    );
};


export default PromptError;