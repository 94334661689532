import React, { useState } from "react";
import MultiSelect from "../../utils/components/multiSelect";
import {motion} from 'framer-motion';
import { connect } from "react-redux";
import { RootState } from "../../../../db/redux/store";
import { Dispatch } from "redux";
import { clear_state, set_session_seed } from "../../../../db/redux/features/app/app.slice";
import { add_db } from "../../../../utils/dynamoDB/fetch";
import Logout from '../../../../assets/icons/exit.png';
import { clear_obslate_response, clear_response_state } from "../../../../db/redux/features/gpt_response/response.slice";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { clear_prompt_state } from "../../../../db/redux/features/prompt_meta/prompt_meta.slice";

function generateUid() {
    return Math.random().toString(36).substr(2, 9);
}


const express = [
    {
        id:'prepare_myself',
        label:'Prepare Myself',
        disabled:false
    },
]

const circumstances = [
    {
        id:'new_circumstance',
        label:'New Circumstance',
        disabled:false
    },
    // {
    //     id:'revise_existing_circumstance',
    //     label:'Revise Existing Circumstance',
    //     disabled:true
    // },
    // {
    //     id:'setback_circumstance',
    //     label:'Setback Circumstance',
    //     disabled:true

    // },
    // {
    //     id:'revise_setback_circumstance',
    //     label:'Revise Setback Circumstance',
    //     disabled:true

    // }
]
 class SelectCircumstance extends React.PureComponent<any,any> {
    constructor(props:any) {
        super(props);
        const df = this.props.selected;
        this.state = {
            selected: df||null
        }
    };


    _next = () =>{
        if(this.state.selected){
            const seed_id = generateUid(); 
            this.props.set_session_seed(seed_id);
            add_db({userID:this.props.user_id,created_at:+new Date(),update_at:+new Date(),circgen_type:{selected:this.state.selected}},seed_id)
        };
        return {
            go: Boolean(this.state.selected),
            value:{
                selected:this.state.selected,
                forceStep:this.state.selected==='new_circumstance'?true:false,
            }
        };
    }

    _set_selected = (id:string) => {
        this.setState({selected:id},()=>{
                this.props.forceGo(id==='prepare_myself'?true:false);
        });
    };

    _confirm_clear = () => {
        return new Promise(async(resolve,reject)=>{
            confirmAlert({
                title: 'Are you sure you want to clear the selection?',
                message: `Please note that clearing this selection will also remove the previous selections you made.`,
                buttons: [
               
                  {
                    label: 'Yes, Clear',
                    onClick: () => {
                        resolve(true);
                    }
                  },
                 
                  {
                    label: 'No',
                    className:'!bg-white !border !text-black !border-black !ring-1 !ring-black',
                    onClick: () => {reject()}
                  }
                ]
              });
        })
    };      
    
    _clear = async() => {
        // console.log(this.props.previous_check);
        try{
            if(this.state.selected) {
                if(this.state.selected==='prepare_myself'&&this.props.previous_check.express){
                    await this._confirm_clear();
                };
                if(this.state.selected==='new_circumstance'&&this.props.previous_check.full){
                    await this._confirm_clear();
                };
            };
            this.setState({selected:null});
            this.props.clear_app();
            this.props.clear_res();
            this.props.clear_prompt();
        }catch(e){
        }
    }

    render(): React.ReactNode {
        return(
            <div  className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40"> 
            {/* className="w-full h-full flex px-4 py-6 flex-col justify-between"> */}
                <span className="font-quick text-text text-[14px] leading-[17px] font-medium">
                    <span className="font-bold">Tip:</span> Only choose circumstances that are very important to you.
                    <br />
                    <br />
                    Generate a new circumstance:
                </span>
                <motion.div layout className="w-full flex  flex-col !pt-2 ">
                        <MultiSelect selected={this.state.selected} onChange={this._set_selected} options={circumstances}/>
                        <span className="text-xs text-text font-raleway mt-6 whitespace-pre-wrap">
                            <span className=" font-bold mb-3">When generating a new circumstance</span>
                            <br />
                            We highly recommend you treat each circumstance journey like a powerful, personal and private coaching session that can take up to 45 minutes to complete.
                        </span>

                        <span className="font-quick text-text text-[14px] leading-[17px] font-medium mt-4 mb-2">Prepare for a meeting, interview, or challenging situation or conversation:</span>
                        <MultiSelect selected={this.state.selected} onChange={this._set_selected} options={express}/>


                        {/* <div className="w-10 h-10 bg-red-400"/> */}
                        <div />
                </motion.div>
                <motion.div layout className="mt-4">   
                    {!this.state.selected?null:<span onClick={this._clear} className="flex  text-danger font-raleway text-xs cursor-pointer">Clear selection</span>}
                </motion.div>  
                <span className="text-xs text-text font-raleway mt-2 whitespace-pre-wrap">
                    <span className=" font-bold mb-3">When preparing yourself</span>
                    <br />
                    Please allow at least 15 minutes to complete this section to adequately prepare yourself.
                    {/* <br />
                    <br />
                    <span className=" font-bold mb-3">Exiting the Demo on a smartphone</span>
                    <br />
                    Come back to your circumstance later, or clear your data Please note that if you need to take a break or leave the Demo at any time while working through a circumstance and would like to finish the circumstance you are working through later, please just close your phone internet browser window (do not log out). This will keep the data you have entered up until the point you leave.
                    <br />
                    <br />
                    To clear the data you have entered please use the
                    <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> icon. But remember, this means you'll have to start a new circumstance when you log back in.
                    <br />
                    <br />
                    <span className=" font-bold mb-3">Exiting the Demo on a computer</span>
                    <br />
                    Come back to your circumstance later, or clear your data Please note that if you need to take a break or leave the Demo at any time while working through a circumstance and would like to finish the circumstance you are working through later, please click the red <span className=" text-red-700">ⓧ</span> on your browser window (do not log out). This will keep the data you have entered up until the point you leave.
                    <br />
                    <br />
                    To clear the data you have entered please use the
                    <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> icon. But remember, this means you'll have to start a new circumstance when you log back in. */}

                    {/* <span className=" font-bold mb-3">Exiting the Demo</span>
                    <br />
                     Please note that if you need to take a break or leave the Demo at any time while working through a circumstance and would like to finish the circumstance you are working through later, please click the red <span className=" text-red-700">ⓧ</span> on your browser window.
                    <br />
                    <br />
                    This will keep the data you have entered up until the point you leave.
                    <br />
                    <br />
                    To clear the data you have entered, please use the  
                    <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> icon. But remember, this means you'll have to start a new circumstance when you log back in.
                     */}
                </span>


            </div>
        )
    }
};

const map_state_to_props = (state:RootState) => {
    return {
        selected:state.app['circgen_type']?.selected,
        previous_check:{
            full:state.app['circgen_vision_wheel'],
            express:state.app['circgen_theme']
        },
        user_id:state.auth.uid
    }
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
        set_session_seed:(seed:string)=>dispatch(set_session_seed(seed)),
        clear_app:()=>dispatch(clear_state()),
        clear_res:()=>dispatch(clear_response_state()),
        clear_prompt:()=>dispatch(clear_prompt_state())

    }
};

export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(SelectCircumstance);