import React from 'react';
import ListReviewer from '../../../utils/components/listReviewer';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import capitalize from 'lodash/capitalize'
import id_to_text from '../../../utils/text/id_to_text';





// const items = [
//     {
//         id:'emotion_intensity',
//         titles:['Emotion','Intensity'],
//         options:[
//             {
//                 id:'burdended',
//                 icon:'burdended',
//                 title:'Burdended',
//                 intensity:7,
//                 mainFocus:true
//             },
//             {
//                 id:'crazy',
//                 icon:'crazy',
//                 title:'Crazy',
//                 intensity:5
//             }
//         ]
//     },
//     {
//         id:'other_emotions',
//         titles:['Other Emotions'],
//         options:[
//             {
//                 id:'flustered',
//                 title:'flustered',
//             }
//         ]
//     }
// ]

class EmotionalRatingReview extends React.PureComponent<any,any>{
    items: any[];
    constructor(props:any) {
        super(props);
        let options:any[] = [];
        Object.keys(this.props.rating).map((key:any) => {
            options.push({
                id:key,
                // icon:key,
                title:key==='self-doubt'?capitalize(key): capitalize(id_to_text(key).replace("-"," ")),

                // title:capitalize(key),
                intensity:this.props.rating[key],
                mainFocus:this.props.top_emotion === key
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
            if(a.mainFocus && !b.mainFocus){
                return -1;
            }else if(!a.mainFocus && b.mainFocus){
                return 1;
            }else{
                return b.intensity - a.intensity;
            }   
        });

        this.items = [
            {
                id:'emotion_intensity',
                titles:['Emotion','Intensity'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col pb-40 overflow-scroll">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Further Defining How You Feel</span>
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Please pause and appreciate what it means to you to be feeling these emotions in this circumstance.</span>
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium mt-2.5">Tuning into how you feel at this level of detail provides the foundation for understanding and better representing yourself.</span>
                <ListReviewer items={this.items}/>
            </div>
        )
    }
};


const map_state_to_props = (state:RootState) => {
    return {
        top_emotion:state.app['decon_emotions_top_emotion']?.top,
        rating:state.app['decon_emotions_rate_intensity']||{}
    };
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(EmotionalRatingReview);