import React from "react";
import InlineMultiSelect from "../../utils/components/inlineMultiSelect";
import { RootState } from "../../../../db/redux/store";
import { connect } from "react-redux";
const occurrence_options = [
    {
        id:'one_off_event',
        label:'One-off event'
    },
    {
        id:'daily',
        label:'Daily',
    },
    {
        id:'weekly',
        label:'Weekly'
    },
    {
        id:'monthly',
        label:'Monthly'
    },
    {
        id:'quarterly',
        label:'Quarterly'
    },
    {
        id:'yearly',
        label:'Yearly'
    }
]

const approximate = [
    {
        id:'with_in_last_few_days',
        label:'Within last few days'
    },
    {
        id:'with_in_last_few_weeks',
        label:'Within last few weeks'   
    },
    {
        id:'with_in_last_month',
        label:'Within last month'
    },
    {
        id:'with_in_last_few_months',
        label:'Within last few months'
    },
    {
        id:'with_in_last_year',
        label:'Within last year'
    },
    {
        id:'over_a_year_ago',
        label:'Over a year ago'
    },
    {
        id:'years_ago',
        label:'Years ago'
    },
    {
        id:'not_sure',
        label:'Not sure'
    }
];

// 1-2 hours
// 3-4 hours
// 1 day
// 2-3 days
// 4-7 days
// Up to 4 weeks
// Between 2-3 months
// Over 3 months


const express_options = [
    {
        id:'1-2_hours',
        label:'1-2 hours'
    },
    {
        id:'3-4_hours',
        label:'3-4 hours'
    },
    {
        id:'1_day',
        label:'1 day'
    },
    {
        id:'2-3_days',
        label:'2-3 days'
    },
    {
        id:'4-7_days',
        label:'4-7 days'
    },
    {
        id:'up_to_4_weeks',
        label:'Up to 4 weeks'
    },
    {
        id:'between_2-3_months',
        label:'Between 2-3 months'
    },
    {
        id:'after_3_months_time',
        label:'After 3 months time'
    }
]

class BeganAndOccurance extends React.PureComponent<any,any>{
    occurrence:any;
    apprx: any;
    is_express: boolean;

    constructor(props:any) {
        super(props);
        this.apprx = this.props.apprx||[];
        this.occurrence = this.props.occurrence|| [];

        this.is_express = Boolean(this.props.circ==='prepare_myself')
    };

    _next = () => {
        return {
            go: this.is_express? Boolean(this.apprx.length>0): Boolean(this.apprx.length>0&&this.occurrence.length>0),
            value:{
                apprx:this.apprx,
                occurrence:this.occurrence
            }
        };
    };

    _on_change = (key:any,value:any) => {
        this[key as keyof typeof this] = value;
        if(this.is_express){
            this.props.forceGo();
        }
    }

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40">
                <ApproximatelyBegan isExpress={this.is_express} defaultValue={this.props.apprx} onChange={this._on_change} />
               {this.is_express?null:<Occurrence defaultValue={this.props.occurrence} onChange={this._on_change} />}
            </div>
        )
    }
};




const map_state_to_props = (state:RootState) => {
    return {
        ...state.app['circgen_duration_occurance'],
       circ:state.app?.circgen_type?.selected
    }
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(BeganAndOccurance);





const Occurrence = ({defaultValue,onChange}:{onChange:any,defaultValue:any}) => {


    const on_change = (changes:any) => {
        onChange('occurrence',changes)  
    };

    return (
        <div className="flex flex-col border-b pb-2 border-dashed border-br-cream pt-4 w-full">
            <span className="font-quick text-text text-[13px] leading-[16px] mb-1.5 font-medium">How often is it impacting you?</span>
            <InlineMultiSelect single options={occurrence_options} defaultValue={defaultValue} selected={[]} onChange={on_change} />
        </div>
    )
};


const ApproximatelyBegan = ({defaultValue,onChange,isExpress}:{isExpress:boolean,onChange:any,defaultValue:any}) => {
    const on_change = (changes:any) => {
        onChange('apprx',changes)  
    };
    return (
        <div className="flex flex-col border-b pb-2 border-dashed border-br-cream pt-4 w-full">
           <span className="font-quick text-text text-[13px] leading-[16px] mb-9 font-medium">{isExpress?'How far away is the event you need to prepare for?':'When did this start and how often does it impact you?'}</span>
            <InlineMultiSelect single options={isExpress?express_options:approximate} selected={[]} defaultValue={defaultValue} label={isExpress?"":"This approximately began:"} onChange={on_change} />
        </div>
    )
};

