


export const cache_image = async(img:string[]) => {
    try{
        const promises = img.map((src:string) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });

        });

        await Promise.all(promises);
    }catch(e){
        console.log(e);
    }
};