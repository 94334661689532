import React from 'react';


class DeconBeliefsIntroductionReview extends React.PureComponent{
    _next = () => {
        return {
            go:true,
            value:{

            }
        }    
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">Just before we home in on why you believe you need to think, feel and act this way, let’s quickly review your think, feel, act sequence:</span>
                <span className='font-quick font-medium text-[13px] leading-[16px] text-title mt-[30px]'>
                    During the Connect Fully stage of this circumstance, your thoughts include "I don't want to listen to anyone here - they don't have my best interests at heart," "No one is being open with me, so I'm going to shut down," and "They are against me, so I have to be guarded and put a wall up." These thoughts make you feel Burdened, and as a result, you tend to jump to assumptions quickly before gathering adequate information.
                </span>
            </div>
        )
    }
}


export default DeconBeliefsIntroductionReview;