const format_rate_obj = (ob:any) => {
    if(!ob) return [];
    const keys = Object.keys(ob);
    let array:any[] = [];
    keys.map((key:string)=>{
        array.push({
            id:key,
            rate:ob[key]
        });
    });
    const sorted = array.sort((a:any,b:any)=>b.rate-a.rate);
    let array2:any[] = [];
    sorted.map((item:any)=>{
        array2.push(item.id);
    });
    return array2;
};

export {
    format_rate_obj
}