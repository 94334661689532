import React from 'react';
import BG from '../../../../assets/bg/intro.png';
import IntroVideoImage from '../../../../assets/static/intro.jpg';
import { cache_image } from '../../../../utils/cache/image';
import { Link } from 'react-router-dom';
import { privacy_info } from '../../../../utils/navigation/path';


export default class Introduction extends React.PureComponent<any,any>{

    preload = () => {
        cache_image([
            require('../../../../assets/icons/inner_self.png'),
            require('../../../../assets/icons/external_self.png')
        ])
    };

    componentDidMount(): void {
        this.preload();
    }
    
    _next = ()=> {
        return {
            go:true,
            value:{
                done:true
            }
        };
    }

    render(): React.ReactNode {
        return (
            <div  style={{backgroundImage:`url(${BG})`}} className="w-full h-full pb-40 bg-bg  intro_shadow rounded-2xl flex flex-col overflow-scroll intro_bg pt-8 px-4">
                <span className="text-white font-quick text-2xl text-center flex leading-[25px] font-semibold self-center">Welcome to Verve!</span>
                <div  style={{backgroundImage:`url(${IntroVideoImage})`}} className="flex justify-center items-center w-full !h-40 mt-6 mb-4 intro_bg">
                    {/* <img src={require('../../../../assets/icons/play.png')} className="w-16"/> */}
                    <div className='w-full h-44'/>
                </div>
                <span className="text-white font-quick text-[16px] !leading-[22px] flex flex-col font-medium">
                    Verve is an innovative service designed to help you effectively manage various aspects of your life, personal, professional, and social. As a perception management tool, the Verve App empowers you to improve challenging situations and circumstances to achieve your goals and enhance your overall well-being. We are excited to present the beta demonstration of the perception management part of the Verve App.
                    <br />
                    <br />
                    When you engage this version of the App, you will:
                    <br />
                    <ol className='list-decimal pl-4 space-y-2 mt-2'>
                        <li>
                            Generate your circumstance
                            <br />
                            This will help you define, detail, and assess the impact of your circumstance, including its sustainability.
                        </li>
                        <li>
                            Deconstruct your perception
                            <br />
                            Your known and not so known disempowering assumptions and beliefs will be revealed after you have identified your thoughts, feelings, and actions. The disconnect from your powerful wisdom will be made clear.
                        </li>
                        <li>
                            Reconstruct your perception
                            <br />
                            You will leverage your wisdom to replace the disempowering beliefs with new empowering beliefs. From there, you will select and commit to an empowering new way to think, feel, and act in your circumstance. 
                        </li>
                    </ol>
                    <br />
                    Ultimately, you will be guided into an empowered approach that can create a breakthrough for your circumstance! 
                    <br />
                    <br />
                    <b>User Data Privacy:</b> For privacy and data security information, please refer to the Terms and Conditions for the Verve Prototype 1 Demo at <Link to={privacy_info} state={{no_footer:true}} className='link_text'>demo.verveeducation.com/privacy.</Link>
                    <br />
                    <br />
                    We ask that, during and after your experience, you please share with us:
                    <br />
                    <ol className='list-disc pl-4 space-y-1 mt-2'>
                        <li>Any technical problems you experience (please identify at what section or action you were at and what the problem was)</li>
                        <li>Any confusing message or instruction</li>
                        <li>Any moments in the process where you felt:</li>
                            <ol className='list-disc pl-6'>
                                <li>Particularly inspired, with realisations or awareness</li>
                                <li>Where you found any aspect to be slow, boring, or repetitive</li>
                            </ol>
                        <li>What parts the process and content were easy or difficult to understand, select, or rate</li>
                        <li>What parts of the process and content were enjoyable or confronting</li>
                        <li>What you wanted to do, or expected to do in the App </li>
                        <li>Your ideas to help improve the experience</li>
                        <li>The average time it took to complete the process</li>
                        <li>Feedback regarding response speed</li>
                    </ol>
                    <br/>
                    <div>
                        To provide feedback at any step, please click the orange “give feedback” tab located to the centre right of your screen, or by emailing <a href='mailto:feedback@verveeducation.com' className='link_text'>feedback@verveeducation.com</a>.
                    </div>
                    <br/>
                    <br/>
                    <div>
                      If you are inspired and have benefited from experiencing the perception manager, we would greatly welcome receiving your testimonial at <a href='mailto:feedback@verveeducation.com' className='link_text'>feedback@verveeducation.com</a>.
                    </div>
                    <br/>
                    <br/>
                    <div>
                        Please do not share this link with anyone. If you would like someone to have access to this, please send their details to <a href='mailto:feedback@verveeducation.com' className='link_text'>feedback@verveeducation.com</a> so we can provide them with a login.
                    </div>
                    <br />
                    <br />
                    Many thanks for your usage, we are grateful for your feedback so that we can refine our service and bring it to market soon!
                </span>

            </div>
        )  
    }
}