export default  [
    {
        id:1,
        label:'Minimal commitment',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:2,
        label:'Slight commitment',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:3,
        label:'Moderate commitment',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:4,
        label:'Good commitment',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:5,
        label:'Strong commitment',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:6,
        label:'Very strong commitment',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:7,
        label:'Exceptional commitment',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        },
    },
    {
        id:8,
        label:'Extreme commitment',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }
    },
    {
        id:9,
        label:'Unprecedented commitment',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }
    },

    {
        id:10,
        label:'Total commitment',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }

    },
  
   
  
]