import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { clear_state } from '../../../../../db/redux/features/app/app.slice';
import { clear_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import { useNavigate } from 'react-router-dom';
import { update_state } from '../../../../../db/redux/features/app/app.slice';
import { clear_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';


class Complete extends React.PureComponent<any,any>{

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };

    _new_circumstance = () => {
        this.props.clear();
        this.props.next('circgen_intro',{done:true});
        this.props.navigate('circgen_type');
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col  completed_cover">
                <div className='flex justify-between flex-col w-full h-full  hidden_scrollbar overflow-scroll  pb-16'>
                        <div className='flex flex-col'>
                            <span className='text-center text-white font-quick font-semibold text-[20px] leading-[25px]'>Congratulations for completing this circumstance!</span>
                            <div className='flex mt-2'>
                                <span className='text-center text-white font-quick text-[13px] leading-[16px] font-medium '>
                                Hopefully you have gained clarity, valuable insights, and confidence for a new empowering approach.
                                <br />
                                Take some time to prepare yourself to put your new approach into practice.
                                </span>
                            </div>
                        </div>
                        <img className='w-full h-56 mt-2 object-contain' src={require('../../../../../assets/bg/happy_face.png')} />
                        <div className='space-y-2 mt-2'>
                                <Button onClick={this._new_circumstance} title='New circumstance' isActive />
                                <Button title='Archive' isActive={false} />
                                <Button title='Go to Reinvention hub'  isActive={false} />
                        </div>
                </div>
            </div>  
        );
    };
};




const Button = ({title,isActive,onClick}:{title:string,isActive:boolean,onClick?:any}) => {
    return (
        <div onClick={onClick} className={`w-full flex justify-center items-center border rounded-full px-4 py-2.5 border-[#FCDAB5] ${!isActive?'opacity-30 cursor-none pointer-events-none':'cursor-pointer'}`}>
            <span className='text-white font-quick font-medium text-[15px] leading-[17px]'>{title}</span>
        </div>
    )
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
        clear:()=>{
            dispatch(clear_state())
            dispatch(clear_prompt_state())
            dispatch(clear_response_state())
        },
        next:(key:string,value:any)=>dispatch(update_state({key,value})),
    };
};

const withRouter = (Component:any) => {
    const Wrapper = forwardRef((props:any, ref:any) => {
      const navigate = useNavigate();
  
      return (
        <Component
          ref={ref}
          navigate={navigate}
          {...props}
        />
      );
    });
  
    return Wrapper;
  };


export default connect(null,map_dispatch_to_props,null,{forwardRef:true})(withRouter(Complete));