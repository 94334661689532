export default  [
    {
        id:1,
        label:'Minimal helpfulness',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:2,
        label:'Slight helpfulness',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:3,
        label:'Moderate helpfulness',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:4,
        label:'Good helpfulness',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:5,
        label:'Strong helpfulness',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:6,
        label:'Very strong helpfulness',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:7,
        label:'Exceptional helpfulness',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        },
    },
    {
        id:8,
        label:'Extreme helpfulness',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }
    },
    {
        id:9,
        label:'Unprecedented helpfulness',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }
    },

    {
        id:10,
        label:'Total helpfulness',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }

    },
  
   
  
]