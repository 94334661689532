import React from 'react';
import Loader from '../loader';
import {motion} from 'framer-motion';

interface IProps {
    message?:string
    isNew?:boolean
    title?:string,
    image?:string,
    subtitle?:string,
};

const PromptLoader = ({message,isNew,title,image,subtitle}:IProps) => {
    if(isNew){
        return (
            <motion.div layout animate={{opacity:1}} initial={{opacity:0}} className='w-full h-full flex flex-col'>
                   <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">{title}</span>
                   <img src={image} className='object-cover w-full h-40 mt-4 mb-4'/>
                   <span className='font-quick text-title text-[14px] leading-[16px] font-medium mb-8 whitespace-pre-wrap'>{subtitle}</span>
                   <div className='flex flex-col items-center justify-center'>
                       <Loader />
                   </div>
            </motion.div>
        )
    };
    return (
        <motion.div layout animate={{opacity:1}} initial={{opacity:0}} className='w-full h-full justify-center items-center flex flex-col p-10'>
            <span className='font-raleway text-sub-text text-[14px] leading-[16px] text-center mb-5 whitespace-pre-line font-medium'>{message}</span>
            <Loader />
        </motion.div>    
    );
};  


export default PromptLoader;