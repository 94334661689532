import React, { useState } from 'react';
import {motion} from 'framer-motion';
import RateText from '../../../utils/components/rate_text';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import { getHighestNumber } from '../../../../../utils/number/get_highest';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { get_response } from '../../../../../utils/request/get';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';


class BeliefPrincipalRating extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;

    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        this.state = {
            loading:this.props.principal_decon_fetched&&this.props.principal_decon_fetched.length>0?false:true,
            error:false,
            options:this.props.principal_decon_fetched||[],
            selected:[...default_keys],
            status_code:0
        };
        this.selected = { 
            ...df
        };  
        this.is_express = Boolean(this.props.circ==='prepare_myself')
    };

    componentDidMount(): void {
        if(!this.props.principal_decon_fetched||(this.props.principal_decon_fetched&&this.props.principal_decon_fetched.length===0)){
            this._fetch_principal();
        }
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_principal();
    };


    _fetch_principal = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'decon_principal',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            let options:any[] = [];

            console.log(data);

            let _fixed_data:any[] = [];
            // if(this.is_express){
                data.res.map((o:any)=>{
                    _fixed_data.push(`${o.title} - ${o.statement}\n\n${o.analysis}`);
                });
            // }else{
            //     _fixed_data = data.res;
            // }


            _fixed_data.map((txt:string)=>{
                options.push({
                    id:`${txt}`.replaceAll(' ','_'),
                    label:txt.replaceAll("selfworth","self-worth").replaceAll("selfforgiveness","self-forgiveness")
                });
            });
            console.log(data);
            this.props.update_state(options, data.prompt,data.tools);
            update_db({value:options},this.props.session_id,'principal_decon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false});
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            console.log(e);
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };



    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'principal_decon_fetched','verve_demo_session_gpt_response')
    };


    _next = () =>{
        // const value = getHighestNumber(Object.values(this.selected));

        // if(!value){
        //     alert('Should only have one highest rated principal deviation.')
        //     return {
        //         go:false,
        //         value:{

        //         }
        //     };
        // };

        return {
            go:this.state.selected.length>0,
            value:{
                ...this.selected
            }
        }
    };


    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };

    
    _on_select = (id:string) => {
        if(this.state.selected.includes(id)){
            // setSelected(selected.filter(_id=>_id!==id));
            this.setState((prv:any)=>{
                return {
                    selected:prv.selected.filter((_id:string)=>_id!==id)
                }
            })
            delete this.selected[id];
        }else{
            this.setState((prv:any)=>{
                return {
                    selected:[...prv.selected,id]
                }
            })
            // setSelected([...selected,id]);
            this.selected[id] = 1;
        }
    };
    
    _render_ratings = (item:any) => {
        return (
            <RateText is_rl defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} onChangeRate={this._on_change_rate} {...item} key={item.id} selected={this.state.selected.includes(item.id)} onChange={this._on_select}/>
        )
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                    {
                        this.state.loading?
                        <PromptLoader message="Let’s look at the wisdom that exposes how disempowering these beliefs are."/>:
                        this.state.error?
                        <PromptError statusCode={this.state.status_code} onRetry={this._fetch_principal}/>
                        :
                        <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                            <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">Assessing Your Beliefs</span>
                            <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4 mb-4">
                                {this.is_express?
                                <>
                                    Knowledge and wisdom are helpful when explained through empowering principles. 
                                    <br />
                                    <br />
                                    The below principles are relevant to your circumstance and are showing how your disempowering beliefs are at odds with your principles. 
                                    <br />
                                    <br />
                                    Choose each principle that will help you empower your approach, and rate how disconnected they are from your current disempowering belief/s.
                                </>
                                    :
                                <>
                                    When we use knowledge in a situation, and it is helpful and reliable, it becomes our personal wisdom.
                                    <br />
                                    <br />
                                    When many people experience identical knowledge being helpful and reliable, it becomes collective wisdom.
                                    <br />
                                    <br />
                                    Collective wisdom specific to personal growth and success is often shared in the form of empowerment principles. 
                                    <br />
                                    <br />
                                    Here are well-known empowerment principles with an explanation of how your disempowering beliefs in this circumstance are directly clashing with them and therefore your wisdom.
                                    <br />
                                    <br />
                                    Choose each empowerment principle that you recognise could empower you if you embraced them in this circumstance, and rate how disconnected they are from your current disempowering belief/s.
                                </>}
                            </span>
                            <motion.div layout className='space-y-2'>
                            {this.state.options.map(this._render_ratings)}
                              </motion.div>
                        </motion.div>
                    }
            </div>
        )
    }
};



const get_beliefs = (beliefs:any) => {
    const keys = Object.keys(beliefs);
    let array:any[] = [];
    keys.map((key:string)=>{
        array.push({
            id:key,
            rate:beliefs[key]
        });
    });
    const sorted = array.sort((a:any,b:any)=>b.rate-a.rate);
    let array2:any[] = [];
    sorted.map((item:any)=>{
        array2.push(item.id);
    });
    return array2;
};


const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["decon_principle_deviation"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            beliefs:get_beliefs(state.app["decon_belief_rating"])
        },
        principal_decon_fetched:state.response['principal_decon_fetched']?.response||state.response['principal_decon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    }
};



const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'principal_decon_fetched',value}));
            dispatch(update_prompt_state({key:'principal_decon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'principal_decon_fetched',value:tools}));
         },
    }    
};




export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(BeliefPrincipalRating);