import React from 'react';
import emojies from '../../../utils/emoji/empowering_emotion.json';
import EmojiCard from '../../../utils/components/emojiCard';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import {motion} from 'framer-motion'
import { get_response } from '../../../../../utils/request/get';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { format_rate_obj } from '../../../utils/formatters';
import InlineMultiSelect from '../../../utils/components/inlineMultiSelect';
import trim from 'lodash/trim';
import capitalize from 'lodash/capitalize';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import uniqBy from 'lodash/uniqBy';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';

const cross_check = (v:any,defaultValue:any) =>{
    // check if the array themes includes the item from defaultValue and return a array where defaultValue item also presented in themes
    const to_return:any[] = [];
    defaultValue.map((item:any) => {
        if(v.find((theme:any) => theme.id === item)){
            to_return.push(item);
        }
    });
    return to_return;
};


class EmpoweringEmotion extends React.PureComponent<any,any>{
    is_express: boolean;


    constructor(props:any){
        super(props);
        const df = this.props.emotional_rating;
        this.state = {
            loading:this.props.emotions_recon_fetched&&this.props.emotions_recon_fetched.length>0?false:true,
            error:false,
            emojies:this.props.emotions_recon_fetched||[],
            selected:df||[],
            status_code:0
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself')
    }

    componentDidMount(): void {
        if(!this.props.emotions_recon_fetched||(this.props.emotions_recon_fetched&&this.props.emotions_recon_fetched.length===0)){
            this._fetch_emotions();
        };
    
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.setState({selected:[]});
        this._fetch_emotions();
    };


    // [Confident, empowered, proud, independent, motivated, supportive, fulfilled, happy, content, self-sufficient]
    _fetch_emotions = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'recon_emotions',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            let items:any = [];
            data.res.map((txt:string)=>{
                const txt_ = txt.replaceAll(".","");
                items.push({
                    id:`${txt}`.toLowerCase(),
                    label:capitalize(txt_)
                });
            });
            if(items.length===1){
                const regex = /^[^,]*,([^,]*,)*[^,]*$/;
                if(regex.test(items[0].label)){
                    const new_array = items[0].label.split(',');
                    items = [];
                    new_array.map((t:string)=>{
                        const txt = trim(t).replaceAll(".","");
                        items.push({
                            id:`${txt}`.toLowerCase(),
                            label:capitalize(txt),
                        });
                    });
                }
            };
            console.log(data);
            items = uniqBy(items,'id');
            
            // sort by alphabetical order
            items.sort((a:any,b:any) => {
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
            });
            this.props.update_state(items,data.prompt,data.tools);
            update_db({value:items},this.props.session_id,'emotions_recon_fetched','verve_demo_session_gpt_response')
            this.setState({emojies:items,loading:false,error:false})
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };

    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'emotions_recon_fetched','verve_demo_session_gpt_response')

    };


    _on_select_emoji = (id:any) => {

        // if(this.state.selected.includes(id)){
        //     // setSelected(selected.filter(item => item !== id));
        //     this.setState((state:any) => {
        //         return {
        //             selected:state.selected.filter((item:string) => item !== id)
        //         }
        //     });
        // }else{
        //     // setSelected([...selected,id]);
        //     this.setState((state:any) => {
        //         return {
        //             selected:[...state.selected,id]
        //         }
        //     });
        // }
        this.setState({selected:id})
    };



    // _render_emojies = (item:typeof emojies[0]) => {
    //     return <EmojiCard {...item} sm key={item.id} selected={this.state.selected.includes(item.id)} onClick={this._on_select_emoji} />;
    // };


    _next = () => {
        return {
            go:this.state.selected.length>0,
            value:{
                emotional_rating:this.state.selected
            }
        };
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40 font-semibold">

                {
                    this.state.loading?
                    <PromptLoader message={this.is_express?'Now that you are preparing yourself to believe and think this way, what would you naturally be feeling?':`As you know, feelings matter. Now that you are preparing yourself to believe and think this way, what would you naturally be feeling?`}/>:
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_emotions}/>
                    :
                <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                    <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4">Embracing New Emotions</span>
                    <span className='flex font-quick text-title text-[13px] leading-[16px] font-medium'>
                    {this.is_express?'Choose the emotions you want to feel intensely as your emotional state to empower your approach.':<>
                        Emotions are powerful and, when they align to your wisdom, they make empowered actions easier. 
                        <br />
                        <br />
                        Based on your wisdom, and the beliefs and thoughts you have already begun committing to, how do you want to feel in this circumstance to empower yourself? Choose from the options below.
                    </>}
                    </span>
                
                    <div className='flex w-full flex-col mt-[18px]'>
                        <span className='flex font-raleway text-[10px] leading-[12px] text-title font-medium'>Select as many emotions you feel are relevant.</span>
                        <div className='flex flex-row flex-wrap justify-between pt-[19px]'>
                            {/* {this.state.emojies.map(this._render_emojies)} */}
                    <InlineMultiSelect options={this.state.emojies} defaultValue={cross_check(this.state.emojies,this.props.emotional_rating||[])} selected={[]} onChange={this._on_select_emoji}  show_count={false} />

                        </div>
                    </div>
                </motion.div>
                }  
            </div>
        )
    }
};

const map_state_to_props = (state:RootState) => {
    return {
        emotional_rating:state.app['recon_empowering_emotions']?.emotional_rating,
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            empowering_beliefs:format_rate_obj(state.app['recon_belief']),
            empowering_thoughts:format_rate_obj(state.app['recon_thoughts']),
        },
        emotions_recon_fetched:state.response['emotions_recon_fetched']?.response||state.response['emotions_recon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    };
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'emotions_recon_fetched',value}));
            dispatch(update_prompt_state({key:'emotions_recon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'emotions_recon_fetched',value:tools}));
         },
    }    
};



export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(EmpoweringEmotion);