import React, {  useState } from "react";
import { motion } from "framer-motion";
import RateText from "../../../utils/components/rate_text";
import { connect } from "react-redux";
import { RootState } from "../../../../../db/redux/store";
import { getHighestNumber } from "../../../../../utils/number/get_highest";
import { Dispatch } from 'redux';
import { update_response_state } from "../../../../../db/redux/features/gpt_response/response.slice";
import PromptLoader from "../../../../../common/componenets/prompt_loader";
import PromptError from "../../../../../common/componenets/prompt_error";
import { get_response } from "../../../../../utils/request/get";
import { extractActions, getActionLabel } from "../../../utils/extractor";
import { update_db } from "../../../../../utils/dynamoDB/fetch";
import { update_prompt_state } from "../../../../../db/redux/features/prompt_meta/prompt_meta.slice";
import capitalize from 'lodash/capitalize';
import { update_tools_state } from "../../../../../db/redux/features/tools/tools.slice";




class ActionRelavanceRating extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;
    
    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        this.state = {
            loading:this.props.action_decon_fetched&&this.props.action_decon_fetched.length>0?false:true,
            error:false,
            options:this.props.action_decon_fetched||[],
            selected:[...default_keys],
            status_code:0
        };
        this.selected = { 
            ...df
        };  
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };


    componentDidMount(): void {
        if(!this.props.action_decon_fetched||(this.props.action_decon_fetched&&this.props.action_decon_fetched.length===0)){
            this._fetch_actions();
        }
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_actions();
    };


    _fetch_actions = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'decon_actions',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            // let options:any[] = [];
            // data.res.map((txt:string)=>{
            //     options.push({
            //         id:`${txt}`.replaceAll(' ','_'),
            //         label:txt
            //     });
            // });
            let options:any[] = [];

            data.res.map((itms:{emotion:string,actions:string[]})=>{
                let extra:{id:string,label:string,is_selected:boolean,parent_id:string}[] = [];
                itms.actions.map((txt:string)=>{
                    extra.push({
                        id:txt.replaceAll(' ','_'),
                        label:capitalize(txt),
                        is_selected:false,
                        parent_id:itms.emotion.replaceAll(' ','_')
                    });
                });
                options.push({
                    id:itms.emotion.replaceAll(' ','_'),
                    label:capitalize(itms.emotion),
                    extra:extra
                });

                // const extraMapped = extractActions(txt);
                // let extra:any[] =[];
                // extraMapped.map((item:any)=>{
                //     extra.push({
                //         ...item,
                //         parent_id:`${txt}`.replaceAll(' ','_'),
                //     });
                // });
                // options.push({
                //     id:`${txt}`.replaceAll(' ','_'),
                //     extra,
                //     label:getActionLabel(txt)
                // });

            });


            console.log(data);
            this.props.update_state(options,data.prompt,data.tools);
            update_db({value:options},this.props.session_id,'action_decon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false});
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };

    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };


    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'action_decon_fetched','verve_demo_session_gpt_response')
    };

    _next = () => {
        let new_selected:any = {};

        console.log(this.selected);

        const keys = Object.keys(this.selected);
        
        keys.map((key:string)=>{
            const item = this.state.options.find((item:any)=>item.id===key);
            const extraSelected = item.extra.filter((item:any)=>item.is_selected);
            let new_id = `${item.label} - `;
            extraSelected.map((item:any,index:number)=>{
                // only add , if its not the last item
                new_id += ` '${item.label}'${index===extraSelected.length-1?'':','}`;
            });
            new_id = new_id.replaceAll(' ','_');
            new_selected[new_id] = this.selected[key];
        }); 

        let new_options:any[] = [];

        this.state.options.map((item:any)=>{
            const extraSelected = item.extra.filter((item:any)=>item.is_selected);
            let new_id = `${item.label} - `;
            extraSelected.map((item:any,index:number)=>{
                // only add , if its not the last item
                new_id += ` '${item.label}'${index===extraSelected.length-1?'':','}`;
            });
            let new_extra:any = [];
            new_id = new_id.replaceAll(' ','_');

            item.extra.map((item:any)=>{
                new_extra.push({
                    ...item,
                    parent_id:new_id
                });
            });
            new_options.push({
                ...item,
                extra:new_extra,
                id:new_id
            });
        });

        let _check_is_min_selected:{ [key: string]: boolean } = {};
   

        this.state.selected.map((i:string)=>{
            const item  = this.state.options.find((item:any)=>item.id===i);
            _check_is_min_selected[item.id as keyof typeof _check_is_min_selected] = item.extra.filter((item:any)=>item.is_selected).length>0;
        });

        const _all_is_selected = Object.values(_check_is_min_selected).every((item:boolean)=>item===true);

    

        this.props.update_state(new_options);
        update_db({value:new_options},this.props.session_id,'action_decon_fetched','verve_demo_session_gpt_response')

        return {
            go:_all_is_selected&&keys.length>0,//this.state.selected.length>0,
            // go:false,
            msg:keys.length>0?`Please select at least one action for each emotion.`:`Please select at least one emotion and one action for each emotion.`,
            value:{
                ...new_selected
                // ...this.selected
            }
        }
    };

    _on_select = (id:string) => {
        if(this.state.selected.includes(id)){
            this.setState((state:any)=>({
                selected:state.selected.filter((item:string)=>item!==id),
                options:state.options.map((item:any)=>{
                    if(item.id===id){
                        return {
                            ...item,
                            extra:item.extra.map((item:any)=>({
                                ...item,
                                is_selected:false
                            }))
                        }
                    }else{
                        return item;
                    }
                })
            }));
            delete this.selected[id];
        }else{
            this.setState((state:any)=>({
                selected:[...state.selected,id]
            }));
            this.selected[id] = 1;
        }
    };

    on_sub_thought_click = (item_id:string,parent_id:string) => {
        let options = [...this.state.options];
        const parent_index = options.findIndex((item: any) => item.id === parent_id);
        const sub_index = options[parent_index].extra.findIndex((item: any) => item.id === item_id);
      
        const updatedOptions = [...options];
        const updatedParent = {
          ...updatedOptions[parent_index],
          extra: [...updatedOptions[parent_index].extra],
        };
        const newSelectStatus = !updatedParent.extra[sub_index].is_selected;
        updatedParent.extra[sub_index] = {
          ...updatedParent.extra[sub_index],
          is_selected:newSelectStatus
        };
      
        updatedOptions[parent_index] = updatedParent;
        
        const selected_extras = updatedOptions[parent_index].extra.filter((item:any)=>item.is_selected);
        if(selected_extras.length<=0){
            if(this.selected[parent_id]){
                 this._on_select(parent_id);
            }
        }else{
            if(!this.selected[parent_id]){
                this._on_select(parent_id);
            }
        }
      
        this.setState({ options: updatedOptions });
    };


    _render_item = (item:any) => {
        return <SubThought {...item} key={item.id} onClick={this.on_sub_thought_click}/>;
    };
    
    _render_sub_items = (id:string,sub_items:any[]) => {
        return (
            <motion.div key={id} className='mt-1 space-y-1.5'>
                {sub_items.map(this._render_item)}
            </motion.div>
        );
    };
    _render_ratings = (item:any) => {
        return (
            <RateText is_rl boldLabel renderExtra={this._render_sub_items} onChangeRate={this._on_change_rate} defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} {...item} key={item.id} selected={this.state.selected.includes(item.id)} onChange={this._on_select}/>
        )
    };
    render(): React.ReactNode {
    console.log(this.is_express)

        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                {
                    this.state.loading?
                    <PromptLoader subtitle={this.is_express?'Let’s see what actions may be in the way of your preparation.':`Every action has an opposite and equal reaction!\n\nAre you ready to see how people typically act when they think and feel this way?`} message={this.is_express?'Let’s see what actions may be in the way of your preparation.':`Every action has an opposite and equal reaction!\n\nAre you ready to see how people typically act when they think and feel this way?`} isNew={false} title='Deconstructing your Actions' image='https://images.unsplash.com/photo-1577083288073-40892c0860a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80'/>
                    // <PromptLoader message={`Are you ready to see how people typically act when they think and feel this way?`}/>
                    :
                    this.state.error?
                      <PromptError statusCode={this.state.status_code} onRetry={this._fetch_actions}/>
                    :
                    <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                        {this.is_express?null:<span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">How You Act</span>}
                        <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">
                        {
                            this.is_express?'When you don’t feel as prepared as you would like to be for a challenging event, understanding your actions will guide you to improve your approach.':
                        <>
                            How we think and feel drives how we act and react. This includes how we relate to and treat ourselves and others.
                            <br/>
                            <br/>
                            Here is a list of typical actions of others in this circumstance who also think and feel this way. 
                            <br/>
                            <br/>
                            Choose the actions you can recognise apply to you in this circumstance.
                        </>
                        }
                        </span>
                        <motion.div layout className='space-y-2'>
                            {this.state.options.map(this._render_ratings)}
                        </motion.div>
                    </motion.div>
                }
            </div>
        )
    }
};



const SubThought = ({id,label,is_selected,onClick,parent_id}:{parent_id:string,onClick?:any,id:string,label:string,is_selected?:boolean}) =>{
    const _on_click = (ev:any)=>{
        onClick(id,parent_id);
        ev.stopPropagation();
        ev.preventDefault();
    }
    return (
        <motion.div onClick={_on_click} className={`relative flex items-center ${is_selected?'bg-btn bg-opacity-40 border-br-green':'bg-white'} border-br-cream border py-2.5 rounded-md px-6`}>
            <span className='flex font-quick text-text text-[13px] leading-[16px] text-left ml-2'>{label}</span>
            {is_selected?<motion.img layout animate={{opacity:1,scale:1}} initial={{opacity:0,scale:0}} className='w-3 h-3 absolute left-3' src={require('../../../../../assets/icons/selected_circle.png')}/>:null}
        </motion.div>
    )
};


const get_thoughts = (thoughts:any) => {
    const keys = Object.keys(thoughts);
    let array:any[] = [];
    keys.map((key:string)=>{
        array.push({
            id:key,
            rate:thoughts[key]
        });
    });
    const sorted = array.sort((a:any,b:any)=>b.rate-a.rate);
    let array2:any[] = [];
    sorted.map((item:any)=>{
        array2.push(item.id);
    });
    return array2;
};



const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["decon_action_relevance"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            thoughts:get_thoughts(state.app["decon_thoughts_relevance"])
        },
        action_decon_fetched:state.response['action_decon_fetched']?.response||state.response['action_decon_fetched'],
        session_id:state.app.session_seed,
        // express_emotion:state.app?.circgen_type?.selected==='prepare_myself'? get_emotions(state.app['circgen_select_emotion'].selected):[],
        circ:state.app?.circgen_type?.selected
    }
};


const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'action_decon_fetched',value}));
            dispatch(update_prompt_state({key:'action_decon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'action_decon_fetched',value:tools}));
         },
    }    
};


export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(ActionRelavanceRating);