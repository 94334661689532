import React, { forwardRef } from 'react';
import HorizontalSlider from '../../utils/components/HorizontalSlider';
import { RootState } from '../../../../db/redux/store';
import { connect } from 'react-redux';
import { struggle_intense_external_domains, struggle_intense_internal_domains } from '../../../../utils/vision_wheel';
import defaultRates from '../../utils/rates'
import {motion, useSpring} from 'framer-motion';
import { Dispatch } from 'redux';
import { clear_state } from '../../../../db/redux/features/app/app.slice';
import { clear_response_state } from '../../../../db/redux/features/gpt_response/response.slice';
import { useNavigate } from 'react-router-dom';
import { clear_prompt_state } from '../../../../db/redux/features/prompt_meta/prompt_meta.slice';

// const domains = [
//     {
//         id:'emotional_wellbeing',
//         image:'https://images.unsplash.com/photo-1581337204873-ef36aa186caa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1456&q=80',
//         label:'Emotional Well-being',
//         description:'Rated 10: Sever impact'
//     },
//     {
//         id:'relationship',
//         image:'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
//         label:'Relationship',
//         description:'Rated 10: Sever impact'
//     },
// ]


const get_rate_description = (rate:number) => {
    const r = defaultRates.find((item) => item.id === rate);
    if(r){
        return r.label;
    }
    return '';
};

class RankedDomain extends React.PureComponent<any,any>{
    options: any[];
    selected: null|string;
    
    constructor(props:any) {
        super(props);
        this.options = [];
        let should_show_gateway = true;
        const items = [...struggle_intense_external_domains,...struggle_intense_internal_domains]
        Object.keys(this.props.ranked_domains).map((key) => {   
                const i = items.find((item) => item.id === key);
                if(this.props.ranked_domains[key].rate){
                    this.options.push({
                        ...i,
                        description:`Rated ${this.props.ranked_domains[key].rate}: ${get_rate_description(this.props.ranked_domains[key].rate)}`,
                        rate:this.props.ranked_domains[key].rate,
                    });
                }
            
                if(i&&this.props.ranked_domains[key].rate>=7){
                    should_show_gateway = false;
                };
        });
        this.state = {
            should_show_gateway,
        }
        // console.log(this.options);
        // sort by rate
        this.options.sort((a,b) => b.rate - a.rate);
        this.selected = this.props.selected?this.props.selected:this.options.length<=0?null: this.options[0].id;

    };

    _on_change = (value:string|null) => {
        this.selected = value;
    };

    _next = () => {
        return {
            go:Boolean(this.selected),
            value:{
                selected:this.selected,
            }
        }
    };

    _on_click_action = (id:string) => {
        if(id==='continue'){
            this.setState({should_show_gateway:false});
        };

        if(id==='re_evaluate'){
            this.props.navigate('circgen_vision_wheel_internal');
        };

        if(id==='start_another'){
            this.props.clear_app();
            this.props.clear_res();
            this.props.clear_prompt();
            this.props.navigate('circgen_type');
        };
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-96">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Updating Your Area of Focus</span>

                {
                    this.state.should_show_gateway?
                    <motion.div layout animate={{opacity:1}} initial={{opacity:0}} className='w-full h-full flex flex-col'>
                        <span className='font-quick text-title text-[13px] leading-[16px] font-medium'> Your impact has been rated in a mild to lower range.</span>
                        <span className='font-quick text-title text-[13px] leading-[16px] font-medium mt-1 mb-10'>Please click the option of how you would like to proceed below:</span>
                     {this.options.length===0? null: <Btn label='Continue and choose the  area of focus impacting you the most.' id='continue' onClick={this._on_click_action}/>}
                        <Btn label='Re-evaluate the impact on your area of focus.' id='re_evaluate' onClick={this._on_click_action}/>
                        <Btn label='Start another circumstance that is impacting you more.' id='start_another' onClick={this._on_click_action}/>
                    </motion.div>
                :
                <motion.div className='flex flex-col' layout animate={{opacity:1}} initial={{opacity:0}}>
                    {/* <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>{`Please select which Domain out of the ${this.options.length} you've rated with the highest intensity you would like to deep dive on:`}</span> */}
                    <span className='font-quick text-title text-[13px] leading-[20px] font-medium'>These are your highest impact areas. Please select the area that is currently the most important to you to continue deepening your understanding of the impact.</span>
                    <HorizontalSlider vertical defaultValue={this.selected} onChange={this._on_change} options={this.options}/>
                </motion.div>
                }
            </div>
        )
    }
}


const Btn = ({onClick,label,id}:{id:string,label:string,onClick:any}) => {
    const scale = useSpring(1);

    const _down = () => {
        scale.set(1.01);
    };


    const _on_click = () => {
        setTimeout(()=>{
            scale.set(1);
        },40);
        onClick(id);
    };
    return (
        <motion.div layout onClick={_on_click} style={{scale}} onMouseUp={_down} className={`border-br border relative rounded-full w-full flex flex-row items-center justify-center px-4 py-3.5 mb-2`}>
                    <div className='flex flex-row items-center'>
                          <motion.span layout className={`text-text font-quick flex  line-clamp-1 font-medium text-[14px] text-center leading-[18px]`}>{label}</motion.span>
                    </div>
        </motion.div>
    )
};

const map_state_to_props = (state:RootState) => {
    return {
        ranked_domains:{
            ...state.app['circgen_vision_wheel_internal'],
            ...state.app['circgen_vision_wheel_external'],
        },
        selected:state.app['circgen_ranked_domain']?.selected,
    };
};


const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
        clear_app:()=>dispatch(clear_state()),
        clear_res:()=>dispatch(clear_response_state()),
        clear_prompt:()=>dispatch(clear_prompt_state()),

    }


};

const withRouter = (Component:any) => {
    const Wrapper = forwardRef((props:any, ref:any) => {
      const navigate = useNavigate();
  
      return (
        <Component
          ref={ref}
          navigate={navigate}
          {...props}
        />
      );
    });
  
    return Wrapper;
  };

export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(withRouter(RankedDomain));