
const struggle_from = [
    {
        id:'my_inner_self',
        label:'My Inner Self',
        image:require('../../assets/icons/inner_self.png'),
        description:'My mental, emotional and physical well-being and personal enjoyment.'
    },
    {
        id:'my_external_world',
        label:'My External World',
        image:require('../../assets/icons/external_self.png'),
        description:'My relationships and roles in family, romance, friendships, work and community.'
    }
];



const subdomains = {
    'relationships':[
        {
            id:'spouse',
            label:'Spouse',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'partner',
            label:'Partner',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'boyfriend',
            label:'Boyfriend',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'girlfriend',
            label:'Girlfriend',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'fiance',
            label:'Fiance',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'casual_dating_partner',
            label:'Casual Dating Partner',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'long_distance_partner',
            label:'Long Distance Partner',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'ex_partner',
            label:'Ex-partner',
            image:'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1598&q=80',
        },
        {
            id:'child',
            label:'Child',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'parent',
            label:'Parent',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'caregiver',
            label:'Caregiver',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'sibling',
            label:'Sibling',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'grandparent',
            label:'Grandparent',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'uncle',
            label:'Uncle',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'aunt',
            label:'Aunt',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'nephew',
            label:'Nephew',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'niece',
            label:'Niece',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'cousin',
            label:'Cousin',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'in_law',
            label:'In-law',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
// Other
        {
            id:'friend',
            label:'Friend',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'ex-friend',
            label:'Ex-Friend',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'boss',
            label:'Boss',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'supervisor',
            label:'Supervisor',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'manager',
            label:'Manager',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'employee',
            label:'Employee',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'colleague/co_worker',
            label:'Colleague/Co-worker',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'mentor',
            label:'Mentor',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'mentee',
            label:'Mentee',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'business_partner',
            label:'Business Partner',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'teacher',
            label:'Teacher',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'student',
            label:'Student',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'classmate',
            label:'Classmate',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'tutor',
            label:'Tutor',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'staff',
            label:'Staff',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'group_member',
            label:'Group Member',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'volunteer',
            label:'Volunteer',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'other',
            label:'Other',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
    ],
    'parenting_/_caregiving':[
        {
            id:'pre_conception',
            label:'Pre-conception',
            image:''
        },
        {
            id:'conception',
            label:'Conception',
            image:''
        },
        {
            id:'pregnancy',
            label:'Pregnancy',
            image:''
        },
        {
            id:'newborn_(0-2_months)',
            label:'Newborn (0-2 months)',
            image:''
        },
        {
            id:'infant_(2-12_months)',
            label:'Infant (2-12 months)',
            image:''
        },
        {
            id:'preschooler_(3-5_years)',
            label:'Preschooler (3-5 years)',
            image:''
        },
        {
            id:'early_childhood_(5-6_years)',
            label:'Early Childhood (5-6 years)',
            image:''
        },
        {
            id:'primary_school_age_(6-12_years)',
            label:'Primary School Age (6-12 years)',
            image:''
        },
        {
            id:'pre_teen_or_tween_(9-12_years)',
            label:'Pre-Teen or Tween (9-12 years)',
            image:''
        },
        {
            id:'young_teenager_(13-15_years)',
            label:'Young Teenager (13-15 years)',
            image:''
        },
        {
            id:'mid_teenager_(15-17_years)',
            label:'Mid Teenager (15-17 years)',
            image:''
        },
        {
            id:'older_teenager_(17-19_years)',
            label:'Older Teenager (17-19 years)',
            image:''
        },
        {
            id:'young_adult_(20-24_years)',
            label:'Young Adult (20-24 years)',
            image:''
        },
        {
            id:'sibling_rivalry',
            label:'Sibling Rivalry',
            image:''  
        },
        {
            id:'puberty',
            label:'Puberty',
            image:''
        },
        {
            id:'drugs_and_alcohol',
            label:'Drugs and Alcohol',
            image:''
        },
        {
            id:'sexuality',
            label:'Sexuality',
            image:''
        },
        {
            id:'boundaries',
            label:'Boundaries',
            image:''
        },
        {
            id:'attitude',
            label:'Attitude',
            image:''
        },
        {
            id:'self-esteem',
            label:'Self-esteem',
            image:''
        },
        {
            id:'friendships',
            label:'Friendships',
            image:''
        },
        {
            id:'peer_pressure',
            label:'Peer Pressure',
            image:''
        },
        {
            id:'bullying',
            label:'Bullying',
            image:''
        },
        {
            id:'harassment',
            label:'Harassment',
            image:''
        },
        {
            id:'discrimination',
            label:'Discrimination',
            image:''
        },
        {
            id:'inequality',
            label:'Inequality',
            image:''
        },
        {
            id:'disabilities',
            label:'Disabilities',
            image:''
        },
        {
            id:'education',
            label:'Education',
            image:''
        },
        {
            id:'extra-curricular_activities',
            label:'Extra-curricular Activities',
            image:''
        },
        {
            id:'career',
            label:'Career',
            image:''
        }
    ],
    'finding_a_partner':[
        {
            id:'dates',
            label:'Dates',
            image:''
        },
        {
            id:'dating',
            label:'Dating',
            image:''
        },
        {
            id:'dating_sites',
            label:'Dating Sites',
            image:''
        },
        {
            id:'past_break_up',
            label:'Past Break-up',
            image:''
        },
        {
            id:'fear_of_commitment',
            label:'Fear of Commitment',
            image:''
        },
        {
            id:'rejection',
            label:'Rejection',
            image:''
        },
        {
            id:'procrastination',
            label:'Procrastination',
            image:''
        },
        {
            id:'parental_pressure',
            label:'Parental Pressure',
            image:''
        },
        {
            id:'peer_pressure',
            label:'Peer Pressure',
            image:''
        },
        {
            id:'sexual_identity',
            label:'Sexual Identity',
            image:''
        },
        {
            id:'self_confidence',
            label:'Self-confidence',
            image:''
        },
        {
            id:'social_anxiety',
            label:'Social Anxiety',
            image:''
        }
    ],
    'work':[
        {
            id:'my_role',
            label:'My Role',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'income',
            label:'Income',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'career_progression',
            label:'Career Progression',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'career_success',
            label:'Career Success',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'senior_executive',
            label:'Senior Executive',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'management',
            label:'Management',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'staff',
            label:'Staff',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'colleagues',
            label:'Colleagues',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'department',
            label:'Department',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'clients',
            label:'Clients',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'suppliers',
            label:'Suppliers',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'service_providers',
            label:'Service Providers',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'alliance_partners',
            label:'Alliance Partners',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'government_agencies',
            label:'Government Agencies',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'industry_peers',
            label:'Industry Peers',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        }

    ],
    // 'old_existing_business':[
    //     {
    //         id:'revenue',
    //         label:'Revenue',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'business_success',
    //         label:'Business Success',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'business_partner',
    //         label:'Business Partner',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'senior_executive',
    //         label:'Senior Executive',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'management',
    //         label:'Management',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'staff',
    //         label:'Staff',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'colleagues',
    //         label:'Colleagues',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'department',
    //         label:'Department',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'clients',
    //         label:'Clients',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'suppliers',
    //         label:'Suppliers',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'service_providers',
    //         label:'Service Providers',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'alliance_partners',
    //         label:'Alliance Partners',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'government_agencies',
    //         label:'Government Agencies',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     },
    //     {
    //         id:'industry_peers',
    //         label:'Industry Peers',
    //         image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
    //     }
    // ],
    'community_engagement':[
        {
            id:'environmental_initiatives',
            label:'Environmental Initiatives',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'social_participation',
            label:'Social Participation',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'local_reputation',
            label:'Local Reputation',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'volunteering',
            label:'Volunteering',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'philanthropy',
            label:'Philanthropy',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        }
    ],
    'higher_calling':[
        {
            id:'higher_purpose',
            label:'Higher Purpose',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },{
            id:'state_impact',
            label:'State Impact',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'national_impact',
            label:'National Impact',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'global_impact',
            label:'Global Impact',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'philanthropy',
            label:'Philanthropy',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        },
        {
            id:'legacy',
            label:'Legacy',
            image:'https://images.unsplash.com/photo-1518708909080-704599b19972?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=643.5&q=80',
        }
    ],
    'emotional_wellbeing':[
        {   
            id:'self_worth',
            label:'Self-worth',
            image:require('../../assets/static/physical_wellbeing.jpg'),
        },
        {
            id:'self_love',
            label:'Self-love',
            image:require('../../assets/static/subdomains/emotional_wellbeing/self_love.jpg')
        },
        {
            id:'confidence',
            label:'Confidence',
            image:require('../../assets/static/subdomains/emotional_wellbeing/confidence.jpg')
        },
        {
            id:'inner_security',
            label:'Inner Security',
            image:require('../../assets/static/subdomains/emotional_wellbeing/inner_security.jpg')
        },
        {
            id:'self_care',
            label:'Self-care',
            image:require('../../assets/static/self_love.png')
        }
    ],
    'mental_wellbeing':[
        {
            id:'learning',
            label:'Learning',
            image:require('../../assets/static/subdomains/mental_wellbeing/learning.jpg'),
        },
        {
            id:'awareness',
            label:'Awareness',
            image:require('../../assets/static/subdomains/mental_wellbeing/awareness.jpg'),
        },
        {
            id:'clarity',
            label:'Clarity',
            image:require('../../assets/static/subdomains/mental_wellbeing/clarity.jpg'),
        },
        {
            id:'attitude',
            label:'Attitude',
            image:require('../../assets/static/subdomains/mental_wellbeing/attitude.jpg'),
        },
        {
            id:'mindfulness',
            label:'Mindfulness',
            image:require('../../assets/static/subdomains/mental_wellbeing/mindfulness.jpg'),
        }
    ],
    'physical_wellbeing':[
        {
            id:'health',
            label:'Health',
            image:require('../../assets/static/subdomains/physical_wellbeing/health.jpg'),
        },
        {
            id:'nutrition',
            label:'Nutrition',
            image:require('../../assets/static/subdomains/physical_wellbeing/nutrition.jpg'),
        },
        {
            id:'fitness',
            label:'Fitness',
            image:require('../../assets/static/subdomains/physical_wellbeing/fitness.jpg'),
        },
        {
            id:'strength/conditioning',
            label:'Strength/Conditioning',
            image:require('../../assets/static/subdomains/physical_wellbeing/strength_conditioning.jpg'),
        },
        {
            id:'rest',
            label:'Rest',
            image:require('../../assets/static/subdomains/physical_wellbeing/rest.jpg'),
        },
        {
            id:'sleep',
            label:'Sleep',
            image:require('../../assets/static/subdomains/physical_wellbeing/sleep.jpg'),
        }
    ],
    'personal_enjoyment':[
        {
            id:'sport',
            label:'Sport',
            image:require('../../assets/static/subdomains/personal_enjoyment/sport.jpg'),

        },
        {
            id:'recreation',
            label:'Recreation',
            image:require('../../assets/static/subdomains/personal_enjoyment/recreation.jpg'),
        },
        {
            id:'hobbies',
            label:'Hobbies',
            image:require('../../assets/static/subdomains/personal_enjoyment/hobbies.jpg'),
        },
        {
            id:'adventure',
            label:'Adventure',
            image:require('../../assets/static/subdomains/personal_enjoyment/adventure.png'),
        },
        {
            id:'travel',
            label:'Travel',
            image:require('../../assets/static/subdomains/personal_enjoyment/travel.jpg'),
        },
        {
            id:'events',
            label:'Events',
            image:require('../../assets/static/subdomains/personal_enjoyment/events.jpg'),
        },
        {
            id:'creativity',
            label:'Creativity',
            image:require('../../assets/static/subdomains/personal_enjoyment/creativity.jpg'),
        }
    ],
    'financial_well-being':[
        {
            id:'budgeting_&_saving',
            label:'Budgeting & Saving',
            image:''
        },
        {
            id:'investing',
            label:'Investing',
            image:''
        },
        {
            id:'debt_management',
            label:'Debt Management',
            image:''
        },
        {
            id:'retirement_planning',
            label:'Retirement Planning',
            image:''
        },
        {
            id:'increasing_income',
            label:'Increasing Income',
            image:''
        },
        {
            id:'conscious_consumption',
            label:'Conscious Consumption',
            image:''
        },
        {
            id:'risk_and_security',
            label:'Risk and Security',
            image:''
        },
        {
            id:'money_psychology',
            label:'Money Psychology',
            image:''
        },
        {
            id:'relationships',
            label:'Relationships',
            image:''
        },
        {
            id:'emergency_funds',
            label:'Emergency Funds',
            image:''
        },
        {
            id:'lifestyle',
            label:'Lifestyle',
            image:''
        },
        {
            id:'generational_wealth',
            label:'Generational Wealth',
            image:''
        }
    ],
    'start-up_business':[
        {
            id:'branding_and_marketing',
            label:'Branding and Marketing',
            image:''
        },
        {
            id:'product_development',
            label:'Product Development',
            image:''
        },
        {
            id:'business_model_and_strategy',
            label:'Business Model and Strategy',
            image:''
        },
        {
            id:'market_research',
            label:'Market Research',
            image:''
        },
        {
            id:'sales_and_distribution',
            label:'Sales and Distribution',
            image:''
        },
        {
            id:'contract_management',
            label:'Contract Management',
            image:''
        },
        {
            id:'capital_raising',
            label:'Capital Raising',
            image:''
        },
        {
            id:'financial_management',
            label:'Financial Management',
            image:''
        },
        {
            id:'regulatory_compliance_and_licencing',
            label:'Regulatory Compliance and Licencing',
            image:''
        },
        {
            id:'talent_recruitment',
            label:'Talent Recruitment',
            image:''
        },
        {
            id:'admin_management',
            label:'Admin Management',
            image:''
        },
        {
            id:'office_management',
            label:'Office Management',
            image:''
        },
        {
            id:'staff_management',
            label:'Staff Management',
            image:''
        },
        {
            id:'organisational_culture',
            label:'Organisational Culture',
            image:''
        },
        {
            id:'customers',
            label:'Customers',
            image:''
        },
        {
            id:'competitors',
            label:'Competitors',
            image:''
        },
        {
            id:'technology_and_infrastructure',
            label:'Technology and Infrastructure',
            image:''
        },
        {
            id:'intellectual_property',
            label:'Intellectual Property',
            image:''
        },
        {
            id:'cybersecurity_and_data_management',
            label:'Cybersecurity and Data Management',
            image:''
        },
        {
            id:'supply_chain',
            label:'Supply Chain',
            image:''
        },
        {
            id:'scaling_and_growth',
            label:'Scaling and Growth',
            image:''
        },
        {
            id:'partnership_and_collaboration',
            label:'Partnership and Collaboration',
            image:''
        },
        {
            id:'crisis_and_contingency',
            label:'Crisis and Contingency',
            image:''
        },
        {
            id:'ethics',
            label:'Ethics',
            image:''
        },
        {
            id:'social_responsibility',
            label:'Social Responsibility',
            image:''
        },
        {
            id:'environmental_responsibility',
            label:'Environmental Responsibility',
            image:''
        },
        {
            id:'international_expansion',
            label:'International Expansion',
            image:''
        },
        {
            id:'exit_strategy',
            label:'Exit Strategy',
            image:''
        },
        {
            id:'succession_planning',
            label:'Succession Planning',
            image:''
        },
        {
            id:'innovation',
            label:'Innovation',
            image:''
        },
        {
            id:'revenue',
            label:'Revenue',
            image:''
        },
        {
            id:'business_success',
            label:'Business Success',
            image:''
        },
        {
            id:'business_partner',
            label:'Business Partner',
            image:''
        },
        {
            id:'senior_executive',
            label:'Senior Executive',
            image:''
        },
        {
            id:'management',
            label:'Management',
            image:''
        },
        {
            id:'staff',
            label:'Staff',
            image:''
        },
        {
            id:'colleagues',
            label:'Colleagues',
            image:''
        },
        {
            id:'department',
            label:'Department',
            image:''
        },
        {
            id:'clients',
            label:'Clients',
            image:''
        },
        {
            id:'suppliers',
            label:'Suppliers',
            image:''
        },
        {
            id:'service_providers',
            label:'Service Providers',
            image:''
        },
        {
            id:'alliance_partners',
            label:'Alliance Partners',
            image:''
        },
        {
            id:'government_agencies',
            label:'Government Agencies',
            image:''
        },
        {
            id:'industry_peers',
            label:'Industry Peers',
            image:''
        }
    ],

    'existing_business':[
        {
            id:'branding_and_marketing',
            label:'Branding and Marketing',
            image:''
        },
        {
            id:'product_development',
            label:'Product Development',
            image:''
        },
        {
            id:'business_model_and_strategy',
            label:'Business Model and Strategy',
            image:''
        },
        {
            id:'market_research',
            label:'Market Research',
            image:''
        },
        {
            id:'sales_and_distribution',
            label:'Sales and Distribution',
            image:''
        },
        {
            id:'contract_management',
            label:'Contract Management',
            image:''
        },
        {
            id:'capital_raising',
            label:'Capital Raising',
            image:''
        },
        {
            id:'financial_management',
            label:'Financial Management',
            image:''
        },
        {
            id:'regulatory_compliance_and_licencing',
            label:'Regulatory Compliance and Licencing',
            image:''
        },
        {
            id:'talent_recruitment',
            label:'Talent Recruitment',
            image:''
        },
        {
            id:'admin_management',
            label:'Admin Management',
            image:''
        },
        {
            id:'office_management',
            label:'Office Management',
            image:''
        },
        {
            id:'staff_management',
            label:'Staff Management',
            image:''
        },
        {
            id:'organisational_culture',
            label:'Organisational Culture',
            image:''
        },
        {
            id:'cultural_sensitivity',
            label:'Cultural Sensitivity',
            image:''
        },
        {
            id:'customers',
            label:'Customers',
            image:''
        },
        {
            id:'quality_assurance',
            label:'Quality Assurance',
            image:''
        },
        {
            id:'competitors',
            label:'Competitors',
            image:''
        },
        {
            id:'technology_and_infrastructure',
            label:'Technology and Infrastructure',
            image:''
        },
        {
            id:'intellectual_property',
            label:'Intellectual Property',
            image:''
        },
        {
            id:'cybersecurity_and_data_management',
            label:'Cybersecurity and Data Management',
            image:''
        },
        {
            id:'supply_chain',
            label:'Supply Chain',
            image:''
        },
        {
            id:'scaling_and_growth',
            label:'Scaling and Growth',
            image:''
        },
        {
            id:'sustainability',
            label:'Sustainability',
            image:''
        },
        {
            id:'diversification',
            label:'Diversification',
            image:''
        },
        {
            id:'business_evolution_and_pivoting',
            label:'Business Evolution and Pivoting',
            image:''
        },
        {
            id:'operational_efficiency',
            label:'Operational Efficiency',
            image:''
        },
        {
            id:'partnership_and_collaboration',
            label:'Partnership and Collaboration',
            image:''
        },
        {
            id:'crisis_and_contingency',
            label:'Crisis and Contingency',
            image:''
        },
        {
            id:'ethics',
            label:'Ethics',
            image:''
        },
        {
            id:'social_responsibility',
            label:'Social Responsibility',
            image:''
        },
        {
            id:'environmental_responsibility',
            label:'Environmental Responsibility',
            image:''
        },
        {
            id:'international_expansion',
            label:'International Expansion',
            image:''
        },
        {
            id:'mergers_and_acquisitions',
            label:'Mergers and Acquisitions',
            image:''
        },
        {
            id:'exit_strategy',
            label:'Exit Strategy',
            image:''
        },
        {
            id:'succession_planning',
            label:'Succession Planning',
            image:''
        },
        {
            id:'innovation',
            label:'Innovation',
            image:''
        },
        {
            id:'legacy_systems',
            label:'Legacy Systems',
            image:''
        },
        {
            id:'revenue',
            label:'Revenue',
            image:''
        },
        {
            id:'business_success',
            label:'Business Success',
            image:''
        },
        {
            id:'business_partner',
            label:'Business Partner',
            image:''
        },
        {
            id:'senior_executive',
            label:'Senior Executive',
            image:''
        },
        {
            id:'management',
            label:'Management',
            image:''
        },
        {
            id:'staff',
            label:'Staff',
            image:''
        },
        {
            id:'staff_retention',
            label:'Staff Retention',
            image:''
        },
        {
            id:'staff_development',
            label:'Staff Development',
            image:''
        },
        {
            id:'change_management',
            label:'Change Management',
            image:''
        },
        {
            id:'colleagues',
            label:'Colleagues',
            image:''
        },
        {
            id:'department',
            label:'Department',
            image:''
        },
        {
            id:'clients',
            label:'Clients',
            image:''
        },
        {
            id:'suppliers',
            label:'Suppliers',
            image:''
        },
        {
            id:'service_providers',
            label:'Service Providers',
            image:''
        },
        {
            id:'alliance_partners',
            label:'Alliance Partners',
            image:''   
        },
        {
            id:'government_agencies',
            label:'Government Agencies',
            image:''
        },
        {
            id:'industry_peers',
            label:'Industry Peers',
            image:''
        },
        {
            id:'stakeholder_communication',
            label:'Stakeholder Communication',
            image:''
        }
    ]
}


const external_keys = [
    'relationships',
    'finding_a_partner',
    'parenting_/_caregiving',
    'financial_well-being',
    'work',
    'start-up_business',
    'existing_business',
    'community_engagement',
    'higher_calling',
];

const external_they_keys = [
    'relationships',
    'work',
    'existing_business',
]

const struggle_intense_external_domains = [
    {
        id:'relationships',
        image:'https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
        label:'Relationships'
    },
    {
        id:'finding_a_partner',
        image:require('../../assets/static/find_my_partner.jpg'),
        label:'Finding a Partner'
    },
    {
        id:'parenting_/_caregiving',
        label:'Parenting / Caregiving',
        image:require('../../assets/static/parenting_:_caregiving.jpg')
    },
    {
        label:'Financial Well-being',
        id:'financial_well-being',
        image:require('../../assets/static/financial_well-being.jpg')
    },
    {
        id:'work',
        image:'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80',
        label:'Work'
    },
    {
        label:'Start-up Business',
        id:'start-up_business',
        image:'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=512&q=80'
    },
    {
        id:'existing_business',
        image:require('../../assets/static/start_up_existing_business.jpg'),
        label:'Existing Business'
    },
    {
        id:'community_engagement',
        image:require('../../assets/static/community_engagement.jpg'),
        label:'Community Engagement'
    },
    {
        id:'higher_calling',
        image:require('../../assets/static/higher_purpose.jpg'),
        label:'Higher Calling'
    }
]
// ['Mental Wellbeing','Emotional Wellbeing','Physical Wellbeing','Personal Enjoyment'];
const struggle_intense_internal_domains = [
    {
        id:'mental_wellbeing',
        image:require('../../assets/static/mental_wellbeing.png'),
        label:'Mental Well-being'
    },
    {
        id:'emotional_wellbeing',
        image:require('../../assets/static/emotional_wellbeing.png'),
        label:'Emotional Well-being'
    },
    {
        id:'physical_wellbeing',
        image:require('../../assets/static/physical_wellbeing.jpg'),
        label:'Physical Well-being'
    },
    {
        id:'personal_enjoyment',
        image:require('../../assets/static/personal_enjoyment.png'),
        label:'Personal Enjoyment'
    }
]


export {
    struggle_from,
    subdomains,
    struggle_intense_external_domains,
    struggle_intense_internal_domains,
    external_keys,
    external_they_keys
}