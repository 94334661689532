
export default  [
    {
        id:1,
        label:'No intensity',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }

    },
    {
        id:2,
        label:'Very low intensity',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:3,
        label:'Slightly low intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:4,
        label:'Low intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        },
    },
    {
        id:5,
        label:'Moderate intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:6,
        label:'Moderately high intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:7,
        label:'High intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:8,
        label:'Very high intensity',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:9,
        label:'Extremely high intensity',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:10,
        label:'Intense beyond words',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    }
]