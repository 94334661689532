import AWS from 'aws-sdk'
AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: 'AKIAQHQC2ROGD7CT5EAG',
      secretAccessKey: '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',
    }),
});
const docClient = new AWS.DynamoDB.DocumentClient()


export const update_db = (data:any,id:string,key:string,table?:string) => {
    return true;
    // console.log(data,id,key,table);
    // const params = {
    //     TableName:table||'demo_sessions',
    //     Key: {
    //       [table?'session_id':'id']: id
    //     },
    //     UpdateExpression: 'SET #k = :v',
    //     ExpressionAttributeNames: {
    //       '#k': key
    //     },
    //     ExpressionAttributeValues: {
    //       ':v': data
    //     },
    //     // ConditionExpression: 'attribute_exists(session_id)' // Check if the session ID exists
    //   };
    
    // docClient.update(params, function (err, data) {
    //     if (err) {
    //         console.log('Error', err)
    //     } else {
    //         // console.log('Success', data)
    //     }
    // })
};


export const add_db = (data:any,id:string) => {
    return true;
    // const params = {
    //     TableName: 'demo_sessions',
    //     Item: {
    //         id: id,
    //         ...data

    //     }
    // }
    
    // docClient.put(params, function (err, data) {
    //     if (err) {
    //         console.log('Error', err)
    //     } else {
    //         // console.log('Success', data)
    //     }
    // })
};

// IndexName: 'user_id-index',
// KeyConditionExpression: 'user_id = :userId',
// ExpressionAttributeValues: {
//   ':userId': userId
// }
export const get_last_db = (userID:string) => {
    return new Promise(async(resolve,reject)=>{
        const params = {
            TableName: 'demo_sessions',
            FilterExpression: 'userID = :userID',
            ExpressionAttributeValues: {
              ':userID': userID
            },
            ScanIndexForward: false,
            // Limit: 1
        };
        try {
            const result = await docClient.scan(params).promise();
            if (result&&result.Items&&result.Items?.length > 0) {
                const sorted = result.Items.sort((a:any,b:any)=>{
                    return b.update_at - a.update_at;
                });
                const gpt_response = await fetch_by_id(sorted[0].id);
                resolve({app:sorted[0],gpt:gpt_response});
              } else {
                resolve(null) // No items found
              }
          }catch (error) {
            console.log('Error retrieving last updated item:', error);
            reject(error);
        }
    });
};


export const fetch_by_id = (id:string,table?:string) => {
    return new Promise(async(resolve,reject)=>{
        try{
            const params = {
                TableName: table?table:'verve_demo_session_gpt_response',
                Key: {
                    session_id: id
                }
            };
            const result = await docClient.get(params).promise();
            if(!result.Item){
                resolve({});
                return;
            }
            let new_item = {...result.Item};
            const keys = Object.keys(result.Item);
            if(!table){
                keys.forEach((key)=>{
                    new_item[key] = {
                        value:result&&result.Item?result.Item[key].value:{}
                    }
                });
            }
            resolve(new_item);
        }catch(e){
            console.log(e);
            reject(e);
        }
    });
};