import React from 'react';
import {motion} from 'framer-motion';
import RateText from '../../../utils/components/rate_text';
import { getHighestNumber } from '../../../../../utils/number/get_highest';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { get_response } from '../../../../../utils/request/get';
import { format_rate_obj } from '../../../utils/formatters';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';





class EmpoweringThought extends React.PureComponent<any,any>{
    is_express: boolean;

    selected: any;
    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        this.state = {
            loading:this.props.thoughts_recon_fetched&&this.props.thoughts_recon_fetched.length>0?false:true,
            error:false,
            options:this.props.thoughts_recon_fetched||[],
            selected:[...default_keys],
            status_code:0
        };
        this.selected = { 
            ...df
        };  
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };



    componentDidMount(): void {
        if(!this.props.thoughts_recon_fetched||(this.props.thoughts_recon_fetched&&this.props.thoughts_recon_fetched.length===0)){
            this._fetch_thoughts();
        }
    };


    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_thoughts();
    };


    _fetch_thoughts = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'recon_thoughts',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            let options:any[] = [];
            data.res.map((txt:string)=>{
                options.push({
                    id:`${txt}`.replaceAll(' ','_'),
                    label:txt
                });
            });
            this.props.update_state(options,data.prompt,data.tools);
            console.log(data);
            update_db({value:options},this.props.session_id,'thoughts_recon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false});
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };


    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };

    _next = () =>{
        // const value = getHighestNumber(Object.values(this.selected));

        // if(!value){
        //     alert('Should only have one highest rated empowering thought.')
        //     return {
        //         go:false,
        //         value:{

        //         }
        //     };
        // };

        return {
            go:this.state.selected.length>0,
            value:{
                ...this.selected
            }
        }
    };

    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'thoughts_recon_fetched','verve_demo_session_gpt_response')
    };

    _on_select = (id:string) => {
        if(this.state.selected.includes(id)){
            this.setState((state:any)=>({
                selected:state.selected.filter((item:string)=>item!==id)
            }));
            delete this.selected[id];
        }else{
            this.setState((state:any)=>({
                selected:[...state.selected,id]
            }));
            this.selected[id] = 1;
        }
    };
    
    
    _render_ratings = (item:any) => {
        return (
            <RateText is_commitment rl_text="Rate your commitment" onChangeRate={this._on_change_rate} defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} {...item} key={item.id} selected={this.state.selected.includes(item.id)} onChange={this._on_select}/>
        )
    };


    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                  {
                    this.state.loading?
                    <PromptLoader message={this.is_express?"Based on your selected belief/s, here come new empowering thoughts to select from.":"From evolving your beliefs, look at the typical empowering thoughts you would like to commit to!"}/>
                    :
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_thoughts}/>
                    :
                        <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                            <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Embracing New Thoughts</span>
                            <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">
                            {this.is_express?
                            'Choose the thoughts that will help you begin better visualising and rehearsing to improve your approach.'
                            :
                            <>
                                These thoughts will help you begin better visualising and rehearsing your ‘new way to be’.
                                <br />
                                <br />
                                Choose the thoughts you want to embrace and rate your level of commitment as to how prepared you are to embrace them.
                                <br />
                                <br />
                                Any doubts and reservations you may feel will be addressed later.
                            </>}
                            </span>
                            <span className='font-raleway font-medium text-[10px] leading-[12px] text-title flex pb-4'>Select all that apply.</span>
                            <motion.div layout className='space-y-2'>
                                {this.state.options.map(this._render_ratings)}
                            </motion.div>
                        </motion.div>
                    }
            </div>
        );
    };
};



const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["recon_thoughts"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            empowering_beliefs:format_rate_obj(state.app["recon_belief"]),
        },
        thoughts_recon_fetched:state.response['thoughts_recon_fetched']?.response||state.response['thoughts_recon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    }
};


const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'thoughts_recon_fetched',value}));
            dispatch(update_prompt_state({key:'thoughts_recon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'thoughts_recon_fetched',value:tools}));
         },
         
    }    
};


export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(EmpoweringThought);