import React from 'react';
import ListReviewer from '../../../utils/components/listReviewer';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';

class BeliefRatingReview extends React.PureComponent<any,any>{
    items: any[];

    constructor(props:any) {

        super(props);
        let options:any[] = [];
        Object.keys(this.props.items).map((key:any) => {
            options.push({
                id:key,
                title:`${capitalizeFirstLetter(id_to_text(key))}.`,
                intensity:this.props.items[key],
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
                return b.intensity - a.intensity;
        });
        options[0]= {
            ...options[0],
            mainFocus:true
        }

        this.items = [
            {
                id:'beliefs_intensity',
                titles:['Beliefs/Assumptions','Intensity'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold mb-4">What You Believe</span>
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">This is the time to appreciate that having disempowering beliefs is a normal part of being human. Giving yourself permission to be a work-in-progress is incredibly empowering and will accelerate your growth.
                 </span>
                <ListReviewer items={this.items}/>
            </div>
        )
    }
}


const map_state_to_props = (state:RootState) => {
    return {    
        items:{
            ...state.app["decon_belief_rating"],
        },
    }
};




export default connect(map_state_to_props,null,null,{forwardRef:true})(BeliefRatingReview);