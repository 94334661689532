import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../../db/redux/store";


class Congratulations extends React.PureComponent<any,any>{
    is_express: boolean;

    constructor(props:any) {
        super(props);
        this.is_express = Boolean(this.props.circ==='prepare_myself')
    }
    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                    <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold">Your New Way To Be!</span>
                    <img src={this.is_express? require('../../../../../assets/static/express_new_way.jpeg') : require('../../../../../assets/bg/new_way_to_be.jpg')} className='object-cover w-full h-40 mt-4 mb-4'/>
                    <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>{this.is_express?'You are now going to receive guidance for a new, empowered approach.':'You are now going to receive guidance for a better way to represent your wisdom and what you want to experience.'} 
                    <br />
                    <br />
                      {this.is_express?'The first step is replacing your disempowering beliefs with empowering ones.':'First, your disempowering beliefs will be replaced with empowering ones, helping you to begin breaking through the obstacles in your circumstance.'}
                    </span>
            </div>
        )
    };
};

const map_state_to_props = (state:RootState) => {
    return {
        circ:state.app?.circgen_type?.selected
    };
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(Congratulations);