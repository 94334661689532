import React from 'react';
import ListReviewer from '../../../utils/components/listReviewer';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';

class EmpoweringActionRateReview extends React.PureComponent<any,any>{
    items: any[];

    constructor(props:any) {

        super(props);
        let options:any[] = [];
        Object.keys(this.props.items).map((key:any) => {
            options.push({
                id:key,
                title:`${capitalizeFirstLetter(id_to_text(key))}`,
                intensity:this.props.items[key],
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
                return b.intensity - a.intensity;
        });
        // options[0]={
        //     ...options[0],
        //     mainFocus:true
        // }
        this.items = [
            {
                id:'thouts_intensity',
                titles:['Actions','Commitment'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Embracing New Actions</span>
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">
                Imagine yourself acting out each action and what you could attract by doing so.
                </span>
                 <ListReviewer items={this.items}/>
            </div>
        )
    };
};





const map_state_to_props = (state:RootState) => {
    return {
        items:{
            ...state.app["recon_empowering_actions"],
        }
    }
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(EmpoweringActionRateReview);