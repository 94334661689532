import BeganAndOccurance from "../../screens/home/steps/circgen/began_and_occurance";
import DeepDiveHighestRankingDomain from "../../screens/home/steps/circgen/deep_dive_highes_ranking_domain";
import EmotionalRating from "../../screens/home/steps/circgen/emotional_rating";
import ExternalWorldAssessment from "../../screens/home/steps/circgen/external_world_assesstment";
import FullImpact from "../../screens/home/steps/circgen/full_impact";
import InnerSelfAssesstment from "../../screens/home/steps/circgen/inner_self_assesstment";
import instruction from "../../screens/home/steps/circgen/instruction";
import Introduction from "../../screens/home/steps/circgen/introduction";
import Occurrence from "../../screens/home/steps/circgen/occurance";
import RankedDomain from "../../screens/home/steps/circgen/ranked_domain";
import RateEmotionIntensity from "../../screens/home/steps/circgen/rate_emotion_intensity";
import RateSustainability from "../../screens/home/steps/circgen/rate_sustainability";
import SelectCircumstance from "../../screens/home/steps/circgen/select_circumstance";
import CircumstanceSummary from "../../screens/home/steps/circgen/summary";
import Theme from "../../screens/home/steps/circgen/theme";
import ValueRating from "../../screens/home/steps/circgen/value_rating";
import VisionWheel from "../../screens/home/steps/circgen/vision_wheel";
import ActionRatingReview from "../../screens/home/steps/decon_recon/decon/action_rating_review";
import ActionRelavanceFocus from "../../screens/home/steps/decon_recon/decon/action_relavance_focus";
import ActionRelavanceRating from "../../screens/home/steps/decon_recon/decon/action_relavance_rating";
import BeliefPrincipalRating from "../../screens/home/steps/decon_recon/decon/belief_principal_rating";
import BeliefRating from "../../screens/home/steps/decon_recon/decon/belief_rating";
import BeliefRatingReview from "../../screens/home/steps/decon_recon/decon/belief_rating_review";
import Congratulations from "../../screens/home/steps/decon_recon/decon/congratulations";
import DeconActionIntroduction from "../../screens/home/steps/decon_recon/decon/decon_action_introduction";
import DeconBeliefsIntroductionReview from "../../screens/home/steps/decon_recon/decon/decon_beliefs_introduction_review";
import DeconBeliefsIntroduction from "../../screens/home/steps/decon_recon/decon/decon_belif_introduction";
import DeconEmotionIntroduction from '../../screens/home/steps/decon_recon/decon/decon_emotion_introduction';
import DeconThoughtIntroduction from "../../screens/home/steps/decon_recon/decon/decon_thoughts_introduction";
import EmotionalRatingIntensity from "../../screens/home/steps/decon_recon/decon/emotional_rating_intensity";
import EmotionalRatingReview from "../../screens/home/steps/decon_recon/decon/emotional_rating_review";
import EmotionalRatingSelect from "../../screens/home/steps/decon_recon/decon/emotional_rating_select";
import EmotionalRatingSelectTop from "../../screens/home/steps/decon_recon/decon/emotional_rating_select_top";
import ThoughtRatingReview from "../../screens/home/steps/decon_recon/decon/thought_rating_review";
import ThoughtRelevance from "../../screens/home/steps/decon_recon/decon/thought_relavance";
import ThoughtRelevanceRating from "../../screens/home/steps/decon_recon/decon/thought_relavance_rating";
import CommitToChange from "../../screens/home/steps/decon_recon/recon/commit_to_change";
import Complete from "../../screens/home/steps/decon_recon/recon/complete";
import EmpoweringAction from "../../screens/home/steps/decon_recon/recon/empowering_action";
import EmpoweringActionRateReview from "../../screens/home/steps/decon_recon/recon/empowering_action_rate_review";
import EmpoweringBelief from "../../screens/home/steps/decon_recon/recon/empowering_belief";
import EmpoweringEmotion from "../../screens/home/steps/decon_recon/recon/empowering_emotion";
import EmpoweringEmotionRate from "../../screens/home/steps/decon_recon/recon/empowering_emotion_rate";
import EmpoweringEmotionRateReview from "../../screens/home/steps/decon_recon/recon/empowering_emotion_rate_review";
import EmpoweringThought from "../../screens/home/steps/decon_recon/recon/empowering_thought";
import EmpoweringThoughtRatingReview from "../../screens/home/steps/decon_recon/recon/empowering_thought_rating_review";
import DeconReconGreatWork from "../../screens/home/steps/decon_recon/recon/great_work";
import ReconSummary from "../../screens/home/steps/decon_recon/recon/recon_summary";


const step_name = [
    {
        parent:1,
        id:'circgen_intro',
        name:'Introduction',
        has_retry:false
    },
    {
        parent:1,
        id:'instruction',
        name:'Instruction',
        has_retry:false
    },
    {
        parent:1,
        id:'circgen_type',
        name:'Select Circumstance'
    },
    {  
        parent:1,
        id:'circgen_vision_wheel',
        name:'Your Focus'
    },
    {
        parent:1,
        id:'circgen_theme',
        name:'Theme'
    },
    {
        parent:1,
        id:'circgen_impact',
        name:'Impact'
    },
    {
        parent:1,
        id:'circgen_duration_occurance',
        name:'Duration & Occurrence'
    },
    {
        parent:1,
        id:'circgen_sus_dev',
        name:'Sustainability'
    },
    // {
    //     parent:1,
    //     id:'circgen_full_impact_intro',
    //     name:'Full Impact'
    // },
    {
        parent:1,
        id:'circgen_vision_wheel_internal',
        name:'Inner Self Assessment'
    },
    {
        parent:1,
        id:'circgen_vision_wheel_external',
        name:'External World Assessment'
    },
    {
        parent:1,
        id:'circgen_ranked_domain',
        name:'Areas of Focus'
    },
    {
        parent:1,
        id:'circgen_deep_dive',
        name:'Looking Deeper'
    },
    {
        parent:1,
        id:'circgen_select_emotion',
        name:'Emotional Impact'
    },
    {
        parent:1,
        id:'circgen_rate_emotion',
        name:'Rate Emotional Impact'
    },
    {
        parent:1,
        id:'circgen_value_rating',
        name:'Giving & Receiving'
    },
    {
        parent:1,
        id:'circgen_circumstance',
        name:'Circumstance Summary',
        has_retry:true,
    },
    // {
    //     parent:2,
    //     id:'decon_intro',
    //     name:'Decon - Emotion Introduction'
    // },
    {
        parent:2,
        id:'decon_emotions_rate',
        name:'How You Feel',
        has_retry:true,
    },
    {
        parent:2,
        id:'decon_emotions_rate_intensity',
        name:'Emotional Intensity'
    },
    // {
    //     parent:2,
    //     id:'decon_emotions_top_emotion',
    //     name:'Decon - Top Emotion'
    // },
    {
        parent:2,
        id:'decon_emotions_summary',
        name:'Emotional Summary'
    },
    // {
    //     parent:2,
    //     id:'decon_intro_thoughts',
    //     name:'Decon - What you think'
    // },
    {
        parent:2,
        id:'decon_thoughts_relevance',
        name:'What You Think',
        has_retry:true,
    },
    // {
    //     parent:2,
    //     id:'decon_thoughts_relevance_rating',
    //     name:'Decon - Top Thought'
    // },
    {
        parent:2,
        id:'decon_thoughts_relevance_summary',
        name:'Thought Summary'
    },
    // {
    //     parent:2,
    //     id:'decon_action_intro',
    //     name:'Decon - Action Introduction'
    // },
    {
        parent:2,
        id:'decon_action_relevance',
        name:'How You Act',
        has_retry:true,
    },
    // {
    //     parent:2,
    //     id:'decon_action_relevance_focus',
    //     name:'Decon - Top Action'
    // },
    {
        parent:2,
        id:'decon_action_summary',
        name:'Action Summary'
    },
    // {
    //     parent:2,
    //     id:'decon_belief_intro',
    //     name:'Decon - Belief Introduction'
    // },
    // {
    //     parent:2,
    //     id:'decon_belief_review',
    //     name:'Decon - Belief Review'
    // },
    {
        parent:2,
        id:'decon_belief_rating',
        name:'What You Believe',
        has_retry:true,
    },
    {
        parent:2,
        id:'decon_belief_rating_summary',
        name:'Belief Summary'
    },
    {
        parent:2,
        id:'decon_principle_deviation',
        name:'Principle Clash',
        has_retry:true,
    },
    {
        parent:2,
        id:'decon_congratulations',
        name:'Creating New Way'
    },
    {
        parent:2,
        id:'recon_belief',
        name:'Empowering Beliefs',
        has_retry:true,
    },
    {
        parent:2,
        id:'recon_thoughts',
        name:'Empowering Thoughts',
        has_retry:true,
    },
    {
        parent:2,
        id:'recon_thoughts_summary',
        name:'Empowering Thoughts Summary'
    },
    {
        parent:2,
        id:'recon_empowering_emotions',
        name:'Empowering Emotions',
        has_retry:true,
    },
    {
        parent:2,
        id:'recon_empowering_emotions_rate',
        name:'Emotional Engagement'
    },
    {
        parent:2,
        id:'recon_empowering_emotions_rate_review',
        name:'Empowering Emotions Summary'
    },
    {
        parent:2,
        id:'recon_empowering_actions',
        name:'Empowering Actions',
        has_retry:true,
    },
    {
        parent:2,
        id:'recon_empowering_actions_summary',
        name:'Empowering Actions Summary'
    },
    // {
    //     parent:2,
    //     id:'recon_decon_great_work',
    //     name:'Recon - Done'
    // },
    {
        parent:2,
        id:'recon_summary',
        name:'New Way Summary'
    },
    {
        parent:2,
        id:'commit_to_change',
        name:'Commitment'
    },
    {
        parent:3,
        id:'completed_all',
        name:'Completed'
    }
];

// get_response({type:'decon_beliefs'
const map_steps_to_gpt = {
    'dominant_theme_gpt':'dominant_theme',
    'subthemes_gpt':'sub_themes',
    'summary_fetched':'generate_summary',
    'emotions_decon_fetched':'decon_get_emotions',
    'thoughts_decon_fetched':'decon_thoughts',
    'action_decon_fetched':'decon_actions',
    'belief_decon_fetched':'decon_beliefs',
    'principal_decon_fetched':'decon_principal',
    'belief_recon_fetched':'recon_beliefs',
    'thoughts_recon_fetched':'recon_thoughts',
    'emotions_recon_fetched':'recon_emotions',
    'action_recon_fetched':'recon_actions'
};

const gpt_steps = {
    'circgen_theme':['dominant_theme_gpt', 'subthemes_gpt'],
    'circgen_circumstance':['summary_fetched'],
    'decon_emotions_rate':['emotions_decon_fetched'],
    'decon_thoughts_relevance':['thoughts_decon_fetched'],
    'decon_action_relevance':['action_decon_fetched'],
    'decon_belief_rating':['belief_decon_fetched'],
    'decon_principle_deviation':['principal_decon_fetched'],
    'recon_belief':['belief_recon_fetched'],
    'recon_thoughts':['thoughts_recon_fetched'],
    'recon_empowering_emotions':['emotions_recon_fetched'],
    'recon_empowering_actions':['action_recon_fetched'],
    
};


const express_step_name =  [
    {
        parent:1,
        id:'circgen_intro',
        name:'Introduction',
        has_retry:false,
    },
    {
        parent:1,
        id:'instruction',
        name:'Instruction',
        has_retry:false,
    },
    {
        parent:1,
        id:'circgen_type',
        name:'Select Circumstance'
    },
    {
        parent:1,
        id:'circgen_theme',
        name:'Preparation Theme'
    },
    {
        parent:1,
        id:'circgen_duration_occurance',
        name:'Duration & Occurrence'
    },
    {
        parent:1,
        id:'circgen_sus_dev',
        name:'Preparation'
    },
    {
        parent:1,
        id:'circgen_select_emotion',
        name:'Emotional Impact'
    },
    // {
    //     parent:1,
    //     id:'circgen_rate_emotion',
    //     name:'Rate Emotional Impact'
    // },
    {
        parent:1,
        id:'circgen_value_rating',
        name:'Giving & Receiving'
    },
    {
        parent:1,
        id:'circgen_circumstance',
        name:'Circumstance Summary',
        has_retry:true
    },
    // {
    //     parent:2,
    //     id:'decon_emotions_rate',
    //     name:'How You Feel'
    // },
    // {
    //     parent:2,
    //     id:'decon_emotions_rate_intensity',
    //     name:'Emotional Intensity'
    // },
    {
        parent:2,
        id:'decon_thoughts_relevance',
        name:'What You Think',
        has_retry:true
    },
    {
        parent:2,
        id:'decon_action_relevance',
        name:'How You Act',
        has_retry:true
    },
    {
        parent:2,
        id:'decon_belief_rating',
        name:'What You Believe',
        has_retry:true
    },
    {
        parent:2,
        id:'decon_principle_deviation',
        name:'Principle Clash',
        has_retry:true
    },
    {
        parent:2,
        id:'decon_congratulations',
        name:'Creating New Way'
    },
    {
        parent:2,
        id:'recon_belief',
        name:'Empowering Beliefs',
        has_retry:true
    },
    {
        parent:2,
        id:'recon_thoughts',
        name:'Empowering Thoughts',
        has_retry:true
    },
    {
        parent:2,
        id:'recon_empowering_emotions',
        name:'Empowering Emotions',
        has_retry:true
    },
    // {
    //     parent:2,
    //     id:'recon_empowering_emotions_rate',
    //     name:'Emotional Engagement'
    // },
    {
        parent:2,
        id:'recon_empowering_actions',
        name:'Empowering Actions',
        has_retry:true
    },
    {
        parent:2,
        id:'recon_summary',
        name:'New Way Summary'
    },
    {
        parent:2,
        id:'commit_to_change',
        name:'Commitment'
    },
    {
        parent:3,
        id:'completed_all',
        name:'Completed'
    }
]

const express_steps = [ 
    'circgen_intro',
    'instruction',
    'circgen_type',
    'circgen_theme',
    'circgen_duration_occurance',
    'circgen_sus_dev',
    'circgen_select_emotion',
    // 'circgen_rate_emotion',
    'circgen_value_rating',
    'circgen_circumstance',
    // 'decon_emotions_rate',
    // 'decon_emotions_rate_intensity',
    'decon_thoughts_relevance',
    'decon_action_relevance',
    'decon_belief_rating',
    'decon_principle_deviation',
    'decon_congratulations',
    'recon_belief',
    'recon_thoughts',
    'recon_empowering_emotions',
    // 'recon_empowering_emotions_rate',
    'recon_empowering_actions',
    'recon_summary',
    'commit_to_change',
    'completed_all'

];

const steps = [
    'circgen_intro',
    'instruction',
    'circgen_type',
    'circgen_vision_wheel',
    'circgen_theme',
    'circgen_impact',
    'circgen_duration_occurance',
    'circgen_sus_dev',
    // 'circgen_full_impact_intro',
    'circgen_vision_wheel_internal',
    'circgen_vision_wheel_external',
    'circgen_ranked_domain',
    'circgen_deep_dive',
    'circgen_select_emotion',
    'circgen_rate_emotion',
    'circgen_value_rating',
    'circgen_circumstance',
    // decon
    // 'decon_intro',
    'decon_emotions_rate',
    'decon_emotions_rate_intensity',
    // 'decon_emotions_top_emotion',
    'decon_emotions_summary',
    // 'decon_intro_thoughts',
    'decon_thoughts_relevance',
    // 'decon_thoughts_relevance_rating',
    'decon_thoughts_relevance_summary',
    // 'decon_action_intro',
    'decon_action_relevance',
    // 'decon_action_relevance_focus',
    'decon_action_summary',
    // 'decon_belief_intro',
    // 'decon_belief_review',
    'decon_belief_rating',
    'decon_belief_rating_summary',
    'decon_principle_deviation',
    'decon_congratulations',
    'recon_belief',
    'recon_thoughts',
    'recon_thoughts_summary',
    'recon_empowering_emotions',
    'recon_empowering_emotions_rate',
    'recon_empowering_emotions_rate_review',
    'recon_empowering_actions',
    'recon_empowering_actions_summary',
    // 'recon_decon_great_work',
    'recon_summary',  
    // decon
    'commit_to_change',
    'completed_all'
];



const get_step_component = (step: string):any => {
    switch(step) {
        case 'instruction':
            return instruction;
        // decon
        case 'decon_intro':
            return DeconEmotionIntroduction;
        case 'decon_emotions_rate':
            return EmotionalRatingSelect;
        case 'decon_emotions_rate_intensity':
            return EmotionalRatingIntensity;
        case 'decon_emotions_top_emotion':
            return EmotionalRatingSelectTop;
        case 'decon_emotions_summary':
            return EmotionalRatingReview;
        case 'decon_intro_thoughts':
            return DeconThoughtIntroduction;
            // Relevance
        case 'decon_thoughts_relevance':
            return ThoughtRelevance;
        case 'decon_thoughts_relevance_rating':
            return ThoughtRelevanceRating;
        case 'decon_thoughts_relevance_summary':
            return ThoughtRatingReview;
        case 'decon_action_intro':
            return DeconActionIntroduction;
        case 'decon_action_relevance':
            return ActionRelavanceRating; 
        case 'decon_action_relevance_focus':
            return ActionRelavanceFocus;
        case 'decon_action_summary':
            return ActionRatingReview;
        case 'decon_belief_intro':
            return DeconBeliefsIntroduction;
        case 'decon_belief_review':
            return DeconBeliefsIntroductionReview;
        case 'decon_belief_rating':
            return BeliefRating;
        case 'decon_belief_rating_summary':
            return BeliefRatingReview;
            // Principle
        case 'decon_principle_deviation':
            return BeliefPrincipalRating;
        case 'decon_congratulations':
            return Congratulations;
        case 'recon_belief':
            return EmpoweringBelief;
        case 'recon_thoughts':
            return EmpoweringThought;
        case 'recon_thoughts_summary':
            return EmpoweringThoughtRatingReview;
        case 'recon_empowering_emotions':
            return EmpoweringEmotion;
        case 'recon_empowering_emotions_rate':
            return EmpoweringEmotionRate;
        case 'recon_empowering_emotions_rate_review':
            return EmpoweringEmotionRateReview;
        case 'recon_empowering_actions':
            return EmpoweringAction;
        case 'recon_empowering_actions_summary':
            return EmpoweringActionRateReview;
        case 'recon_decon_great_work':
            return DeconReconGreatWork;
        case 'recon_summary':
            return ReconSummary;
        case 'commit_to_change':
            return CommitToChange;
        // decon
        case 'circgen_intro':
            return Introduction;
        case 'circgen_type':
            return SelectCircumstance;
        case 'circgen_vision_wheel':
            return VisionWheel;
        case 'circgen_theme':
            return Theme;
        case 'circgen_impact':
            return Occurrence;
        case 'circgen_duration_occurance':
            return BeganAndOccurance;
        case 'circgen_sus_dev':
            return RateSustainability;
        case 'circgen_full_impact_intro':
            return FullImpact;
        case 'circgen_vision_wheel_internal':
            return InnerSelfAssesstment;
        case 'circgen_vision_wheel_external':
            return ExternalWorldAssessment;
        case 'circgen_ranked_domain':
            return RankedDomain;
        case 'circgen_deep_dive':
            return DeepDiveHighestRankingDomain;
        case 'circgen_select_emotion':
            return EmotionalRating;
        case 'circgen_rate_emotion':
            return RateEmotionIntensity;
        case 'circgen_value_rating':
            return ValueRating;
        case 'circgen_circumstance':
            return CircumstanceSummary;
        case 'completed_all':
            return Complete;
        default:
            return null;
    };
};

export {
    get_step_component,
    steps,
    step_name,
    gpt_steps,
    map_steps_to_gpt,
    express_step_name,
    express_steps
};