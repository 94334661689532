import React from "react";
import ListReviewer from "../../../utils/components/listReviewer";
import { RootState } from "../../../../../db/redux/store";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";



class EmpoweringEmotionRateReview extends React.PureComponent<any,any>{
    items: any[];
    constructor(props:any) {
        super(props);
        let options:any[] = [];
        Object.keys(this.props.rating).map((key:any) => {
            options.push({
                id:key,
                // icon:key,
                title:capitalize(key),
                intensity:this.props.rating[key],
                // mainFocus:this.props.top_emotion === key
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
             return b.intensity - a.intensity;
        });

        this.items = [
            {
                id:'emotion_intensity',
                titles:['Emotion','Commitment'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };


    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col pb-40 overflow-scroll">
               <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Embracing New Emotions</span>
               <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Here are your empowering emotions, which define your desired emotional state as you make changes.
            </span>
               <ListReviewer items={this.items}/>
            </div>
        )
    };
};




const map_state_to_props = (state:RootState) => {
    return {
        rating:state.app['recon_empowering_emotions_rate']||{}
    };
};



export default connect(map_state_to_props,null,null,{forwardRef:true})(EmpoweringEmotionRateReview);