import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import { RootState } from '../../../../db/redux/store';
import id_to_text from '../../utils/text/id_to_text';
import { arry_of_strings_to_text } from '../../utils/text';


const format_subdomain = (subdomain:string) => {
    if(subdomain.includes('your')){
        return `'${subdomain}'`;
    };
    return subdomain;
};

class RateSustainability extends React.PureComponent<any,any>{
    rate: number;
    is_express: boolean;

    
    constructor(props:any) {
        super(props);
        this.rate = this.props.sus_dev_rate;
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    }
    _next = () => {
        return {
            go:true,
            value:{
                sus_dev_rate:this.rate
            }
        }
    };  

    _change = (value:number) => {
        this.rate = value;
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">{this.is_express?'How prepared are you currently?':'How long are you willing to be impacted in this way?'}</span>
               {this.is_express?null:<span className='font-quick text-title text-[14px] leading-[18px] flex mt-4 font-medium'>{`"My ${this.props.domain}, specifically related to my ${format_subdomain(this.props.subdomain)}, is affected by ${`${this.props.theme}`.toLowerCase()} that is associated with ${this.props.subtheme}"`}</span>}
                <SustainabilityCard isExpress={this.is_express} onChange={this._change} defaultValue={this.props.sus_dev_rate} />
            </div>
        )
    }
}





const map_state_to_props = (state:RootState) => {
    return {
        domain:id_to_text(state.app['circgen_vision_wheel']?.struggle_intense||''),
        subdomain:id_to_text(state.app['circgen_vision_wheel']?.focus||''),
        theme:id_to_text(state.app['circgen_theme']?.theme?.id||''),
        subtheme:arry_of_strings_to_text(id_to_text(state.app['circgen_theme']?.subtheme||'')),
        sus_dev_rate:state.app['circgen_sus_dev']?.sus_dev_rate||0,
        circ:state.app?.circgen_type?.selected
    }
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(RateSustainability);



function roundToTen(num:number) {
    return Math.round(num / 10);
};


const values = [
    'It can\'t continue another minute',
    'It can\'t continue another day.',
    'It can\'t continue for another week.',
    'It can\'t go beyond a month.',
    'It can\'t go beyond 3 months.',
    'It\'s affecting me a lot.',
    'It\'s affecting me quite a bit.',
    'It\'s starting to affect me.',
    'I can tolerate the impact.',
    'Not much impact.',
    'No impact at all.'
];


const express_values = [
    'Totally Clueless',
    'Just Winging It',
    'Very Nervous',
    'Barely Prepared',
    'Halfway There',
    'Kind of Ready',
    'At the Basic Level',
    'Feeling Positive',
    'Almost There',
    'Super Close',
    'Ready for the Next Level'
]


const colors = {
    0:{
        text:'#BD574F',
        bg:'#FCE3E1'
    },
    1:{
        text:'#ED9126',
        bg:'#FBE7CF'
    },
    2:{
        text:'#7DCFB6',
        bg:'#E9F7F5'
    }

}

const get_colors = (rate:number) => {
    if(rate<=4){
        return colors[0];
    }
    if(rate<=8){
        return colors[1];
    };
    return colors[2];
};


const SustainabilityCard = ({defaultValue,onChange,isExpress}:{isExpress:boolean, onChange:any,defaultValue:number}) => {

    const [rate,setRate] = useState(defaultValue);

    const _on_change = (value:number) => {
        const v = roundToTen(value);
        if(v===rate){
            return;
        };
        onChange(v);
        setRate(v);
    };

    const clr = get_colors(rate);

    return (
        <div className='flex flex-col w-full rounded-2xl border border-br-gray py-2.5 px-4 mt-6 pb-4'>

            <div className='flex w-full flex-row justify-between pb-4 border-b border-dashed border-br-cream '>
                <div className='flex flex-col'>
                    <span className='text-text font-quick text-[16px] leading-[21px] flex mb-1.5 font-medium'>{isExpress?'Preparation':'Sustainability'}</span>
                    <span className='flex flex-row items-center font-raleway text-title text-[12px] leading-[14px]'><img src={require('../../../../assets/icons/info.png')} className="w-3 mr-1"/>{isExpress?express_values[rate]:values[rate]}</span>
                </div>
                <div style={{backgroundColor:clr.bg}} className='w-14 h-14 rounded-full flex justify-center items-center'>
                        <div className='flex flex-row items-end'>
                                <span style={{color:clr.text}} className='font-quick text-[32px] leading-[28px] font-semibold'>{rate}</span>
                                <span style={{color:clr.text}} className='font-quick text-[10px] leading-[12px] font-semibold'>/10</span>
                        </div>
                </div>
            </div>

            <div className='pt-4'>
                <span className='font-raleway text-title text-[10px] leading-[12px] flex mb-4'>Slide to rate the level of {isExpress?'Preparation':'Sustainability'}</span>
                <ReactSlider defaultValue={defaultValue*10} onChange={_on_change} className='slider_active'  trackClassName="slider_track" renderThumb={(props:any, state:any) => <div {...props} className="w-4 h-4 rounded-full track_shadow outline-none ring-0 -top-[7px] border-[3px] border-br bg-btn ovr_rnd"/> } />
                <div className='w-full flex flex-row justify-between items-center pt-3.5'>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>0</span>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>10</span>
                </div>
            </div>

        </div>
    )
};



// You will get number between 0-100, write a function that will return 0-10 in rounded number