import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { steps } from '../../../../utils/steps';


interface AppState {
    [key:string]:any;
    session_seed?:string|null;   
};

const initialState:AppState = {
    session_seed:null
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
        update_state:(state,action:PayloadAction<{key:string,value:object}>)=>{
            const {key,value} = action.payload;
            state[key as keyof AppState] = {
                // ...state[key as keyof AppState],
                ...value
            };
            //check if the update step has any steps behind it and delete those steps from state
            const step_index = steps.indexOf(key);
            if(step_index!==-1){
                const steps_to_remove = steps.slice(step_index+1);
                steps_to_remove.forEach(step=>{
                    if(state[step as keyof AppState]){
                        delete state[step as keyof AppState];
                    };
                }
                );
            };
        },
        set_state:(state,action:PayloadAction<any>)=>{
                const keys = Object.keys(action.payload);
                keys.forEach(key=>{
                    state[key as keyof AppState] = action.payload[key];
                });

            //  state = {...action.payload}
        },
        set_session_seed:(state,action:PayloadAction<string>)=>{
            state.session_seed = action.payload;
        },
        remove_a_state:(state,action:PayloadAction<string>)=>{
            const key = action.payload;
            if(state[key as keyof AppState]){
                delete state[key as keyof AppState];
            };
        },
        clear_state:(state)=>{
            return initialState;
        }
  },
});

// Action creators are generated for each case reducer function
export const {update_state,remove_a_state,clear_state,set_session_seed,set_state} = appSlice.actions;

export default appSlice.reducer;


