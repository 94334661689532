import React from 'react';
// import EmojiCard from '../../../utils/components/emojiCard';
// import emojies from '../../../utils/emoji/list.json';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import { get_response } from '../../../../../utils/request/get';
import {motion} from 'framer-motion'
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import InlineMultiSelect from '../../../utils/components/inlineMultiSelect';
import trim from 'lodash/trim';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import uniqBy from 'lodash/uniqBy';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst'
import id_to_text from '../../../utils/text/id_to_text';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';


const cross_check = (v:any,defaultValue:any) =>{
    // check if the array themes includes the item from defaultValue and return a array where defaultValue item also presented in themes
    const to_return:any[] = [];
    defaultValue.map((item:any) => {
        if(v.find((theme:any) => theme.id === item)){
            to_return.push(item);
        }
    });
    return to_return;
};



class EmotionalRatingSelect extends React.PureComponent<any,any>{

    constructor(props:any){
        super(props);
        const df = this.props.emotional_rating;
        this.state = {
            loading:this.props.emotions_decon_fetched&&this.props.emotions_decon_fetched.length>0?false:true,
            error:false,
            emojies:this.props.emotions_decon_fetched||[],
            selected:df||[],
            status_code:0
        }
    }

    componentDidMount(): void {
        if(!this.props.emotions_decon_fetched||(this.props.emotions_decon_fetched&&this.props.emotions_decon_fetched.length===0)){
            this._fetch_emotions();
        };
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.setState({selected:[]});
        this._fetch_emotions();
    };

    remove_number_prefix = (text:string) =>  {
        return text.replace(/^\d+[:.]\s*/, '');
    }

    _fetch_emotions = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'decon_get_emotions',values:{
                ...this.props.api
            },include_prompt:true});
            let items:any = [];

            console.log(data);

            const emotions = Object.keys(this.props.api.emotions).map((key:string)=>{
                console.log(key)
                return {
                    id:`${key}`.toLowerCase().replaceAll("_","_"),
                    name:upperFirst((key)).replaceAll("_"," "),
                    label:upperFirst(key).replaceAll("_"," "),
                }
            });
            
            data.res.map((t:string)=>{
                const txt = capitalize(this.remove_number_prefix(t)).replace(".","");
                items.push({
                    id:`${txt}`.toLowerCase().replaceAll("_","-").replaceAll(" ","_"),
                    name:upperFirst(txt).replaceAll("_","-"),
                    label:upperFirst(txt).replaceAll("_","-"),
                });
            });

            if(items.length===1){
                const regex = /^[^,]*,([^,]*,)*[^,]*$/;
                if(regex.test(items[0].name)){
                    const new_array = items[0].name.split(',');
                    items = [];
                    new_array.map((t:string)=>{
                        const txt = capitalize(trim(t)).replace(".","").replace("And ","").replace("and ","").replace("And","").replace("and","");
                        items.push({
                            id:`${txt}`.toLowerCase().replace("_","-"),
                            name:upperFirst(txt).replace("_","-"),
                            label:upperFirst(txt).replace("_","-"),
                        });
                    });
                }
            };

 
            // items = uniqBy(items,'id');
            
            // sort by alphabetical order
            items.sort((a:any,b:any) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
            // console.log(emotions,items);
            items = uniqBy([...items],'id');
            let fixed_item:any[] = [];

            items.map((v:any)=>{
                fixed_item.push({
                    ...v,
                    name:v.id==='self-doubt'?v.name: id_to_text(v.name).replace("-"," "),
                    label:v.id==='self-doubt'?v.name: id_to_text(v.label).replace("-"," "),
                });
            })

            this.props.update_state(fixed_item,data.prompt,data.tools);
            update_db({value:fixed_item},this.props.session_id,'emotions_decon_fetched','verve_demo_session_gpt_response')
            this.setState({emojies:fixed_item,loading:false,error:false})
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };

    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'emotions_decon_fetched','verve_demo_session_gpt_response')
    };


    _next = () => {
        let ordered = [...this.state.selected];
        // this.state.emojies.map((item:any)=>{
        //     if(!ordered.includes(item.id)){
        //         ordered.push(item.id);
        //     };
        // });
        return {
            go:this.state.selected.length>0,
            value:{
                emotional_rating:ordered
            }
        }
    };
    _on_select_emoji = (id:any) => {
                // limit 3
        // if(this.state.selected.length >= 3 && !this.state.se.lected.includes(id)) return;

        // if(this.state.selected.includes(id)){
        //     // setSelected(selected.filter(item => item !== id));
        //     this.setState((state:any) => {
        //         return {
        //             selected:state.selected.filter((item:string) => item !== id)
        //         }
        //     });
        // }else{
        //     // setSelected([...selected,id]);
        //     this.setState((state:any) => {
        //         return {
        //             selected:[...state.selected,id]
        //         }
        //     });
        // }
        this.setState({selected:id})
    };

    // _render_emojies = (item:any) => {
    //     return <EmojiCard {...item} key={item.id} selected={this.state.selected.includes(item.id)} onClick={this._on_select_emoji} />;
    // };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40 font-semibold">
                {
                    this.state.loading?
                    <PromptLoader message={`How you feel matters!\n\nYou are now beginning the phase to understand your perception.`} isNew={false} title='Deconstructing your Emotions' image='https://images.unsplash.com/photo-1579783928621-7a13d66a62d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80'/>
                    :
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_emotions}/>
                    :
                    <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                        <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4">Further Defining How You Feel</span>
                        <div className='flex flex-col'>
                            <span className='font-quick text-title text-[13px] font-medium'>
                                These emotions, which include your original selection, are typically felt by others in this circumstance.
                                <br />
                                <br />
                                Take a fresh look at all emotions and select the ones that best represent how you feel.
                            </span>
                        </div>
                        <div className='flex w-full flex-col mt-[18px]'>
                            <span className='flex font-raleway text-[10px] leading-[12px] text-title font-medium'>Select all that apply.</span>
                            <div className='flex flex-row flex-wrap justify-between pt-[19px]'>
                                {/* {this.state.emojies.map(this._render_emojies)} . . . */}
                    <InlineMultiSelect options={this.state.emojies} defaultValue={cross_check(this.state.emojies,this.props.emotional_rating||[])} selected={[]} onChange={this._on_select_emoji}  show_count={false} />

                            </div>
                        </div>
                    </motion.div>
                }
            </div>
        )
    }
}


const map_state_to_props = (state:RootState) => {
    return {
        emotional_rating:state.app['decon_emotions_rate']?.emotional_rating,

        api:{
            summary:state.response['summary_fetched'].response||state.response['summary_fetched'],
            emotions:state.app['circgen_rate_emotion']
        },
        emotions_decon_fetched:state.response['emotions_decon_fetched']?.response||state.response['emotions_decon_fetched'],
        session_id:state.app.session_seed
    };
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'emotions_decon_fetched',value}));
            dispatch(update_prompt_state({key:'emotions_decon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'emotions_decon_fetched',value:tools}));
         },
    }    
};


export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(EmotionalRatingSelect);