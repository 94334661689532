
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { home_path, login_path,default_path, privacy_info } from '../navigation/path';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { RootState } from '../../db/redux/store';


const onboarding_paths = [login_path];

function RequireAuth({ children,replace }: { children?: JSX.Element|null,replace?:string }):any {
    const {uid:user,has_accepted_terms} = useSelector((state:RootState)=>state.auth);
    let location = useLocation();
    if(user&&!has_accepted_terms&&location.pathname!==privacy_info){
      return <Navigate to={privacy_info} state={{ from: location }}  replace />;
    };
    if(user&&onboarding_paths.includes(location.pathname)){
      return <Navigate to={`${default_path}`} state={{ from: location }}  replace />;
    };
    if (!user&&!onboarding_paths.includes(location.pathname)) {
      return <Navigate to={login_path} state={{ from: location }} replace />;
    };
    if(replace){
      return <Navigate to={`${replace}`} state={{ from: location }}  replace />;
    }
    if(!children){
      return <div/>
    };
    return children;
};


export default RequireAuth;