import React from 'react';
import AssessmentCard from '../../utils/components/assesstmentCard';
import {motion} from 'framer-motion';
import { struggle_intense_external_domains } from '../../../../utils/vision_wheel';
import { RootState } from '../../../../db/redux/store';
import { connect } from 'react-redux';



class ExternalWorldAssessment extends React.PureComponent<any,any>{

    selected: any;
    constructor(props:any) {
        super(props);
        this.selected = {
            ...this.props.item
        }
    };

    _next = () => {
        return {
            go: true,
            value:{
                ...this.selected
            }
        };
    };


    _on_impact_change = (id:string,impact:string) => {
        this.selected[id] = {
            ...this.selected[id],
            impact
        }
    };

    _on_rate_change = (id:string,rate:number) => {
        this.selected[id] = {
            ...this.selected[id],
            rate
        }
    };

    _render_lists = (item:typeof struggle_intense_external_domains[0]) => {
        return <AssessmentCard dynamic defaultImpact={this.props.item[item.id]?.impact} defaultRate={this.props.item[item.id]?.rate} onImpactChange={this._on_impact_change} onRateChange={this._on_rate_change}  key={item.id} {...item}/>
    };

   render(): React.ReactNode {
    return (
        <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-96">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Assessing the Impact (External World)</span>
                <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>Let’s better understand the impact in every area of your external world.</span>
                <motion.div layout className='space-y-2 flex flex-col pt-[22px]'>
                    {struggle_intense_external_domains.map(this._render_lists)}
                </motion.div>
        </div>
    );
   }
};


const map_state_to_props = (state:RootState) => {
    return {
        item:{...state.app['circgen_vision_wheel_external']}
    }
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(ExternalWorldAssessment);