import React, { useState } from 'react';
import MultiSelect from "../../utils/components/multiSelect";
import {motion} from 'framer-motion';
import { Dispatch } from 'redux';
import { RootState } from '../../../../db/redux/store';
import { connect } from 'react-redux';



const situations = [
    {
        id:'it_is_currently_my_reality',
        label:'It is currently my reality'
    },
    {
        id:'it_is_going_to_be_my_reality',
        label:'It is going to be my reality'
    }
];


class Occurrence extends React.PureComponent<any,any>{
    constructor(props:any) {
        super(props);
        this.state = {
            selected:this.props.selected||null
        }
    };

    _next = () => {
        return {
            go: Boolean(this.state.selected),
            value:{
                selected:this.state.selected
            }
        };
    }

    _set_selected = (selected:any) => {
        this.setState({selected},()=>{
            this.props.forceGo();
        });
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col justify-between">
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Is this currently affecting you, or are you anticipating an impact?</span>
                <motion.div layout className="w-full h-full flex justify-evenly flex-col">
                        <MultiSelect selected={this.state.selected} onChange={this._set_selected} options={situations}/>
                        {/* <div className="w-10 h-10 bg-red-400"/> */}
                        <div className='flex h-[70%]'/>
                </motion.div>
                <div className="flex flex-">   
                </div>  
            </div>
        )
    }
};



const map_state_to_props  = (state:RootState) => {
    return {
        ...state.app['circgen_impact']
    }
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(Occurrence);