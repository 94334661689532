function findItemAndParentObject(id:string,options:any) {
    let result = null;
    
    for (const theme of options) {
        const item = theme.items.find((item:any) => item.id === id);
        if (item) {
            result = {
                ...item,
                parent: theme.id
            };
            break; // Stop searching once the item is found
        }
    }
    
    return result;
}


export {
    findItemAndParentObject
}