import React, { useEffect, useRef, useState } from 'react';
import {motion} from 'framer-motion';
import ReactSlider from 'react-slider';
import rt from '../rates';
import relavance from '../relavance';
import commitment from '../commitment';
import helpfulness from '../helpfulness';

const RateText = ({id,onChange, label,selected,no_select,rl_text,onChangeRate,defaultRate,is_rl,is_commitment,renderExtra,extra,boldLabel,no_color,is_helpfulness}:{is_helpfulness?:boolean,no_color?:boolean, boldLabel?:boolean, extra?:any,renderExtra?:any,is_commitment?:boolean, is_rl?:boolean,defaultRate?:number,onChangeRate?:any, rl_text?:string, id:string,selected?:boolean,no_select?:boolean,onChange:(id:string)=>void, label?:string}) => {

    const _ref = useRef<any>(null);

 
    const _on_change = (event: any) => {
        if(event.target.id!=='text'){
            return;
        }
        // _ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        onChange(id);
        if(selected){
            return;
        };
            const containerCover = document.getElementById('container_cover');
            if (containerCover && containerCover.firstElementChild) {
            const parentDiv = containerCover.firstElementChild as HTMLElement;


            let offset = ((_ref.current.offsetTop || 0) - parentDiv.scrollTop) 
            const height = _ref.current.clientHeight + (offset/9);


            offset = offset + height;

            // console.log("h=>", parentDiv.scrollTop + height,"parent=>",parentDiv.clientHeight)
            // console.log(offset, parentDiv.clientHeight);

         
            // console.log(_ref.current.clientHeight)

                
            if (offset < 0 || offset > (parentDiv.clientHeight-50)) {
                    const style = window.getComputedStyle(parentDiv);
                    const paddingBottom = parseInt(style.getPropertyValue('padding-bottom'), 10);
                    _ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    parentDiv.scrollTo({
                      top: (parentDiv.scrollTop) + height+paddingBottom,
                      behavior: 'smooth' // Apply smooth scrolling behavior to the parent div
                    });
      
                };
        
        }
    };


  

    const _on_change_rate = (num:number) => {
        onChangeRate && onChangeRate(id, num);
        // prevent default
        return false;
    };

    return (
        <motion.div ref={_ref} id="overal_cover" layout onClick={_on_change} className={`rounded-2xl cursor-pointer border border-br-gray p-4 flex flex-col ${extra||no_color?'':"bg-white"} relative ${selected?'border-br-green':''} ${!label?'border-none':''}`}>
           {!selected?null:<motion.img id="selected_icon" animate={{scale:1,opacity:1}} initial={{scale:0,opacity:0}} layout className="w-5 h-5 absolute -left-1.5 -top-1.5 rounded-full border border-white bg-white" src={require('../../../../assets/icons/selected_circle.png')}/>}
           {!label?null: <motion.div id="text" layout className={`font-quick text-title text-[13px] leading-[18px] ${boldLabel?'font-bold':'font-medium'} whitespace-pre-line`} dangerouslySetInnerHTML={{__html:label.replace(/\*\*(.*?)\*\*/g, '<span class="font_bold">$1</span>')}}></motion.div>}
           {renderExtra&&extra?renderExtra(id,extra):null}
           {!selected&&!no_select?null:
           <motion.div  animate={{opacity:1}} initial={{opacity:0}} className="mt-2.5 flex w-full flex-col">
                    <span className=' font-raleway text-sub-text text-[10px] leading-[12px] font-medium'>{rl_text||'Rate the relevance:'}</span>
                    <Rate is_commitment={is_commitment} is_helpfulness={is_helpfulness} is_rl={is_rl} defaultRate={defaultRate} onChangeRate={_on_change_rate}/>
            </motion.div>}
        </motion.div>
    );
};


const Rate = React.memo(({onChangeRate,defaultRate,is_rl,is_commitment,is_helpfulness}:any) =>{
    const [rating,setRating] = useState(defaultRate||1);

    const rates = is_helpfulness? helpfulness: is_commitment?commitment:is_rl?relavance:rt;

    const _on_change = (num:number) => {
        const val = Math.ceil(num / 10)
        const value =  val||1;
        if(value!==rating){
            onChangeRate&&onChangeRate(value)
            setRating(value);
        };
        return false;
    };


    const pr = (ev:any)=>{
        ev.stopPropagation();
        ev.preventDefault();
    }

    return (
        <motion.div onClick={pr} id="rate_container"  animate={{opacity:1,transition:{delay:.2}}} initial={{opacity:0}}  className={`flex flex-col w-full px-4`}>

            <motion.div layout className='w-full flex justify-center items-center mt-2'>
                    <motion.div layout className={`rounded-full px-2.5 p-2 flex justify-center items-center`} style={{background:rates[rating-1].color.bg}}>
                        <motion.span layout style={{color:rates[rating-1].color.text}} className='font-raleway text-[10px] leading-[12px] flex font-normal'>{rating}: {rates[rating-1].label}</motion.span>
                    </motion.div>
            </motion.div>

            <div onClick={pr} className='pt-2.5 w-full self-center rate_slider'>
                <ReactSlider  defaultValue={(defaultRate||1)*10} onSliderClick={_on_change} onChange={_on_change} min={1} max={100} className={is_commitment?"slider_inverted_green_active":"slider_inverted_active"} trackClassName={is_commitment?"slider_inverted_green":"slider_inverted"} renderThumb={(props:any, state:any) => <div {...props} className="w-4 h-4 rounded-full track_shadow outline-none ring-0 -top-[7px] border-[3px] border-br bg-btn ovr_rnd"/> } />
                <div className='w-full flex flex-row justify-between items-center pt-3'>
                        <span className='text-[12px] leading-[15px] font-quick font-normal text-text'>1</span>
                        <span className='text-[12px] leading-[15px] font-quick font-normal text-text'>10</span>
                </div>
            </div>

        </motion.div>
    )  
});


export default RateText;