import React from 'react';
import ListReviewer from '../../../utils/components/listReviewer';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';



const items = [
    {
        id:'emotion_intensity',
        titles:['Thoughts','Intensity'],
        options:[
            {
                id:'burdended',
                title:'I don’t want to listen to anyone here – they don’t have my best interests at heart.',
                intensity:7
            },
            {
                id:'crazy',
                title:'No-one is being open to me, so I’m going to shut down.',
                intensity:5
            },
            {
                id:'against_me',
                title:'They are against me, so I have to be guarded and put a wall up.',
                intensity:5   
            }
        ]
    },
    {
        id:'other_emotions',
        titles:['Other thoughts'],
        options:[
            {
                id:'flustered',
                title:'Things are not going well for me right now',
            }
        ]
    }
]

class ThoughtRatingReview extends React.PureComponent<any,any>{
    items: any[];

    constructor(props:any) {

        super(props);
        let options:any[] = [];
        Object.keys(this.props.items).map((key:any) => {
            options.push({
                id:key,
                title:`${capitalizeFirstLetter(id_to_text(key))}.`,
                intensity:this.props.items[key],
                mainFocus:this.props.top_thought === key
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
            if(a.mainFocus && !b.mainFocus){
                return -1;
            }else if(!a.mainFocus && b.mainFocus){
                return 1;
            }else{
                return b.intensity - a.intensity;
            }   
        });

        this.items = [
            {
                id:'thought_intensity',
                titles:['Thoughts','Intensity'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">What You Think</span>
                <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Our thoughts help us appreciate what we feel and deepen our understanding of our perception.
                <br />
                <br />
                Take some time to appreciate what you think and feel in this circumstance.
                </span>
                <ListReviewer items={this.items}/>
            </div>
        )
    }
}

const map_state_to_props = (state:RootState) => {
    return {    
        top_thought:state.app['decon_thoughts_relevance_rating']?.selected,
        items:{
            ...state.app["decon_thoughts_relevance"],
        },
    }
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(ThoughtRatingReview);