import React from 'react';
import {motion} from 'framer-motion';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import MultiSelect from '../../../utils/components/multiSelect';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';

class ActionRelavanceFocus extends React.PureComponent<any,any>{
    options: any[];
    constructor(props:any){
        super(props);
        this.options = [];
        Object.keys(this.props.items).map((key:string)=>{
            this.options.push({
                id:key,
                label:`${capitalizeFirstLetter(id_to_text(key))}.`
            });
        });


        const df = this.props.selected||null;
        
        this.state = {
            selected:df
        };
    };

    _next = () =>{
        return {
            go:this.state.selected!==null,
            value:{
                selected:this.state.selected
            }
        } 
    };

    _on_select = (id:string) => {
        this.setState({
            selected:id
        });
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col justify-between">
            <span className="font-quick text-text text-[13px] leading-[16px] font-medium">Please select 1 action you would like to focus on.</span>
                <motion.div layout className="w-full h-full flex justify-evenly flex-col">
                    <motion.div layout className="w-full h-full flex justify-evenly flex-col">
                            <MultiSelect no_padding selected={this.state.selected} onChange={this._on_select} options={this.options}/>
                            <div />
                    </motion.div>
                </motion.div>
            </div>
        )
    }
};




const map_state_to_props = (state:RootState) => {
    return {
        items:{
            ...state.app["decon_action_relevance"],
        },
        selected:state.app['decon_action_relevance_focus']?.selected
    }
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(ActionRelavanceFocus);