import React from 'react';
import ListReviewer from '../../../utils/components/listReviewer';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
// import capitalize from 'lodash/capitalize';
import id_to_text, { capitalizeFirstLetter } from '../../../utils/text/id_to_text';



class ActionRatingReview extends React.PureComponent<any,any>{
    items: any[];

    constructor(props:any) {

        super(props);
        let options:any[] = [];
        Object.keys(this.props.items).map((key:any) => {
            options.push({
                id:key,
                title:`${capitalizeFirstLetter(id_to_text(key))}.`,
                intensity:this.props.items[key],
                mainFocus:this.props.top_action === key
            })
        });
        // sort by main focus and then by intensity
        options.sort((a:any,b:any) => {
            if(a.mainFocus && !b.mainFocus){
                return -1;
            }else if(!a.mainFocus && b.mainFocus){
                return 1;
            }else{
                return b.intensity - a.intensity;
            }   
        });

        this.items = [
            {
                id:'action_intensity',
                titles:['Action','Intensity'],
                options:options
            }
        ];
    };

    _next = () => {
        return {
            go: true,
            value:{
                
            }
        };
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex font-semibold mb-4">How You Act</span>
                <span className="flex flex-col font-quick text-text text-[13px] leading-[16px] font-medium">Actions are about how we relate to ourselves and others and include:
                <ol className='list-decimal pl-5 mt-2'>
                    <li>What we say and how we say it; and</li>
                    <li>What we do and how we do it</li>
                </ol>
                <br />
                How we act can, in most instances, influence what we attract. In other words, there is an impact and a reaction to our actions.
                <br />
                <br />
                Take some time to comprehend the role your actions play in attracting what you don’t want.
                </span>
                <ListReviewer items={this.items}/>
            </div>
        )
    }
};


const map_state_to_props = (state:RootState) => {
    return {    
        top_action:state.app['decon_action_relevance_focus']?.selected,
        items:{
            ...state.app["decon_action_relevance"],
        },
    }
};



export default connect(map_state_to_props,null,null,{forwardRef:true})(ActionRatingReview);