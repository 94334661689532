import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState:{uid:null|string,has_accepted_terms:boolean} ={
  uid:null,
  has_accepted_terms:false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
      on_user_login:(state,action:PayloadAction<{uid:string,has_accepted_terms:boolean}>)=>{
          state.uid = action.payload.uid;
          state.has_accepted_terms = action.payload.has_accepted_terms;
      },
      on_user_logout:(state)=>{
          state.uid = null;
          state.has_accepted_terms = false;
      }
  },
});

// Action creators are generated for each case reducer function
export const {  on_user_login, on_user_logout } = authSlice.actions;

export default authSlice.reducer;


