
// Pessimistic

export default(emoji: string,sm?:boolean) => {
    switch(emoji) {
        case 'reckless':
            return require('../../../../assets/emoji/reckless.png');
        case 'inattentive':
            return require('../../../../assets/emoji/inattentive.png');
        case 'overconfident':
            return require('../../../../assets/emoji/overconfident.png');
        case 'fear':
            return require('../../../../assets/emoji/fear.png');
            // 
        case 'numb':
            return require('../../../../assets/emoji/numb.png');
        case 'anxious':
            return require('../../../../assets/emoji/anxious.png');
        case 'frustrated':
            return require('../../../../assets/emoji/frustrated.png');
        case 'frustrated_at_myself':
            return require('../../../../assets/emoji/frustrated.png');
        case 'frustrated_at_others':
            return require('../../../../assets/emoji/frustrated.png');
        case 'angry':
            return require('../../../../assets/emoji/angry.png');
        case 'angry_at_myself':
            return require('../../../../assets/emoji/angry.png');
        case 'angry_at_others':
            return require('../../../../assets/emoji/angry.png');
        case 'repulsed':
            return require('../../../../assets/emoji/repulsed.png');
        // case 'betrayed':
        //     return require('../../../../assets/emoji/betrayed.png');
        case 'resentful':
            return require('../../../../assets/emoji/resentful.png');
        case 'envious':
            return require('../../../../assets/emoji/envious.png');
        case 'disrespect':
            return require('../../../../assets/emoji/disrespected.png');
        case 'overwhelmed':
            return require('../../../../assets/emoji/overwhelmed.png');
        case 'exhausted':
            return require('../../../../assets/emoji/exhausted.png');
        case 'regret':
            return require('../../../../assets/emoji/regret.png');
        case 'sad':
            return require('../../../../assets/emoji/sad.png');
        // case 'loss':
        //     return require('../../../../assets/emoji/loss.png');
        case 'stuck':
            return require('../../../../assets/emoji/stuck.png');
        case 'powerless':
            return require('../../../../assets/emoji/stuck.png');
        // case 'dissatisfied':
        //     return require('../../../../assets/emoji/dissatisfied.png');
        case 'unworthy':
            return require('../../../../assets/emoji/unworthy.png');
        case 'inferior':
            return require('../../../../assets/emoji/unworthy.png');
        case 'incapable':
            return require('../../../../assets/emoji/incapable.png');
        // case 'self_doubt':
        //     return require('../../../../assets/emoji/self_doubt.png');
        // case 'embarrassed':
            // return require('../../../../assets/emoji/embarrassed.png');
        case 'misunderstood':
            return require('../../../../assets/emoji/misunderstood.png');
        // case 'unmotivated':
            // return require('../../../../assets/emoji/unmotivated.png');
        // case 'distrust':
            // return require('../../../../assets/emoji/distrust.png');
        case 'pessimistic':
            return require('../../../../assets/emoji/pessimistic.png');
        case 'cynical':
            return require('../../../../assets/emoji/pessimistic.png');
            // abandoned.png
        case 'worried':
            return require('../../../../assets/emoji/worried.png');
        case 'victimised':
            return require('../../../../assets/emoji/victimised.png');
        case 'vulnerable':
            return require('../../../../assets/emoji/vulnerable.png');
        case 'resistant':
            return require('../../../../assets/emoji/resistant.png');
        case 'shame':
            return require('../../../../assets/emoji/shame.png');
        case 'underwhelmed':
            return require('../../../../assets/emoji/underwhelmed.png');
        case 'vengeful':
            return require('../../../../assets/emoji/vengeful.png');
        case 'misunderstood':
            return require('../../../../assets/emoji/misunderstood.png');
        case 'pressured':
            return require('../../../../assets/emoji/pressured.png');
        case 'rejected':
            return require('../../../../assets/emoji/rejected.png');
        case 'lonely':
            return require('../../../../assets/emoji/rejected.png');
        case 'grief':
            return require('../../../../assets/emoji/grief.png');
        case 'guilt':
            return require('../../../../assets/emoji/guilt.png');
        case 'inadequate':
            return require('../../../../assets/emoji/inadequate.png');
        case 'jealous':
            return require('../../../../assets/emoji/jealous.png');
        case 'distant':
            return require('../../../../assets/emoji/distant.png');
        case 'exploited':
            return require('../../../../assets/emoji/exploited.png');
        // case 'disappointed':
        //     return require('../../../../assets/emoji/disappointed.png');
        case 'dismissive':
            return require('../../../../assets/emoji/dismissive.png');
        case 'dismissive_of_myself':
            return require('../../../../assets/emoji/dismissive.png');
        case 'dismissive_of_others':
            return require('../../../../assets/emoji/dismissive.png');
        case 'depressed':
            return require('../../../../assets/emoji/depressed.png');
        case 'confused':
            return require('../../../../assets/emoji/confused.png');
        case 'bored':
            return require('../../../../assets/emoji/bored.png');
        case 'awkward':
            return require('../../../../assets/emoji/awkward.png');
        case 'abandoned':
            return require('../../../../assets/emoji/abandoned.png');
        

        case 'burdened':
            return require('../../../../assets/emoji/burdened.png');
        case 'crazy':
            return require('../../../../assets/emoji/crazy.png');
        case 'desperate':
            return require('../../../../assets/emoji/desperate.png');
        case 'disappointed':
            return require('../../../../assets/emoji/disappointed.png');
        case 'disappointed_in_myself':
            return require('../../../../assets/emoji/disappointed.png');
        case 'disappointed_in_others':
            return require('../../../../assets/emoji/disappointed.png');
        case 'distressed':
            return require('../../../../assets/emoji/distressed.png');
        case 'disturbed':
            return require('../../../../assets/emoji/disturbed.png');
        case 'flustered':
            return require('../../../../assets/emoji/flustered.png');
        case 'scatterd':
            return require('../../../../assets/emoji/scatterd.png');
        case 'arrogant':
            return require('../../../../assets/emoji/arrogant.png');
        case 'lacking':
            return require('../../../../assets/emoji/lacking.png');
        case 'mischievous':
            return require('../../../../assets/emoji/mischievous.png');
        case 'pitty':
            return require('../../../../assets/emoji/pitty.png');
        case 'misunderstood':
            return require('../../../../assets/emoji/misunderstood.png');
        case 'disgusted':
            return require('../../../../assets/emoji/disgusted.png');
        case 'dishonest':
            return require('../../../../assets/emoji/dishonest.png');
        case 'awkward':
            return require('../../../../assets/emoji/awkward.png');
        case 'crazy':
            return require('../../../../assets/emoji/crazy.png');
        case 'regret':
            return require('../../../../assets/emoji/regret.png');
        case 'pressured':
            return require('../../../../assets/emoji/pressured.png');
        case 'judjemental':
            return require('../../../../assets/emoji/judjemental.png');
        case 'vengeful':
            return require('../../../../assets/emoji/vengeful.png');
        case 'hurt':
            return require('../../../../assets/emoji/hurt.png');
        case 'frantic':
            return require('../../../../assets/emoji/frantic.png');
        case 'restless':
            return require('../../../../assets/emoji/restless.png');
        case 'pessimistic':
            return require('../../../../assets/emoji/pessimistic.png');
        case 'bemused':
            return require('../../../../assets/emoji/bemused.png');
        case 'disrespected':
            return require('../../../../assets/emoji/disrespected.png');
        case 'distant':
            return require('../../../../assets/emoji/distant.png');
        case 'jealous':
            return require('../../../../assets/emoji/jealous.png');
        default:
            return sm? require('../../../../assets/emoji/smiley.png') : require('../../../../assets/emoji/burdened.png');
    };
};