import React, { useState } from 'react';
import { motion } from 'framer-motion';
import DropDown from '../../utils/components/dropDown';
import InlineMultiSelect from '../../utils/components/inlineMultiSelect';
import { connect } from 'react-redux';
import { RootState } from '../../../../db/redux/store';
// @ts-ignore
import Shimmer from "react-shimmer-effect";
import uniqBy from 'lodash/uniqBy'
import { get_response } from '../../../../utils/request/get';
import { Dispatch } from 'redux';
import { remove_response_state, update_response_state } from '../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../common/componenets/prompt_loader';
import PromptError from '../../../../common/componenets/prompt_error';
import uniq from 'lodash/uniq';
import { update_db } from '../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../db/redux/features/tools/tools.slice';



const express_themes = [
    {
        id:'personal',
        label:'Personal',
        items:[
            {
                label:'Undertaking a Challenging Task',
                id:'undertaking_a_challenging_task',
                _key:'undertaking_a_challenging_task',
                parent:'personal'
            },
            {
                label:'Making a Difficult Decision',
                id:'making_a_difficult_decision',
                _key:'making_a_difficult_decision',
                parent:'personal'
            },
            {
                label:'Having a Difficult Conversation',
                id:'having_a_difficult_conversation',
                _key:'having_a_difficult_conversation',
                parent:'personal'
            },
            {
                label:'Going on a Date',
                id:'going_on_a_date',
                _key:'going_on_a_date',
                parent:'personal'
            },
            {
                label:'Breaking-up a Romantic Relationship',
                id:'breaking_up_a_romantic_relationship',
                _key:'breaking_up_a_romantic_relationship',
                parent:'personal'
            },
            {
                label:'Breaking-up a Friendship',
                id:'breaking_up_a_friendship',
                _key:'breaking_up_a_friendship',
                parent:'personal'
            },
            {
                label:'Taking an Exam',
                id:'taking_an_exam',
                _key:'taking_an_exam',
                parent:'personal'
            },
            {
                label:'Starting a Fitness Program',
                id:'starting_a_fitness_program',
                _key:'starting_a_fitness_program',
                parent:'personal'
            },
            {
                label:'Attending a Social Gathering',
                id:'attending_a_social_gathering',
                _key:'attending_a_social_gathering',
                parent:'personal'
            },
            {
                label:'Auditioning for a Role',
                id:'auditioning_for_a_role',
                _key:'auditioning_for_a_role',
                parent:'personal'
            },
            {
                label:'Performing on Stage',
                id:'performing_on_stage',
                _key:'performing_on_stage',
                parent:'personal'
            },
            // {
            //     label:'About to Lose a Loved-one',
            //     id:'about_to_lose_a_loved_one',
            //     _key:'about_to_lose_a_loved_one',
            //     parent:'personal'
            // }
        ]
    },
    {
        id:'professional',
        label:'Professional',
        items:[
            {
                label:'Being Interviewed for a Job',
                id:'being_interviewed_for_a_job',
                _key:'being_interviewed_for_a_job',
                parent:'professional'
            },
            {
                label:'Interviewing a Job Candidate',
                id:'interviewing_a_job_candidate',
                _key:'interviewing_a_job_candidate',
                parent:'professional'
            },
            {
                label:'Meeting with Management',
                id:'meeting_with_management',
                _key:'meeting_with_management',
                parent:'professional'
            },
            {
                label:'Meeting with Staff',
                id:'meeting_with_staff',
                _key:'meeting_with_staff',
                parent:'professional'
            },
            {
                label:'Meeting with Client',
                id:'meeting_with_client',
                _key:'meeting_with_client',
                parent:'professional'
            },
            {
                label:'Making a Difficult Decision',
                id:'making_a_difficult_decision',
                _key:'making_a_difficult_decision',
                parent:'professional'
            },
            {
                label:'Having a Difficult Conversation',
                id:'having_a_difficult_conversation',
                _key:'having_a_difficult_conversation',
                parent:'professional'
            },
            {
                label:'Undertaking a Challenging Task',
                id:'undertaking_a_challenging_task',
                _key:'undertaking_a_challenging_task',
                parent:'professional'
            },
            {
                label:'Giving a Presentation',
                id:'giving_a_presentation',
                _key:'giving_a_presentation',
                parent:'professional'
            },
            {
                label:'Presenting a Sales Pitch',
                id:'presenting_a_sales_pitch',
                _key:'presenting_a_sales_pitch',
                parent:'professional'
            },
            {
                label:'Attending a Networking Event',
                id:'attending_a_networking_event',
                _key:'attending_a_networking_event',
                parent:'professional'
            },
            {
                label:'Engaging in Public Speaking',
                id:'engaging_in_public_speaking',
                _key:'engaging_in_public_speaking',
                parent:'professional'
            }
        ]
    }
]


const default_items = [
    // {id:'other',label:'Other',_key:'other'},
    {id:'obstacle',label:'Obstacle',_key:'obstacle'},
    // {id:'broken_agreement',label:'Broken agreement',_key:'broken_agreement'},
    // {id:'lazy',label:'Lazy',_key:'lazy'},
    // {id:'no_support',label:'No Support',_key:'no_support'},
    // {id:'change',label:'Change',_key:'change'},
    // {id:'experience',label:'Experience',_key:'experience'},
    // {id:'pain_point',label:'Pain Point',_key:'pain_point'},
    // {id:'over_sensitivity',label:'Over Sensitivity',_key:'over_sensitivity'},
    // {id:'fear_of_change',label:'Fear of Change',_key:'fear_of_change'},
    // // {id:'intentionally_given',label:'Intentionally Given',_key:'intentionally_given'},
    // // {id:'intentionally_received',label:'Intentionally Received',_key:'intentionally_received'},
    // // {id:'perceived_received',label:'Perceived Received',_key:'perceived_received'},
    // {id:'grown_apart',label:'Grown Apart',_key:'grown_apart'},
    // {id:'resistance',label:'Resistance',_key:'resistance'},

]

// "subthemes": "Living arrangements",
// "circtype": "new_obstacle_encountered_with_an_existing_s-circ",
// "domain": "relationships",
// "subdomain": "partner",
// "dominant_theme": "low_energy",
// "id": "living_arrangements"
// const get_sub_themes = (theme:string,domain:string,subdomain:string,circumstance:string) => {
//     const subthemes = require('../../../../utils/themes/subthemes.json');
//     const filtered = subthemes.filter((subtheme:any) => (subtheme.domain===domain&&subtheme.subdomain===subdomain&&subtheme.circtype===circumstance&&subtheme.dominant_theme===theme)||(!subtheme.domain&&!subtheme.subdomain&&!subtheme.circtype&&!subtheme.dominant_theme));
//     let to_return:any[] = [];
//     filtered.map((subtheme:any,index:number) => {
//         to_return.push({
//             id:subtheme.id,
//             _key:subtheme.id+`${index}`,
//             label:subtheme.subthemes
//         })
//     });
//     to_return = uniqBy(to_return,'id');
//     return to_return;
// };

// const get_themes = (domain:string,subdomain:string,circumstance:string) => {
//     const themes = require('../../../../utils/themes/themes.json');

//     const filtered = themes.filter((theme:any) => (theme.domain===domain&&theme.subdomain===subdomain)||(!theme.domain&&!theme.subdomain));
//     let to_return:any[] = [];
//     filtered.map((theme:any,index:number) => {
//         if(!theme.id){
//             return;
//         }
//         to_return.push({
//             id:theme.id,
//             _key:theme.id+`${index}`,
//             label:theme.dominant_themes
//         })
//     });
//     to_return = uniqBy(to_return,'id');
//     return [...to_return,{id:'resistance',label:'Resistance',_key:'resistance'}];
// };


function findItemAndParentObject(id:string) {
    let result = null;
    
    for (const theme of express_themes) {
        const item = theme.items.find((item:any) => item.id === id);
        if (item) {
            result = {
                ...item,
                // parent: theme.id
            };
            break; // Stop searching once the item is found
        }
    }
    
    return result;
}

class Theme extends React.PureComponent<any,any>{
    // themes: any[];
    is_express: boolean;

    default_theme: any;
    constructor(props:any) {
        super(props);
        this.is_express = Boolean(this.props.circ==='prepare_myself')

        // this.themes = get_themes(this.props.domain,this.props.subdomain,this.props.circumstance);
        const themes = this.props.dominant_theme_fetched||[];
        console.log(this.props);
        this.default_theme = this.props.theme||null
        if(this.props.theme&&(this.props.dominant_theme_fetched||this.is_express)){
            this.default_theme = this.props.theme;// this.is_express?findItemAndParentObject(this.props.theme):themes.find((theme:any) => theme.id === this.props.theme);
        };
        const th = this.props.theme;
        const sth = this.props.subtheme;

        

        this.state = {
            loading:this.is_express?false:this.props.dominant_theme_fetched&&this.props.dominant_theme_fetched.length>0?false:true,
            error:false,
            items:this.is_express?express_themes:this.props.dominant_theme_fetched||[],
            status_code:0,
            theme: th|| null,
            subtheme:sth|| null,
        };


  
    };

    componentDidMount(): void {
        if(!this.is_express){
            if(!this.props.dominant_theme_fetched||(this.props.dominant_theme_fetched&&this.props.dominant_theme_fetched.length===0)){
                this.get_themes();
            };
        }

    }
       
    get_themes = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'dominant_theme',values:{
                domain:this.props.domain,
                sub_domain:this.props.subdomain,
            },include_prompt:true})

            let items:any =[];
            data.res.map((t:string,i:number)=>{
                const id = `${t}`.replace(/\s/g,'_');
                items.push({
                    id,
                    _key:id+`${i}`,
                    label:t
                })
            });
            items = [...items,...default_items]

            update_db({value:items},this.props.session_id,'dominant_theme_gpt','verve_demo_session_gpt_response')

            this.props.update_state(items,data.prompt,data.tools||[]);
            this.setState({items:items,loading:false,error:false})
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };

    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'dominant_theme_gpt','verve_demo_session_gpt_response')
    };



    _next = () =>{
        if(!this.state.theme||!this.state.subtheme){
            return {
                go:false,
                value:null
            };
        };
        return {
            go:Boolean(this.state.subtheme&&this.state.theme),
            value:{
                ...this.state
            }
        };
    };


    _on_change = (key:string,value:any) => {
        if(key==='theme'){
            this.setState({
                theme:value,
                subtheme:null
            });
            this.props.clear_state();
            this.default_theme = value;
            // this.is_express?findItemAndParentObject(value):this.state.items.find((theme:any) => theme.id === value);
        }else{
            this.setState({
                subtheme:value
            });
        }
    };

    _clear = () => {
        this.setState({
            theme:null,
            subtheme:null
        });
        this.props.clear_state();
    };

    render(): React.ReactNode {
        return (
            <motion.div layout className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40">
                {
                    this.state.loading?
                    <PromptLoader message='Loading your Themes.'/>:
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this.get_themes} />:

                    <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                        <ImportantTheme isExpress={this.is_express} onChange={this._on_change} defaultValue={!this.state.theme?null:this.default_theme} options={this.state.items}/>
                        {this.state.theme?<SubThemes key={this.state.theme.id} isExpress={this.is_express} onChange={this._on_change} defaultValue={this.props.subtheme} theme={this.state.theme} domain={this.props.domain} circumstance={this.props.circumstance} subdomain={this.props.subdomain}  />:null}
                        <motion.div layout className="flex mt-10">   
                            {!this.state.theme?null:<span onClick={this._clear} className="flex  text-danger font-raleway text-xs cursor-pointer">Clear selection</span>}
                        </motion.div> 
                    </motion.div>
                }
             
            </motion.div>
        )
    }
};


const map_state_to_props = (state:RootState) => {
    return {
        domain:state.app['circgen_vision_wheel']?.struggle_intense,
        subdomain:state.app['circgen_vision_wheel']?.focus,
        circumstance:state.app['circgen_type']?.selected,
        theme:state.app['circgen_theme']?.theme,
        subtheme:state.app['circgen_theme']?.subtheme,

        // 
        dominant_theme_fetched:state.response['dominant_theme_gpt']?.response||state.response['dominant_theme_gpt'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    };  
};

const map_dispatch = (dispatch:Dispatch) => {
    return {
         clear_state:()=>{
            // dispatch(remove_response_state('dominant_theme_gpt'))
            dispatch(remove_response_state('subthemes_gpt'))
         },
         update_state:(value:any,prompt:string)=>{
            dispatch(update_response_state({key:'dominant_theme_gpt',value}))
            dispatch(update_prompt_state({key:'dominant_theme_gpt',value:prompt}))
         },
    }    
};


export default connect(map_state_to_props,map_dispatch,null,{forwardRef:true})(Theme);



const cross_check_subtheme = (themes:any,defaultValue:any) =>{
    // check if the array themes includes the item from defaultValue and return a array where defaultValue item also presented in themes
    const to_return:any[] = [];
    defaultValue.map((item:any) => {
        if(themes.find((theme:any) => theme.id === item)){
            to_return.push(item);
        }
    });
    return to_return;
};


class _subthemes extends React.PureComponent<any,any>{

    constructor(props:any) {
        super(props);
        this.state = {
            loading:!this.props.subthemes_fetched?true:false,
            error:false,
            subthemes:this.props.subthemes_fetched||[],
            status_code:0
        };
    };

    // componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    //     console.log(nextProps, this.props)
    //     if(this.props.subthemes_fetched&&!nextProps.subthemes_fetched){
    //             this.get_sub_themes();
    //     }
    // }

    componentDidMount(): void {
        if(!this.props.subthemes_fetched||this.props.subthemes_fetched.length===0){
            this.get_sub_themes();
        };
    }
 

    get_sub_themes = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'sub_themes',values:{
                theme:this.props.theme.label,
                domain:this.props.domain,
                sub_domain:this.props.subdomain,
                selection_parent:this.props.isExpress?findItemAndParentObject(this.props.theme.id)?.parent:''
            },include_prompt:true,isExpress:this.props.isExpress})
            // id:subtheme.id,
            // _key:subtheme.id+`${index}`,
            // label:subtheme.subthemes
            let to_return:any[] =[];
            const uniqData = uniq<string>(data.res);
            uniqData.map((t:string,i:number)=>{
                const id = `${t}`.replace(/\s/g,'_');
                to_return.push({
                    id,
                    _key:id+`${i}`,
                    label:t
                })
            });
            console.log(data);
            this.props.update_state(to_return,data.prompt,data.tools||[]);
            update_db({value:to_return},this.props.session_id,'subthemes_gpt','verve_demo_session_gpt_response')
            this.setState({subthemes:[...to_return],loading:false,error:false});
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };


    on_change = (id:any) => {
        this.props.onChange('subtheme',id)  
    };

    render(): React.ReactNode {
    // const subthemes = get_sub_themes(this.props.theme,this.props.domain,this.props.subdomain,this.props.circumstance);
        return (
            <motion.div layout animate={{opacity:1}} exit={{opacity:0,y:100}}   className="flex flex-col border-b pb-2 border-dashed border-br-cream pt-4 w-full">
               {this.state.error?null:<span className="font-quick text-text text-[13px] leading-[16px] mb-1.5 font-medium">{this.props.isExpress?'Please choose several sub-themes that relate to what you want to prepare for.':'Please choose several sub-themes that describe your circumstance (a range of 3 to 6 is recommended).'}</span>}
                {
                    this.state.loading?
                    <motion.div animate={{opacity:1}} initial={{opacity:0}} layout className='flex flex-row flex-wrap pt-3'>
                         <Shimmer>
                            <div key={'1'} className={`flex flex-row items-center border w-32 h-8 bg-gray px-7 py-[7.5px]pl-7 border-br-light-cream rounded-full mr-1.5 mb-1.5`} />
                            <div key={'2'} className={`flex flex-row items-center border w-36 h-8 bg-gray px-7 py-[7.5px]pl-7 border-br-light-cream rounded-full mr-1.5 mb-1.5`} />
                            <div key={'4'} className={`flex flex-row items-center border w-20 h-8 bg-gray px-7 py-[7.5px]pl-7 border-br-light-cream rounded-full mr-1.5 mb-1.5`} />
                            <div key={'5'} className={`flex flex-row items-center border w-24 h-8 bg-gray px-7 py-[7.5px]pl-7 border-br-light-cream rounded-full mr-1.5 mb-1.5`} />
                        </Shimmer>
                    </motion.div>
                    :
                    this.state.error?
                        <motion.div animate={{opacity:1}} initial={{opacity:0}} layout className='flex w-full h-full justify-center items-center flex-col text-center my-10'>
                                <span className='text-title font-quick text-[13px] '>Something went wrong while fetching sub-themes, Please try again.</span>
                                <span onClick={this.get_sub_themes} className='text-danger font-bold font-quick text-[13px] mt-1'>Try again</span>
                        </motion.div>
                    :
                    <InlineMultiSelect options={this.state.subthemes} defaultValue={cross_check_subtheme(this.state.subthemes,this.props.defaultValue||[])} selected={[]} onChange={this.on_change}  show_count count_prefix='sub-themes selected'/>
                }
            </motion.div>
        )
    }
}

const map_theme_props = (state:RootState) => {
    return {
        subthemes_fetched:state.response['subthemes_gpt']?.response||state.response['subthemes_gpt'],
        session_id:state.app.session_seed
    };
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'subthemes_gpt',value}));
            dispatch(update_prompt_state({key:'subthemes_gpt',value:prompt}));
            dispatch(update_tools_state({key:'subthemes_gpt',value:tools}));
         },
    }    
};

const SubThemes = connect(map_theme_props,map_dispatch_to_props,null,{forwardRef:true})(_subthemes);




const ImportantTheme = ({options,onChange,defaultValue,isExpress}:{isExpress?:boolean, defaultValue:any,onChange:(key:string,value:string)=>void,options:any}) => {


    const on_change = (obj:any) => {
        onChange('theme',obj)  
    };

    return (
        <div className="flex flex-col border-b pb-4 border-dashed border-br-cream pt-4 w-full">
            <span className="font-quick text-text text-[13px] leading-[16px] mb-5 font-medium">
             {isExpress?
                'Select the main theme that best defines what you want to prepare for.':
                <>
                    Please identify all themes that will help define your circumstance, beginning with the main theme.
                    <br />
                    <br />
                    After you have selected your main theme, you will be selecting many sub-themes to help you more accurately define your circumstance.
                </>}
            </span>
            <div className='px-10'>
                <DropDown classNameExtra='scrollfix' newVersion withTitle={isExpress} emptyPlaceholder={'Select a theme'}  defaultValue={defaultValue} onValueChange={on_change} options={options}/>
            </div>
            <span className='w-full mt-2 font-quick text-text text-[12px] leading-[15px] font-normal'>As AI can sometimes struggle, please be mindful of occasional slower content responses and formatting glitches. These are rare and minor, but please report them if they persist for you.</span>

        </div>
    )
};
