import React from 'react';
import RateText from '../../../utils/components/rate_text';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import {m} from 'framer-motion';

class CommitToChange extends React.PureComponent<any,any>{
    is_express: boolean;

    constructor(props:any){
        super(props);
        this.state = {
            value:this.props.ratings,
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };

    _on_change = (id:string, value:number) => {
        this.setState({value});
    }

    _next = () => {
        return {
            go: true,
            value:{
                commitment:this.state.value
            }
        };
    }

    render(): React.ReactNode {
        return (
            <m.div layout className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">{this.is_express?'How prepared are you?':'How prepared and committed are you to being this way?'}</span>
                <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">{this.is_express?'Rate how prepared you now believe you will be for this circumstance.':'Rate your level of commitment to being this new way for this circumstance.'}</span>
                <RateText no_color defaultRate={this.props.ratings} is_commitment no_select rl_text="Rate your commitment:" id="text" onChange={()=>{}} onChangeRate={this._on_change}/>

                {
                    this.state.value>9&&this.is_express?
                    <div className=''>
                       <span className=" flex-col font-quick text-bg font-medium text-[13px] leading-[16px] flex mt-4">
                              If the score is below 10, you can repeat the process focussing on what else is concerning you, including what disempowering emotions you are yet to address.
                       </span>
                    </div>
                    :null
                }
               {this.state.value>9?
             null
               :<m.span layout className=" flex-col font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4">
                {this.is_express?
                     'If the score is below 10, you can repeat the process focussing on what else is concerning you, including what disempowering emotions you are yet to address.'
                :
                <>
                Any score below 10 indicates normal resistance that should be respected and understood.
                <br />
                <br />
                You can address resistance by generating a new circumstance.
                <br />
                <br />
                To understand the reasons why you are not ready, choose the same areas of struggle you chose for this circumstance. Then, choose “Resistance” as your main theme, and sub-themes related to perfectionism, fear of making mistakes, confrontation, conflict, and failure.

                {/* <ol className='list-decimal ml-6'>
                    <li>Selecting “Next”</li>
                    <li>Selecting “New circumstance”</li>
                </ol>
                <br /> */}
                {/* Your new circumstance will focus on the underlying reasons as to why you are not ready. */}
                <br />
                <br />
                After you have assessed the impact, scroll to the bottom of the emoji list, and choose any 3 of the following emotions:
                <br />
                <ol className='list-disc ml-5 mt-1'>
                        <li>Self-doubt</li>
                        <li>Resistant</li>
                        <li>Hesitant</li>
                        <li>Unprepared</li>
                        <li>Unconvinced</li>
                </ol>
                {/* <ol className='list-decimal ml-6'>
                    <li>Choose the same areas of struggle you chose for this circumstance</li>
                    <li className='mt-1'>Scroll to the bottom of the list of main themes and choose “Resistance”</li>
                    <li className='mt-1'>Choose all sub-themes related to perfectionism and fear of making mistakes, confrontation/conflict and failure</li>
                    <li className='mt-1'>After you have assessed the impact, scroll to the bottom of the emoji list, and choose 3 emotions out of this list of 5:</li>
                    <ol className='list-disc ml-10 '>
                        <li>Self-doubt</li>
                        <li>Hesitant</li>
                        <li>Resistant</li>
                        <li>Unprepared</li>
                        <li>Unconvinced</li>
                    </ol>
                    <li className='mt-1'>Complete the circumstance process</li>
                    
                </ol> */}
                <br />
                Complete the circumstance process from there.
                </>}
              
                </m.span>}
                <span className=" flex-col font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4">
                    <span className='font-semibold text-text text-[16px] leading-[20px] mb-1 mt-4'>Upcoming Features</span>
                    Thank you for your participation in our development phase.
                    <br />
                    <br />
                    We hope you continue to use this Demo to help prepare you for more of your important challenges.
                    <br />
                    <br />
                    Here are upcoming features you can look forward to thanks to your ongoing feedback to improve your experience:
                    <ol className='list-disc ml-5 mt-1'>
                            <li>Download your summary (already implemented)</li>
                            <li>Your <i>Personal Dashboard</i> to monitor and manage your overall progress</li>
                            <li>New <i>Personal Analytics</i>to help you better understand yourself</li>
                            <li>Your <i>Vision Wheel</i> to plan your well-being and performance across all areas of life</li>
                            <li>Your <i>Perception Projects Library</i> to store and work on multiple projects</li>
                            <li><i>Personal Audiobooks</i> exported from your completed Perception Projects</li>
                            <li>Educational video clips and tutorials</li>
                            <li><i>Cocoon Space</i> giving you access to mindfulness, relaxation, and meditation audios</li>
                            <li><i>Avatar Mode</i> to create the type of empowered character that you want to become</li>
                            <li><i>Dual Reinvention Mode</i> to share a joint Perception Project to empower a relationship</li>
                            <li>And much more as your feedback flows through…</li>
                    </ol>
                </span>
            </m.div>
        )
    }
};
// commit_to_change
const map_state_to_props = (state:RootState) => {
    return {
        ratings:state.app['commit_to_change']?.commitment||1,
        circ:state.app?.circgen_type?.selected
    }
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(CommitToChange);