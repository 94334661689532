import React, { Fragment, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Listbox, Transition } from '@headlessui/react'

interface IOptions {
    id:string,
    _key?:string,
    label:string
}
interface IProps {
    options: IOptions[],
    defaultValue?:IOptions
    // selected:string,
    onValueChange?:(id:string)=>void
    small?:boolean,
    emptyPlaceholder?:string,
    withTitle?:boolean,
    newVersion?:boolean
    classNameExtra?:string
}


function findItemAndParentObject(id:string,options:any) {
    let result = null;
    
    for (const theme of options) {
        const item = theme.items.find((item:any) => item.id === id);
        if (item) {
            result = {
                ...item,
                parent: theme.id
            };
            break; // Stop searching once the item is found
        }
    }
    
    return result;
}

const DropDown = ({options,small,onValueChange,defaultValue,emptyPlaceholder,withTitle,newVersion,classNameExtra}:IProps)=>{
    
    const [sls, setSelected] = useState(defaultValue?defaultValue:emptyPlaceholder?null:options[0]);
    // console.log(sls);

    useEffect(()=>{
        if(!defaultValue){
            setSelected(null);
        }
    },[defaultValue]);

    const onChange = (id:any) => {
        if(!newVersion){
            const _selected = withTitle?findItemAndParentObject(id,options): options.find((item)=>item.id === id);
            if(_selected){
                setSelected(_selected);
                onValueChange && onValueChange(id);
            }
            return;
        };

        setSelected(id);
        onValueChange && onValueChange(id);
        
    };

    const _render_list_with_title = (item:any) => {
        return (
            <div key={item._key}>
                <span className='ml-1 font-quick text-text text-[14px] leading-[19px] font-bold'>{item.label}</span>
                {item.items.map(_render_lists)}
            </div>
        )
    };

    const _render_lists = (item:IOptions) => {
    
        return (
            <Listbox.Option
                key={item._key||item.id}
                // className={({ active }) =>
                // `relative cursor-default select-none py-2 pl-10 pr-4 ${
                //     active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                // }`
                // }
                value={newVersion?item:item.id}
            >
                
                {({ selected }) => (
                    <div className={`w-full p-2  flex items-start flex-row rounded-full cursor-pointer ${selected?'bg-sub-btn':''}`}>
                        {selected?<img className='w-3 h-3 ml-1 mr-2 mt-1' src={require('../../../../assets/icons/drop_down_selected.png')}/>:null}
                        <span className={`flex font-quick text-text text-[12px] leading-[17px] font-medium`}>
                                {item.label}
                        </span>
                    </div>
                )}
            </Listbox.Option>
        )
    };

    return (
        <div className='w-full relative'>
            <Listbox  value={newVersion?sls:sls?.id} onChange={onChange}>
                <Listbox.Button className={`w-full  flex flex-row justify-between px-4 py-[15px] bg-sub-btn border border-br rounded-full items-center ${small?'py-[7.5px] px-[16px]':''}`}>
                    <span className={`font-quick text-text text-[0.875rem] leading-[18px] ${small?'text-[12px] leading-[17px] text-left line-clamp-1':''} ${!sls?'opacity-50':''} font-medium`}>{!sls?emptyPlaceholder:sls?.label}</span>
                    <img src={require('../../../../assets/icons/down.png')} className={`w-3.5 h-3.5 ${small?'w-2.5 h-2.5':''} object-contain`}/>
                </Listbox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Listbox.Options className={`absolute z-50 mt-1 max-h-44 w-full overflow-auto rounded-2xl bg-bg container_shadow p-2  ring-1 ring-black ring-opacity-5 focus:outline-none ${classNameExtra}`}>
                            {withTitle?options.map(_render_list_with_title):options.map(_render_lists)}
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    );
};

export default DropDown;