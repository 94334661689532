import capitalize from 'lodash/capitalize'
import id_to_text from '../text/id_to_text';
import express_list from './express_list.json';

export const fix_emotion = (emotion:string) => {
   return emotion==='self-doubt'?emotion: id_to_text(emotion).replace("-"," ");
};




export const fix_express_id = () => {
   let array:any[] = [];
   const id_map = {
      'what_emotion_is_preventing_you_feeling_fully_worthy_of_success?':'worthiness',
      'what_emotion_is_preventing_your_optimal_capability?':'performance_capability',
      'what_emotion_is_preventing_you_from_fully_facing_this_challenge?':'challenge_avoidance'

   }
   //  express_list
   express_list.map((item:any)=>{
         array.push({
            ...item,
            id: id_map[item.id as keyof typeof id_map] || item.id,
         });
   });

   return array;
};