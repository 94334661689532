import React from 'react';
import get_icon from '../emoji/get_icon';
import { motion } from 'framer-motion';

function removeNumbersAndSymbols(str:string) {
    var pattern = /[0-9:.,]/g;
    var result = str.replace(pattern, '');
    return result;
}
const EmojiCard = ({id,name,selected,onClick,sm,smallFont,parent, className}:{className?:string, parent?:string,smallFont?:boolean,sm?:boolean,id:string,name:string,selected:boolean,onClick:(id:string,p?:string)=>void}) => {
    const _on_click = () => {
        onClick(id,parent);
    }
    return (
        <div onClick={_on_click} className={`rounded-lg border border-br-gray cursor-pointer flex flex-col justify-center items-center p-2 w-[31%] mb-2 relative ${selected?'border-br-green':''} ${className}`}>
              {!selected?null:<motion.img animate={{scale:1,opacity:1}} initial={{scale:0,opacity:0}} layout className="w-5 h-5 absolute -left-1.5 -top-1.5 rounded-full border border-white bg-white" src={require('../../../../assets/icons/selected_circle.png')}/>}
            <img src={get_icon(id,sm)} className='w-12 h-12 object-contain' />
            <span className={`font-raleway text-title ${smallFont?'text-[0.43rem]':'text-[0.6rem]'} font-medium text-center w-[100%] break-words`}>{removeNumbersAndSymbols(name)}</span>
        </div>
    )
};


export default EmojiCard;