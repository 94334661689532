import React, { useState } from 'react';
import {motion} from 'framer-motion';
import { connect } from 'react-redux';
import { RootState } from '../../../../db/redux/store';
import ReactSlider from 'react-slider';
import InlineMultiSelect from '../../utils/components/inlineMultiSelect';

const questions = [
    {
        id:'giving_and_receiving_with_myself:_how_is_this_impacting_your_ability_to_pay_attention_to_your_values,_feelings_and_needs?',
        question:<span><span className=' font-bold'>GIVING AND RECEIVING WITH MYSELF</span><br/> How is this impacting your ability to pay attention to your values, feelings and needs?</span>,
        options:[
            {
                title:'Giving',
                id:'giving',
                items:[
                    {
                        // id:'i_am_not_giving_attention_and/or_effort_and_support_to_myself',
                        // label:`I am not giving attention and/or effort and support to **myself**`
                        label:'I am not paying attention to my values, feelings and needs.',
                        id:'i_am_not_paying_attention_to_my_values,_feelings_and_needs.'
                    },
                    {
                        // id:'i_am_tempted_to_stop_giving_attention_and/or_effort_and_support_to_myself',
                        // label:'I am tempted to stop giving attention and/or effort and support to **myself**'
                        label:'I am tempted to not pay attention to my values, feelings and needs.',
                        id:'i_am_tempted_to_not_pay_attention_to_my_values,_feelings_and_needs.'
                    },
                ]
            },
            {
                title:'Receiving',
                id:'receiving',
                items:[
                    {
                        // id:'i_am_not_giving_attention_and/or_effort_and_support_to_others',
                        // label:'I am not giving attention and/or effort and support to **others**'
                        label:'I am not allowing quality time, support, or resources to fulfill my needs.',
                        id:'i_am_not_allowing_quality_time,_support,_or_resources_to_fulfill_my_needs.'
                    },
                    {
                        // id:'_am_tempted_to_stop_giving_attention_and/or_effort_and_support_to_others',
                        // label:'I am tempted to stop giving attention and/or effort and support to **others**'
                        label:'I feel pressured to stop allowing quality time, support, or resources to fulfill my needs.',
                        id:'i_feel_pressured_to_stop_allowing_quality_time,_support,_or_resources_to_fulfill_my_needs.'
                    }
                ]
            }
        ]   
    },
    {
        id:'giving_and_receiving_with_others:_how_is_this_impacting_your_ability_to_pay_attention_to_the_values,_feelings_and_needs_of_others?',
        question:<span><span className=' font-bold'>GIVING AND RECEIVING WITH OTHERS</span><br /> How is this impacting your ability to pay attention to the values, feelings and needs of others?</span>,
        options:[
            {
                title:'Giving',
                id:'giving',
                items:[
                    {
                        // id:'i_am_not_receiving_attention_and/or_effort_and_support_from_myself',
                        // label:'I am not receiving attention and/or effort and support from **myself**',
                        label:'I am not paying attention to the values, feelings and needs of others.',
                        id:'i_am_not_paying_attention_to_the_values,_feelings_and_needs_of_others.'
                    },
                    {
                        // id:'i_am_tempted_to_stop_receiving_attention_and/or_effort_and_support_from_myself',
                        // label:'I am tempted to stop receiving attention and/or effort and support from **myself**'
                        label:'I am tempted to not pay attention to the values, feelings and needs of others.',
                        id:'i_am_tempted_to_not_pay_attention_to_the_values,_feelings_and_needs_of_others.'
                    },
                ]
            },
            {
                title:'Receiving',
                id:'receiving',
                items:[
                    {
                        // id:'i_am_not_receiving_attention_and/or_effort_and_support_from_others',
                        // label:'I am not receiving attention and/or effort and support from **others**',
                        label:'I am not allowing others to give me quality time, support, or resources to fulfill my needs.',
                        id:'i_am_not_allowing_others_to_give_me_quality_time,_support,_or_resources_to_fulfill_my_needs.'
                    },
                    {
                        // id:'i_am_tempted_to_stop_receiving_attention_and/or_effort_and_support_from_others',
                        // label:'I am tempted to stop receiving attention and/or effort and support from **others**'
                        label:'I am tempted to stop allowing others to give me quality time, support, or resources to fulfill my needs.',
                        id:'i_am_tempted_to_stop_allowing_others_to_give_me_quality_time,_support,_or_resources_to_fulfill_my_needs.'
                    },
                ]
            }
            
        ]
    }
]

const express_questions = [
    {
        id:'how_is_this_impacting_your_ability_to_pay_attention_to_your_values,_feelings_and_needs?',
        question:<span>How is this impacting your ability to pay attention to your values, feelings and needs?</span>,
        options:[
            {
                title:'',
                id:'giving',
                items:[
                    {
                        label:'I am not paying to attention to my weaknesses and needs.',
                        id:'i_am_not_paying_to_attention_to_my_weaknesses_and_needs.'
                    },
                    {
                        // id:'i_am_tempted_to_stop_giving_attention_and/or_effort_and_support_to_myself',
                        // label:'I am tempted to stop giving attention and/or effort and support to **myself**'
                        label:'I am not following through with addressing my weaknesses and needs.',
                        id:'i_am_not_following_through_with_addressing_my_weaknesses_and_needs.'
                    },
                ]
            },
            // {
            //     title:'',
            //     id:'receiving',
            //     items:[
            //         {
            //             // id:'i_am_not_giving_attention_and/or_effort_and_support_to_others',
            //             // label:'I am not giving attention and/or effort and support to **others**'
            //             label:'I am not allowing quality time, support, or resources to fulfill my needs.',
            //             id:'i_am_not_allowing_quality_time,_support,_or_resources_to_fulfill_my_needs.'
            //         },
            //         {
            //             // id:'_am_tempted_to_stop_giving_attention_and/or_effort_and_support_to_others',
            //             // label:'I am tempted to stop giving attention and/or effort and support to **others**'
            //             label:'I feel pressured to stop allowing quality time, support, or resources to fulfill my needs.',
            //             id:'i_feel_pressured_to_stop_allowing_quality_time,_support,_or_resources_to_fulfill_my_needs.'
            //         }
            //     ]
            // }
        ]   
    },
    {
        id:'2nd',
        question:null,
        options:[
            {
                title:'',
                id:'giving',
                items:[
                    {
                        label:' I am not open and encouraging of all feedback to guide me to improve.',
                        id:'i_am_not_open_and_encouraging_of_all_feedback_to_guide_me_to_improve.'
                    },
                    {
                        label:'I am not testing parts of my approach to better guide me.',
                        id:'i_am_not_testing_parts_of_my_approach_to_better_guide_me.'
                    },
                ]
            },
            // {
            //     title:'',
            //     id:'receiving',
            //     items:[
            //         {
            //             // id:'i_am_not_receiving_attention_and/or_effort_and_support_from_others',
            //             // label:'I am not receiving attention and/or effort and support from **others**',
            //             label:'I am not allowing others to give me quality time, support, or resources to fulfill my needs.',
            //             id:'i_am_not_allowing_others_to_give_me_quality_time,_support,_or_resources_to_fulfill_my_needs.'
            //         },
            //         {
            //             // id:'i_am_tempted_to_stop_receiving_attention_and/or_effort_and_support_from_others',
            //             // label:'I am tempted to stop receiving attention and/or effort and support from **others**'
            //             label:'I am tempted to stop allowing others to give me quality time, support, or resources to fulfill my needs.',
            //             id:'i_am_tempted_to_stop_allowing_others_to_give_me_quality_time,_support,_or_resources_to_fulfill_my_needs.'
            //         },
            //     ]
            // }
            
        ]
    }
]

class ValueRating extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;

    constructor(props:any) {
        super(props);
        const df = {...this.props.rating};
        this.selected = {...df}||{ 

        };
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };


    _next = () => {
        return {
            go: true,
            value:{
                ...this.selected
            }
        };
    };

    on_change = (question_id:string,item_id:string,rate:number,b:string) => {

        if(rate===0){
            try{
                this.selected[question_id] = {
                    ...this.selected[question_id],
                }
                delete this.selected[question_id][item_id];
                console.log('deleted')
            }catch (e){
            }
            return;
        }
        this.selected[question_id] = {
            ...this.selected[question_id],
            [item_id]:rate
        };
    };

     _render_questions = (item:typeof questions[0]|any) => {
        return <Question {...item} defaultValue={this.props.rating[item.id]} onChange={this.on_change} key={item.id}/>
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">{this.is_express?'Assessing my Approach to Feedback':`Impact on Giving and Receiving`}</span>
                {this.is_express? express_questions.map(this._render_questions) : questions.map(this._render_questions)}
            </div>
        );
    }
};


const map_state_to_props =(state:RootState) => {
    return {
        rating:{
            ...state.app['circgen_value_rating'],
        },
        circ:state.app?.circgen_type?.selected
    }
};
export default connect(map_state_to_props,null,null,{forwardRef:true})(ValueRating);


const Question = React.memo(({id,question,options,onChange,defaultValue}:typeof questions[0]&{onChange:any,defaultValue:any}) => {

    // const [rate,setRate] = useState<number>(1);
    const default_selected = Object.keys(defaultValue||{}).length>0?Object.keys(defaultValue):[];
    // const [selected,setSelected] = useState<string|null>(default_selected||null);
    const [selected,setSelected] = useState<string[]>(default_selected);


    const _on_change = (item_id:string) =>{
        // remove if already selected
        if(selected.includes(item_id)){
            setSelected(selected.filter((item) => item!==item_id));
            onChange(id,item_id,0);
            return;
        }
        onChange(id,item_id,1);
        setSelected([...selected,item_id]);
    };
    const _on_change_rate = (item_id:string,rate:number) => {
        // setRate(rate);
        onChange(id,item_id,rate);
        
    };


    const _render_items = (item:any) => {
        return (
            <RateText rl_text="To what level?" {...item} defaultRate={defaultValue&&defaultValue[item.id]?defaultValue[item.id]:1} onChangeRate={_on_change_rate} key={item.id} selected={selected.includes(item.id)} onChange={_on_change}/>
        )
    };

    const _render_ratings = (item:typeof options[0]) => {
        return (
            <div key={item.id}>
               {item.title===''?null:<span className='font-quick text-text text-[12px] leading-[14px] flex mb-2 font-semibold'>{item.title}</span>}
                <div className='space-y-2'>
                    {item.items.map(_render_items)}
                </div>
            </div>
        )
    };
    return (
        <div className='flex flex-col mb-4'>
            {!question?<span className='-mt-4'/>:<span className='font-quick text-title text-[13px] leading-[16px] mb-2 font-medium flex'>{question}</span>}
            <motion.div layout className='space-y-2 pt-2'>
                {options.map(_render_ratings)}
            </motion.div>
        </div>
    )
});



const RateText = ({id,onChange, label,selected,no_select,rl_text,onChangeRate,defaultRate,is_rl,is_commitment}:{is_commitment?:boolean, is_rl?:boolean,defaultRate?:number,onChangeRate?:any, rl_text?:string, id:string,selected?:boolean,no_select?:boolean,onChange:(id:string)=>void, label?:string}) => {

    const _on_change = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onChange(id);
    };

    const _on_change_rate = (num:number) => {
        onChangeRate && onChangeRate(id, num);
        // prevent default
        return false;
    };

    return (
        <motion.div layout onClick={_on_change} className={`rounded-2xl border cursor-pointer border-br-gray p-4 flex flex-col relative ${selected?'border-br-green':''} ${!label?'border-none':''}`}>
            {!selected?null:<motion.img animate={{scale:1,opacity:1}} initial={{scale:0,opacity:0}} layout className="w-5 h-5 absolute -left-1.5 -top-1.5 rounded-full border border-white bg-white" src={require('../../../../assets/icons/selected_circle.png')}/>}
          {!label?null: <motion.div layout className='font-quick text-title text-[12px] leading-[17px] font-medium' dangerouslySetInnerHTML={{__html:label.replace(/\*\*(.*?)\*\*/g, '<span class="font_bold">$1</span>')}}></motion.div>}
           {!selected&&!no_select?null:
           <motion.div animate={{opacity:1}} initial={{opacity:0}} className="mt-2.5 flex w-full flex-col">
                    <span className='font-raleway text-sub-text text-[10px] leading-[12px] font-medium'>{rl_text||'Rate the relevance:'}</span>
                    <Rate is_commitment={is_commitment} is_rl={is_rl} defaultRate={defaultRate} onChangeRate={_on_change_rate}/>
            </motion.div>}
        </motion.div>
    );
};


const rate_keys = ['a_little','a_lot','fully'];
const rates = [
    {
        id:'a_little',
        label:'A little',
        // color:{
        //     bg:'#A6EED7',
        //     text:'#40826E'
        // }  
    },
    {
        id:'a_lot',
        label:'A lot',
        // color:{
        //     bg:'#D5CB75',
        //     text:'#8c7d37'
        // }
    },
    {
        id:'fully',
        label:'Fully',
        // color:{
        //     bg:'#F7C7C7',
        //     text:'#A64F4F'
        // }
    },
   
];

const Rate = React.memo(({onChangeRate,defaultRate,is_rl,is_commitment}:any) =>{
    const [rating,setRating] = useState(defaultRate-1);

    // const _on_change = (num:number) => {
    //     if(num!==rating){
    //         setRating(num);
    //         onChangeRate(num);
    //     }
    // };
    const _on_change = (id:string[]) => {
        if(id.length<=0){
            return;
        }
        const num = rate_keys.indexOf(id[0])+1;
            setRating(num);
            onChangeRate(num);

    };

    // const _render_ratings = (item:typeof rates[0],index:number) => {
    //     return (
    //         <CheckButton onChange={_on_change} index={index} id={item.id} rating={rating} key={item.id}/>
    //     )
    // };


    const pr = (ev:any)=>{
        ev.stopPropagation();
        ev.preventDefault();
    }

    return (
        <motion.div id="rate_container"  animate={{opacity:1,transition:{delay:.2}}} initial={{opacity:0}}  className={`flex flex-col w-full px-4`}>

            <motion.div layout className='w-full flex justify-evenly pt-0.5 items-center mt-2'>
                    {/* {rates.map(_render_ratings)} */}
                  <InlineMultiSelect small single show_count={false} options={rates} defaultValue={[rate_keys[rating]]} selected={[]} onChange={_on_change} />
                    
            </motion.div>

            {/* <div onClick={pr} className='pt-2.5'> */}
                {/* <ReactSlider onSliderClick={_on_change}  defaultValue={2} value={rating} onChange={_on_change} className={is_commitment?"slider_inverted_green_active":"slider_inverted_active"} min={1} max={3} trackClassName={is_commitment?"slider_inverted_green":"slider_inverted"} renderThumb={(props:any, state:any) => <div {...props} className="w-4 h-4 rounded-full track_shadow outline-none ring-0 -top-[7px] border-[3px] border-br bg-btn ovr_rnd"/> } /> */}
                {/* <div className='w-full flex flex-row justify-between items-center pt-2.5'>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>1</span>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>10</span>
                </div> */}
            {/* </div> */}

        </motion.div>
    )  
});


// const CheckButton = ({index,rating,onChange,id}:{index:number,rating:number,onChange:any,id:string}) => {

//     const _on_change = (e:any) => {
//         onChange(index+1);
//         e.stopPropagation();
//     };

//     return ( 
//         <motion.div onClick={_on_change}  layout className={`rounded-full px-5 p-2 cursor-pointer`} style={{background:`${rates[index].color.bg}`,opacity:rating===(index+1)?1:.5}}>
//             <motion.span layout style={{color:rates[index].color.text}} className='font-raleway text-[10px] leading-[12px] flex'>{rates[index].label}</motion.span>
//         </motion.div>)
// }