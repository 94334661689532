import React from "react";
import EmotionIntensityRate from "../../../utils/components/EmotionIntensityRate";
import { RootState } from "../../../../../db/redux/store";
import { connect } from "react-redux";
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import id_to_text from "../../../utils/text/id_to_text";




class EmotionalRatingIntensity extends React.PureComponent<any,any>{
    options: any[];
    rating: any; 
    constructor(props:any) {
        super(props);
        this.options = [];
        const df = this.props.rating;
        this.rating = {...df};

        this.props.emotional_rating.map((item:any) => {
            this.options.push({
                id:item,
                // first letter capital
                // name:capitalize(item),
                name:item==='self-doubt'?capitalize(item): capitalize(id_to_text(item).replace("-"," ")),

            })
            if(isEmpty(df)){
                this.rating[item] = 1;
            }
        });
      
    };


    _next = () => {
        return {
            go: true,
            value:{
                ...this.rating
            }
        };
    };

    _on_change = (id:string,rate:number) => {
        this.rating[id] = rate;
    };


    _render_emotions = (item:any) => {
        return <EmotionIntensityRate noEmoji noPre defaultRate={this.props.rating[item.id]} onChange={this._on_change} {...item} key={item.id} />
    };


    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Further Defining How You Feel</span>
                <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>To continue better understanding how you feel, rate the intensity of each emotion.</span>
                
                <div className='pt-4 space-y-2'>
                        {this.options.map(this._render_emotions)}
                </div>

            </div>
        )
    }
}

const map_state_to_props = (state:RootState) => {
    return {
        emotional_rating:state.app['decon_emotions_rate']?.emotional_rating,
        rating:state.app['decon_emotions_rate_intensity']||{}
    }
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(EmotionalRatingIntensity);