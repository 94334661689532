import React from 'react';
import get_icon from '../emoji/get_icon';


interface IItemOption {
    id:string;
    icon?:string;
    title:string;
    intensity?:number;
    mainFocus?:boolean
}

interface IItem {
    id:string;
    titles:string[];
    options:IItemOption[];
}


const ListReviewer = ({items,}:{items:IItem[]}) => {


    const _render_sections = (item:IItem) => {
        return <Section {...item} key={item.id}/>
    };  

    return (
        <div className='w-full'>
            {items.map(_render_sections)}
        </div>
    );       
};



const Section = ({titles,options}:IItem) => {

    const _render_row = (item:IItemOption) =>{
        return <Row {...item} key={item.id}/>  
    };

    const _render_title = (title:string) => {
        return <span key={title} className='font-quick text-text text-[10px] leading-[12px] font-medium title_list_viewer'>{title}</span>
    };

    return (
        <div className='flex w-full flex-col'>
                <div className='flex justify-between items-center border-b border-br-gray pt-6 pb-2'>
                    {titles.map(_render_title)}
                </div>
                {options.map(_render_row)}
        </div>
    )
};


const Row = ({icon,title,id,intensity,mainFocus}:IItemOption) => {
    return (
        <div  className={`flex flex-col w-full p-2 border-b border-br-gray ${mainFocus?'bg-sub-bg':''}`}>
         {mainFocus?<span className='font-raleway text-title text-[8px] leading-[9px] flex mb-2'>Main focus</span>:null}
            <div className='flex flex-row items-center justify-between w-full'>
                <div className='flex flex-row items-center w-[85%] pdf_title_class'>
                    {icon? <img src={get_icon(icon)} className='w-8 h-8 mr-2'/>:<div  className='h-8'/>}
                    <span className='font-quick text-[12px] leading-[17px] text-text font-medium whitespace-pre-line'>{title}</span>
                </div>
                {intensity? <span className='font-quick text-text text-[14px] leading-[18px] font-medium'>{intensity}</span>:null}
            </div>
        </div>
    )
};



export default ListReviewer;