import React, { useState } from "react";
import {motion} from 'framer-motion';
import ReactSlider from 'react-slider';
import DR from "../rates";




const IntensityRate = ({label,noborder,providedRates,onChange,defaultRate=1,is_commitment,noPre}:{labelTitle?:string,noPre?:boolean,is_commitment?:boolean,defaultRate?:number,providedRates?:any,noborder?:boolean,label?:string,onChange?:any}) =>{
        const rates = providedRates||DR;
        const [rating,setRating] = useState(defaultRate||1);
        const _on_change = (num:number) => {
            const val = Math.ceil(num / 10)
            const value =  val||1;
            if(value!==rating){
                onChange && onChange(value);
                setRating(value);
            }
        };
    
    
    return (
        <motion.div  animate={{opacity:1,transition:{delay:.2}}} initial={{opacity:0}}  className={`flex flex-col w-full ${noborder?'':'border-t border-br-gray border-dashed mt-2'} pt-[9px]`}>
            {noPre?null:<span className='font-raleway text-[10px] leading-[12px] text-title flex'>{label||'Rate the impact of this situation to your Emotional Well-being:'}</span>}

            <motion.div layout className='w-full flex justify-center items-center mt-2'>
                    <motion.div layout className={`rounded-full px-2.5 p-2`} style={{background:rates[rating-1].color.bg}}>
                        <motion.span layout style={{color:rates[rating-1].color.text}} className='font-raleway text-[10px] leading-[12px flex'>{rating} | {rates[rating-1].label}</motion.span>
                    </motion.div>
            </motion.div>

            <div className='pt-2.5'>
                <ReactSlider onChange={_on_change} defaultValue={defaultRate*10} min={1} max={100} className={is_commitment?"slider_inverted_green_active":"slider_inverted_active"} trackClassName={is_commitment?"slider_inverted_green":"slider_inverted"} renderThumb={(props:any, state:any) => <div {...props} className="w-4 h-4 rounded-full track_shadow outline-none ring-0 -top-[7px] border-[3px] border-br bg-btn ovr_rnd"/> } />
                <div className='w-full flex flex-row justify-between items-center pt-3.5'>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>1</span>
                        <span className='text-[12px] leading-[15px] font-fra text-text'>10</span>
                </div>
            </div>

        </motion.div>
    );
};


export default IntensityRate;