function getValuesAfterItem(steps:string[], response:string[], item:string) {
    const startIndex = steps.indexOf(item);
  
    // If the item is not found in the steps array or it's the last element,
    // there are no values after it in the response array.
    if (startIndex === -1 || startIndex === steps.length - 1) {
      return [];
    }
  
    // Get the subarray of steps starting from the item's index + 1 till the end.
    const remainingSteps = steps.slice(startIndex + 1);
  
    // Use the remainingSteps array to filter the response array.
    const valuesAfterItem = response.filter((val) => remainingSteps.includes(val));
  
    return valuesAfterItem;
}


export {
    getValuesAfterItem
}