import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../db/redux/store";
import { Dispatch } from "redux";
import Logout from '../../../../assets/icons/exit.png';

 class Instruction extends React.PureComponent<any,any> {
    constructor(props:any) {
        super(props);
     
    };


    _next = () =>{
    
        return {
            go: true,
            value:{
              
            }
        };
    }

   

    render(): React.ReactNode {
        return(
            <div  className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40"> 
            {/* className="w-full h-full flex px-4 py-6 flex-col justify-between"> */}
                <span className="font-quick text-text text-[14px] leading-[17px] font-medium whitespace-pre-wrap">
                    <span className="font-bold">Exiting the Demo on a <span className="underline">smartphone:</span></span>
                    <br />
                    <br />
                    <i>To come back to your circumstance later</i>
                    <br />
                    <br />
                    Please note that if you need to take a break or leave the Demo at any time while working through a circumstance and you would like to finish the circumstance you are working through later, please just close your phone internet browser window (do not log out). This will keep the data you have entered up until the point you leave.
                    <br />
                    <br />
                    <i>To clear your data</i>
                    <br />
                    <br />
                    To clear the data you have entered please use the 
                    <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> icon. But remember, this means you'll have to start a new circumstance when you log back in.
                    <br />
                    <br />
                    <br />
                    <span className="font-bold">Exiting the Demo on a <span className="underline">computer:</span></span>
                    <br />
                    <br />
                    <i>To come back to your circumstance later</i>
                    <br />
                    <br />
                    Please note that if you need to take a break or leave the Demo at any time while working through a circumstance and you would like to finish the circumstance you are working through later, please click the red <span className=" text-red-700">ⓧ</span> on your browser window (do not log out). This will keep the data you have entered up until the point you leave.
                    <br />
                    <br />
                    <i>To clear your data</i>
                    <br />
                    <br />
                    To clear the data you have entered please use the
                    <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> icon. But remember, this means you'll have to start a new circumstance when you log back in.
                </span>
              

            </div>
        )
    }
};

const map_state_to_props = (state:RootState) => {
    return {
   
    }
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {

    }
};

export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(Instruction);