import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { domAnimation, LazyMotion } from 'framer-motion';
import { Provider } from 'react-redux';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

import awsExports from './aws-exports';


import './index.css';

import reportWebVitals from './reportWebVitals';
import { store } from './db/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';

Amplify.configure({
  ...awsExports,
  // Auth:{
  //   identityPoolId:'us-east-1_fbjvADRND',
  //   region:'us-east-1'
  // }
});

// changes
const container:HTMLElement|null = document.getElementById('root');
if(!container){
  throw new Error("container not found");
};

const root = createRoot(container);
const persistor = persistStore(store);

const Wrapper:FC = () => {
  return (
    // <React.StrictMode>
      // <LazyMotion strict features={domAnimation}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <Authenticator.Provider>
                        <App />
                  </Authenticator.Provider>
              </PersistGate>
          </Provider>
      // {/* </LazyMotion> */}
  )
};




root.render(<Wrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
