import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../db/redux/store';
import id_to_text from '../../utils/text/id_to_text';
import { arry_of_strings_to_text } from '../../utils/text';
import PromptLoader from '../../../../common/componenets/prompt_loader';
import PromptError from '../../../../common/componenets/prompt_error';
import { get_response } from '../../../../utils/request/get';
import {motion} from 'framer-motion'
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../db/redux/features/gpt_response/response.slice';
import capitalize from 'lodash/capitalize';
import { update_db } from '../../../../utils/dynamoDB/fetch';
import { external_they_keys } from '../../../../utils/vision_wheel';
import { update_prompt_state } from '../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { findItemAndParentObject } from '../../../../utils';
import express_list from '../../utils/emoji/express_list.json';
import { fix_express_id } from '../../utils/emoji/fix_emotions';
import { update_tools_state } from '../../../../db/redux/features/tools/tools.slice';
import { stream_summary } from '../../../../utils/request/stream';
class CircumstanceSummary extends React.PureComponent<any,any>{
    is_express: boolean;
    is_streaming: boolean;

    constructor(props:any) {
        super(props);
        this.state = {
            loading:this.props.summary_fetched?false:true,
            error:false,
            summary:this.props.summary_fetched||'',
            status_code:0
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself');
        this.is_streaming = false;

    };


    componentDidMount(): void {
        if(!this.props.summary_fetched){
            this.fetch_summary();
        };
    }

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.fetch_summary();
    };

    fetch_summary = async() => {
        try{

            // stream_summary({type:'generate_summary',values:this.props.api,include_prompt:true,isExpress:this.is_express});
            // return;
       
            this.is_streaming = true;
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  

            let extra = {};

            // if(this.is_express){
            //     // top_emotions
            //     const emotions = this.props.api.top_emotions;
            //     let new_array = [];

            //     emotions.map((i:string)=>{
            //         const parent = findItemAndParentObject(i, express_list);
            //         if(parent){
            //             new_array.push({parent:parent.label,emotion:i});
            //         }
            //     });

            // }
            if(this.state.summary){
                this.setState({summary:''});
                this.props.update_state('','',[]);
                update_db({value:''},this.props.session_id,'summary_fetched','verve_demo_session_gpt_response')
            }
            const res = await stream_summary({type:'generate_summary',values:this.props.api,include_prompt:true,isExpress:this.is_express,isDemo:window.location.hostname.includes('demo')},(text:string)=>{
                    this.state.loading&&this.setState({loading:false,error:false});
                    this.setState((prv:any)=>{
                        return {
                            summary:prv.summary+text
                        }
                    
                    });
            });

            this.is_streaming = false;


            // const data:any = await get_response({type:'generate_summary',values:{
            //     ...this.props.api,
            // },include_prompt:true,isExpress:this.is_express});
            // console.log(data);
            // const res = data.res;

            this.props.update_state(res||'','',[]);
            // this.is_streaming = false;
            update_db({value:res||''},this.props.session_id,'summary_fetched','verve_demo_session_gpt_response')
            // this.setState({summary:data.res,loading:false,error:false})
        }catch(e:any){
            console.log(e);
            this.is_streaming = false;
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };

    _back = () => {
        this.props.update_state(null,null,[]);
        // update_db({value:null},this.props.session_id,'summary_fetched','verve_demo_session_gpt_response')
    };

    _next = () => {
        return {
            go:this.is_streaming?false:true,
            value:{
            },
            msg:'Please wait until the summary is generated.'
        }
    };  

    render(): React.ReactNode {
        // console.log(this.props)
        return (
            <div className="w-full h-full flex flex-col overflow-scroll action_container pb-40">
                {
                    this.state.loading?
                    <PromptLoader message={this.is_express?'You are about to see a summary describing how you are under prepared.':`Take time to pause.\n\nDo you feel fully connected to this description?\nThe more you validate how you’re impacted by a circumstance, the more empowered you’ll be to understand and address it.`}/>
                    :
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this.fetch_summary}/>
                    :
                <motion.div className='flex flex-col' animate={{opacity:1}} initial={{opacity:0}}>
                    <div className='flex w-full h-[23%] relative'>
                        <img className='w-full h-full object-cover rounded-tl-2xl rounded-tr-2xl absolute' src='https://images.unsplash.com/photo-1580136807188-8fc02811caf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80'/>
                        <span className='flex text-white  font-quick text-[20px] leading-[25px] z-50 m-4 font-semibold'>Describing Your Circumstance</span>
                    </div>
                    <div className='flex w-full p-4 flex-col'>
                        <span className='font-quick font-bold text-title text-[12px] leading-[17px] flex mt-2'>Circumstance Title</span>
                        <div className='bg-bg border border-br-gray p-4 rounded-2xl mt-[9px]'>
                            <span className='font-quick text-[13px] leading-[20px] flex text-text font-medium'>
                            {/* ${external_they_keys.includes(this.props.domain)?'my':'my'} */}
                           {this.is_express?`My ${this.props.theme} in a ${this.props.theme_parent} context relating to ${this.props.subtheme} that will happen in ${this.props.duration}.`:`${capitalize(this.props.domain)} relating to my ${this.props.subdomain} defined by themes: ${`${this.props.theme}`.toLowerCase()}, ${this.props.subtheme.toLowerCase()}.`}
                            {/* {`My ${this.props.domain}, specifically related to my ${this.props.subdomain} is affected by ${this.props.theme} that is associated with ${this.props.subtheme}.`} */}
                            </span>
                        </div>
                        <span className='font-quick font-bold text-text text-[12px] leading-[17px] flex mt-4'>Description</span>
                        <div className='w-full flex mt-2'>
                            <span className=' font-raleway flex text-[13px] leading-[17px] font-medium text-sub-text'>
                            {this.state.summary}
                            {/* {`This approximately began ${this.props.begin_time} and this has been occurring on a ${this.props.occurrence} basis and continues to affect me to this day.`}<br />
                            <br />
                            {`This  with the most intense impact being ${this.props.ranked_domain} in the areas of ${this.props.deep_dive}.`}
                            <br />
                            {`My most intense emotions are ${this.props.rated_emotion}.`} */}
                            {/* This has had a significant impact on my emotional wellbeing and relationships, particularly in the area of self-worth. I am feeling intense emotions of distressed. As a result, I am struggling to value myself, leading to a Sustainability score of 4, indicating that this cannot continue for more than three months. */}
                            </span>
                        </div>
                        <span className='font-quick font-bold text-text text-[12px] leading-[17px] flex mt-5'>Tip</span>
                        <span className='font-raleway  text-sub-text text-[12px] leading-[14px] mt-1'>If this description validates the impact, select “Next” to continue. If not, please select “Previous” to make any required changes.</span>


                    </div>

                </motion.div>
                }
        </div>
        )
    }
}



const get_emotions = (emotions:string[]) => {
    let emo:any = [];

    emotions.map((i:string)=>{
        const parent = findItemAndParentObject(i, fix_express_id());
        if(parent){
            emo.push({parent:id_to_text(parent.parent),emotion:i});
        }
    });

    return emo;
};
                                  

const map_state_to_props = (state: RootState) => {
    return {
        domain:id_to_text(state.app['circgen_vision_wheel']?.struggle_intense||''),
        subdomain:state.app['circgen_vision_wheel']?.focus?.replaceAll("_","-"),
        theme:id_to_text(state.app['circgen_theme']?.theme?.id||''),
        subtheme:arry_of_strings_to_text(id_to_text(state.app['circgen_theme']?.subtheme||'')),

        theme_parent:state.app?.circgen_type?.selected==='prepare_myself'?state.app['circgen_theme']?.theme?.parent:'',
        duration:id_to_text(state.app['circgen_duration_occurance']?.apprx[0]||''),

        api:{
            domain:state.app['circgen_vision_wheel']?.struggle_intense||'',
            sub_domain:state.app['circgen_vision_wheel']?.focus,
            dominant_theme:state.app['circgen_theme']?.theme?.id||'',
            sub_themes:state.app['circgen_theme']?.subtheme||[],
            started_at:state.app['circgen_duration_occurance']?.apprx[0],
            occurance:state.app['circgen_duration_occurance']?.occurrence[0],
            circgen_impact:state.app['circgen_impact']?.selected||'',
            sus_dev_rating:state.app["circgen_sus_dev"].sus_dev_rate,
            vision_wheel_internal:state.app["circgen_vision_wheel_internal"],
            vision_wheel_external:state.app["circgen_vision_wheel_external"],
            highest_ranking_domain:state.app['circgen_deep_dive'],
            top_emotions: state.app?.circgen_type?.selected==='prepare_myself'?state.app['circgen_select_emotion'].selected: state.app['circgen_rate_emotion'],
            value_rating:state.app['circgen_value_rating'],
            express_emotion:state.app?.circgen_type?.selected==='prepare_myself'? get_emotions(state.app['circgen_select_emotion'].selected):[],
            theme_parent:state.app?.circgen_type?.selected==='prepare_myself'?state.app['circgen_theme']?.theme?.parent:'',
        },
        // 
        summary_fetched:state.response['summary_fetched']?.response||state.response['summary_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    }
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'summary_fetched',value}))
            dispatch(update_prompt_state({key:'summary_fetched',value:prompt}))
            dispatch(update_tools_state({key:'summary_fetched',value:tools}))
         },
    }    
};


export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(CircumstanceSummary);