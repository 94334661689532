import {motion, useSpring} from 'framer-motion';
import React from 'react';
import get_icon from '../emoji/get_icon';


export interface ISelectOption {
    id: string;
    label: string;
    icon?:null|string;
    disabled?:boolean;
};

const MultiSelect = ({ options, selected, onChange,no_padding }:{no_padding?:boolean,options:ISelectOption[],selected:string|null,onChange:(id:string)=>void}) => {

    const _on_change_pre_check = (id:string) =>{
        if(id===selected){
            return;
        };
        onChange(id);
    };

    const _render_option = (option:ISelectOption) => {
        return <Option key={option.id} no_padding={no_padding} {...option} onChange={_on_change_pre_check} selected={option.id===selected}/>
    };

    return (
        <div className={`space-y-2 w-full justify-center items-center ${no_padding?'px-0':'px-0 exsm:px-10'}`}>
            {options.map(_render_option)}
        </div>
    )
};


const Option = React.memo(({id,label,onChange,selected,icon,no_padding,disabled}:ISelectOption&{no_padding?:boolean,onChange:(id:string)=>void,selected:boolean,icon?:null|string}) => {

    const scale = useSpring(1);

    const _down = () => {
        scale.set(1.01);
    };


    const _on_chnage = () => {
        setTimeout(()=>{
            scale.set(1);
        },40);
        onChange(id);
    };

    return (
        <motion.div layout onClick={_on_chnage} style={{scale}} onMouseUp={_down} className={`border-br border relative rounded-full w-full flex flex-row items-center cursor-pointer justify-center ${no_padding?'px-7 py-[21px]':'py-3.5'} ${selected?'bg-sub-btn':''} ${disabled?'opacity-30 pointer-events-none cursor-none':''}`}>
           {icon?<img className='w-8 h-8 absolute left-4 bg-transparent' src={get_icon(id)}/>:null}
            <div className='flex flex-row items-center'>
                {selected?<motion.img initial={{opacity:0,scale:0}} animate={{opacity:1,scale:1}} layout src={require('../../../../assets/icons/selected.png')} className="w-5 mr-2"/>:null}
                <motion.span layout className={`text-text font-quick flex  line-clamp-1 font-medium ${no_padding?'text-[12px] leading-[17px]':'text-[14px] leading-[18px]'} text-center`}>{label}</motion.span>
            </div>
        </motion.div>
    )
},(prev,next)=>(prev.selected===next.selected));


export default MultiSelect;