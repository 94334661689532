import trim from "lodash/trim";

export function capitalizeFirstLetter(s:string) {
    const str = trim(s);
    return str.charAt(0).toUpperCase() + str.slice(1);
}
  
export default(value:string|string[],append?:string):any => {
    //ex => key_value return key value
    if (typeof value === 'string') {
        return value.split('_').map((word, index) => {
            if (index === 0) {
                return word;
            };
            return word.charAt(0) + word.slice(1);
        }).join(' ');
    };
    //ex => [key_value, key_value] return [key value, key value]
    if (Array.isArray(value)) {
        return value.map((item) => {
            return item.split('_').map((word, index) => {
                if (index === 0) {
                };
                return word.charAt(0) + word.slice(1);
            }).join(' ');
        });
    }
};