import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gpt_steps, steps } from '../../../../utils/steps';
import { getValuesAfterItem } from '../utils';


interface ResponseState {
    [key:string]:any
};

const initialState:ResponseState = {
}



  
export const responseSlice = createSlice({
  name: 'response',
  initialState,
  reducers: {
        update_response_state:(state,action:PayloadAction<{key:string,value:object}>)=>{
            const {key,value} = action.payload;
            state[key as keyof ResponseState] = value;
        },

        clear_obslate_response:(state,action:PayloadAction<{current:string}>)=>{

            const current = action.payload.current;
            const gpt_keys = Object.keys(gpt_steps);
            // console.log(current)
            const obslete = getValuesAfterItem(steps,gpt_keys,current);
            // console.log(obslete);
            obslete.forEach(key=>{
                const items = gpt_steps[key as keyof typeof gpt_steps];
                items.forEach(item=>{
                    if(state[item as keyof ResponseState]){
                        delete state[item as keyof ResponseState];
                    };
                });
            });

            
        },

        propogate_response_state_value:(state,action:PayloadAction<any>)=>{
            Object.keys(state).forEach(key=>{
                delete state[key as keyof ResponseState];
            });
            const keys = Object.keys(action.payload);
            keys.forEach(key=>{
                state[key as keyof ResponseState] = action.payload[key];
            });
        },

        propogate_response_state:(state,action:PayloadAction<any>)=>{
            const keys = Object.keys(action.payload);
            keys.forEach(key=>{
                state[key as keyof ResponseState] = action.payload[key].value;
            });
        },
        remove_response_state:(state,action:PayloadAction<string>)=>{
            const key = action.payload;
            if(state[key as keyof ResponseState]){
                delete state[key as keyof ResponseState];
            };
        },
        clear_response_state:(state)=>{
            return initialState;
        }
  },
});

// Action creators are generated for each case reducer function
export const {update_response_state,remove_response_state,clear_response_state,propogate_response_state,clear_obslate_response,propogate_response_state_value} = responseSlice.actions;

export default responseSlice.reducer;


