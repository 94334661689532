import commitment from "../commitment";
import get_icon from "../emoji/get_icon";
import IntensityRate from "./intensityRate";

const intensity = [
    {
        id:1,
        label:'No intensity',
        color:{
            bg:'#A6EED7',
            text:'#40826E'
        }

    },
    {
        id:2,
        label:'Very low intensity',
        color:{
            bg:'#D5CB75',
            text:'#A68F2E'
        }
    },
    {
        id:3,
        label:'Slightly low intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:4,
        label:'Low intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        },
    },
    {
        id:5,
        label:  'Moderate intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:6,
        label:'Moderately high intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:7,
        label:'High intensity',
        color:{
            bg:'#F7C7C7',
            text:'#A64F4F'
        }
    },
    {
        id:8,
        label:'Very high intensity',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:9,
        label:'Extremely high intensity',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    },
    {
        id:10,
        label:'Intense beyond words',
        color:{
            bg:'#FCE3E1',
            text:'#E70909'
        }
    }
]

const EmotionIntensityRate = ({id,name,defaultRate,onChange,rtl_label,is_commitment,noEmoji,noPre}:{noPre?:boolean,noEmoji?:boolean,is_commitment?:boolean,rtl_label:string, id:string,name:string,defaultRate?:number,onChange?:any}) => {

    const _on_change = (num:number) => {
        onChange && onChange(id, num);
    };

    const rate = is_commitment?commitment:intensity;

    return (
        <div className='px-2 py-4 pr-7 rounded-2xl border border-br-gray flex flex-row space-x-2'>
          {noEmoji?<div className="pl-3"/>:  <img src={get_icon(id)} className="w-12 h-12"/>}
            <div className='flex flex-col w-full'>
                    <span className='font-quick text-[13px] leading-[16px] text-text flex font-medium'>{name}</span>
                    <IntensityRate noPre is_commitment={is_commitment} providedRates={rate} defaultRate={defaultRate} onChange={_on_change} noborder label={rtl_label||"Rate the intensity of this emotion"}/>
            </div>
        </div>
    )
};  

export default EmotionIntensityRate;