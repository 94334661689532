import React, { useState } from 'react';
import {motion} from 'framer-motion';
import RateText from '../../../utils/components/rate_text';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import { getHighestNumber } from '../../../../../utils/number/get_highest';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { get_response } from '../../../../../utils/request/get';
import { extractText, getLabel } from '../../../utils/extractor';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { findItemAndParentObject } from '../../../../../utils';
import id_to_text from '../../../utils/text/id_to_text';
import express_list from '../../../utils/emoji/express_list.json';
import { fix_emotion, fix_express_id } from '../../../utils/emoji/fix_emotions';
import capitalize from 'lodash/capitalize';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';


class ThoughtRelevance extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;

    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        console.log(this.props);
        this.state = {
            loading:this.props.thoughts_decon_fetched&&this.props.thoughts_decon_fetched.length>0?false:true,
            error:false,
            options:this.props.thoughts_decon_fetched||[],
            selected:[...default_keys],
            status_code:0

        };
        this.selected = { 
            ...df
        };  
         this.is_express = Boolean(this.props.circ==='prepare_myself')

    };

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_thoughts();
    };


    componentDidMount(): void {

        if(!this.props.thoughts_decon_fetched||(this.props.thoughts_decon_fetched&&this.props.thoughts_decon_fetched.length===0)){
            this._fetch_thoughts();
        };

    }


    _fetch_thoughts = async() => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'decon_thoughts',values:{
                ...this.props.api,
                // tmp:.2
            },include_prompt:true,isExpress:this.is_express});
            console.log(data);
            // const ex = ["Fear - 'I am not capable of handling this situation.'"]
            let options:any[] = [];

            data.res.map((itms:{emotion:string,thoughts:string[]})=>{

                    let extra:{id:string,label:string,is_selected:boolean,parent_id:string}[] = [];
                    itms.thoughts.map((txt:string)=>{
                        extra.push({
                            id:txt.replaceAll(' ','_'),
                            label:txt,
                            is_selected:false,
                            parent_id:itms.emotion.replaceAll(' ','_')
                        });
                    });
                    options.push({
                        id:itms.emotion.replaceAll(' ','_'),
                        label:capitalize(itms.emotion),
                        extra:extra
                    });

                // const extraMapped = extractText(txt);
                // let extra:any[] =[];
                // extraMapped.map((item:any)=>{
                //     extra.push({
                //         ...item,
                //         parent_id:`${txt}`.replaceAll(' ','_'),
                //     });
                // });
                // options.push({
                //     id:`${txt}`.replaceAll(' ','_'),
                //     extra,
                //     label:getLabel(txt)
                // });
            });
            
            console.log(data);
            this.props.update_state(options,data.prompt,data.tools);
            update_db({value:options},this.props.session_id,'thoughts_decon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false})
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            console.log(e);
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    };


    _next = () =>{
        //given array of number return true only if it has higher number
       

        // const value = getHighestNumber(Object.values(this.selected));

        // if(!value){
        //     alert('Should only have one highest rated thought.')
        //     return {
        //         go:false,
        //         value:{

        //         }
        //     };
        // };

        let new_selected:any = {};
        // console.log(this.state.options);
        // console.log(this.selected);
        const keys = Object.keys(this.selected);
        keys.map((key:string)=>{
            const item = this.state.options.find((item:any)=>item.id===key);
            // console.log(item);
            const extraSelected = item.extra.filter((item:any)=>item.is_selected);
            let new_id = `${item.label} - `;
            extraSelected.map((item:any,index:number)=>{
                // only add , if its not the last item
                new_id += ` '${item.label}'${index===extraSelected.length-1?'':','}`;
            });
            new_id = new_id.replaceAll(' ','_');
            new_selected[new_id] = this.selected[key];
        }); 

        let new_options:any[] = [];

        this.state.options.map((item:any)=>{
            const extraSelected = item.extra.filter((item:any)=>item.is_selected);
            let new_id = `${item.label} - `;
            extraSelected.map((item:any,index:number)=>{
                // only add , if its not the last item
                new_id += ` '${item.label}'${index===extraSelected.length-1?'':','}`;
            });
            let new_extra:any = [];
            new_id = new_id.replaceAll(' ','_');

            item.extra.map((item:any)=>{
                new_extra.push({
                    ...item,
                    parent_id:new_id
                });
            });
            new_options.push({
                ...item,
                extra:new_extra,
                id:new_id
            });
        });

        let _check_is_min_selected:{ [key: string]: boolean } = {};
   

        this.state.selected.map((i:string)=>{
            const item  = this.state.options.find((item:any)=>item.id===i);
            _check_is_min_selected[item.id as keyof typeof _check_is_min_selected] = item.extra.filter((item:any)=>item.is_selected).length>0;
        });

        const _all_is_selected = Object.values(_check_is_min_selected).every((item:boolean)=>item===true);

    

        this.props.update_state(new_options);
        update_db({value:new_options},this.props.session_id,'thoughts_decon_fetched','verve_demo_session_gpt_response')
            console.log(_all_is_selected,_check_is_min_selected,keys);
        return {
            go:_all_is_selected&&keys.length>0,//this.state.selected.length>0,
            // go:false,
            msg:keys.length>0?`Please select at least one thought for each emotion.`:`Please select at least one emotion and one thought for each emotion.`,
            value:{
                ...new_selected
                // ...this.selected
            }
        }
    };

    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'thoughts_decon_fetched','verve_demo_session_gpt_response')
    };

    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };

    _on_select = (id:string) => {
        if(this.state.selected.includes(id)){
            this.setState((state:any)=>({
                selected:state.selected.filter((item:string)=>item!==id),
                options:state.options.map((item:any)=>{
                    if(item.id===id){
                        return {
                            ...item,
                            extra:item.extra.map((item:any)=>({
                                ...item,
                                is_selected:false
                            }))
                        }
                    }else{
                        return item;
                    }
                })
            }));
            delete this.selected[id];

            // setSelected(selected.filter(_id=>_id!==id));
        }else{
            // setSelected([...selected,id]);
            this.setState((state:any)=>({
                selected:[...state.selected,id]
            }));
            this.selected[id] = 1;
        }
    };

    on_sub_thought_click = (item_id:string,parent_id:string) => {
        let options = [...this.state.options];
        const parent_index = options.findIndex((item: any) => item.id === parent_id);
        const sub_index = options[parent_index].extra.findIndex((item: any) => item.id === item_id);
      
        const updatedOptions = [...options];
        const updatedParent = {
          ...updatedOptions[parent_index],
          extra: [...updatedOptions[parent_index].extra],
        };
        const newSelectStatus = !updatedParent.extra[sub_index].is_selected;
        updatedParent.extra[sub_index] = {
          ...updatedParent.extra[sub_index],
          is_selected:newSelectStatus
        };
      
        updatedOptions[parent_index] = updatedParent;
        
        const selected_extras = updatedOptions[parent_index].extra.filter((item:any)=>item.is_selected);
        if(selected_extras.length<=0){
            if(this.selected[parent_id]){
                 this._on_select(parent_id);
            }
        }else{
            if(!this.selected[parent_id]){
                this._on_select(parent_id);
            }
        }
      
        this.setState({ options: updatedOptions });
    };

    _render_item = (item:any) => {
        return <SubThought {...item} key={item.id} onClick={this.on_sub_thought_click}/>;
    };

    _render_sub_items = (id:string,sub_items:any[]) => {
        return (
            <motion.div key={id} className='mt-1 space-y-1.5'>
                {sub_items.map(this._render_item)}
            </motion.div>
        );
    };

    _render_ratings = (item:any) => {
        return (
            <RateText is_rl {...item} boldLabel renderExtra={this._render_sub_items} defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} key={item.id} selected={this.state.selected.includes(item.id)} onChangeRate={this._on_change_rate} onChange={this._on_select}/>
        )
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                {
                    this.state.loading?
                    <PromptLoader  message={this.is_express?`Let's look at the thinking that may be in the way of you feeling as prepared as you would like to be.`:`Your thoughts matter!\n\nNow for many reasons\nyou may feel this way.`} subtitle={this.is_express?'Let’s look at the thinking that may be in the way of you feeling prepared.':`Your thoughts matter!\n\nNow for many reasons\nyou may feel this way.`} isNew={false} title='Deconstructing your Thoughts' image='https://images.unsplash.com/photo-1679563559367-6452affe2705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2101&q=80'/>
                    // <PromptLoader message={Now for many reasons you may feel this way.`}/>
                    :
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_thoughts}/>
                    :
                    <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                            {this.is_express?null:<span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">What You Think</span>}
                            {/* <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">When you feel Burdened at the Connect Fully stage of the Circumstance, rate the thinking that makes you feel this way.</span> */}
                            <span className='flex flex-col font-quick text-text text-[13px] leading-[16px] font-medium mb-4'>
                                {this.is_express?'Identifying your personal thoughts is going to continue to deepen your understanding so you can improve your approach.':
                                    <>
                                        What we think influences what we feel. Different types of thoughts shape our awareness, for example:
                                        <br/>
                                        <br/>
                                        <ol className=' list-decimal pl-5'>
                                            <li>Thoughts that are formed consciously with words; and</li>
                                            <li>Thoughts that are subtle and fleeting, often without words</li>
                                        </ol>
                                        <br />
                                        Choose the thoughts that best explain why you feel the emotions you have selected. Then, rate the relevance of these thoughts for each emotion.
                                    </>
                                }
                            </span>
                            <motion.div layout className='space-y-2'>
                                {this.state.options.map(this._render_ratings)}
                            </motion.div>
                    </motion.div>
                }
            </div>
        )
    }
};


const SubThought = ({id,label,is_selected,onClick,parent_id}:{parent_id:string,onClick?:any,id:string,label:string,is_selected?:boolean}) =>{
    const _on_click = (ev:any)=>{
        onClick(id,parent_id);
        ev.stopPropagation();
        ev.preventDefault();
    }
    return (
        <motion.div onClick={_on_click} className={`relative cursor-pointer flex items-center ${is_selected?'bg-btn bg-opacity-40 border-br-green':'bg-white'} border-br-cream border py-2.5 rounded-md px-6`}>
            <span className='flex font-quick text-text text-[13px] leading-[16px] text-left ml-2'>{label}</span>
            {is_selected?<motion.img layout animate={{opacity:1,scale:1}} initial={{opacity:0,scale:0}} className='w-3 h-3 absolute left-3' src={require('../../../../../assets/icons/selected_circle.png')}/>:null}
        </motion.div>
    )
};



const get_emotions = (emotions:any) => {
    const keys = Object.keys(emotions);
    let array:any[] = [];
    keys.map((key:string)=>{
        array.push({
            id:fix_emotion(key),
            rate:emotions[key]
        });
    });
    const sorted = array.sort((a:any,b:any)=>b.rate-a.rate);
    let array2:any[] = [];
    sorted.map((item:any)=>{
        array2.push(item.id);
    });
    return array2;
};


const get_express_emotion = (emotions:string[]) => {
    let emo:any = [];

    emotions.map((i:string)=>{
        const parent = findItemAndParentObject(i, fix_express_id());
        if(parent){
            emo.push({parent:id_to_text(parent.parent),emotion:fix_emotion(i)});
        }
    });

    return emo;
};
                                  


const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["decon_thoughts_relevance"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            emotions:state.app["decon_emotions_rate_intensity"]?get_emotions(state.app["decon_emotions_rate_intensity"]):[],
            express_emotion:state.app?.circgen_type?.selected==='prepare_myself'? get_express_emotion(state.app['circgen_select_emotion'].selected):[],

        },
        thoughts_decon_fetched:state.response['thoughts_decon_fetched']?.response||state.response['thoughts_decon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected

    }
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'thoughts_decon_fetched',value}));
            dispatch(update_prompt_state({key:'thoughts_decon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'thoughts_decon_fetched',value:tools}));
         },
    }    
};


export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(ThoughtRelevance);