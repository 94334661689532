import { Auth } from "aws-amplify";


const stream_summary = async(values:any,onText:(text:string)=>void) => {

    return new Promise(async(resolve,reject)=>{
        try{
            const auth_res = await Auth.currentSession();
            const jwt = auth_res.getAccessToken().getJwtToken();
        
        
            const response = await fetch('https://6efh2o4ncx7r6bthva7ey3vb7u0jsdxh.lambda-url.us-east-1.on.aws/',{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(values),
            });
            let text = '';
            const reader = response.body?.getReader();
        
            while (true) {
                //  @ts-ignore
              const { done, value } = await reader.read();
              // console.log(done,value);
              if (done) {
                resolve(text);
                break;
              };
              const data = new TextDecoder().decode(value);
                text += data;
                onText(data);
            }
        }catch(e){
            reject(e)
        }
    })
};

export {stream_summary}