import React from 'react';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import EmotionIntensityRate from '../../../utils/components/EmotionIntensityRate';


class EmpoweringEmotionRate extends React.PureComponent<any,any>{
    options: any[];
    rating: any; 
    constructor(props:any) {
        super(props);
        this.options = [];
        const df = this.props.rating;
        this.rating = {...df}||{
        };
        this.props.emotions.map((item:any) => {
            this.options.push({
                id:item,
                // first letter capital
                name:capitalize(item)
            })
            if(isEmpty(df)){
                this.rating[item] = 1;
            }
        });
    };

    _next = () => {
        return {
            go: true,
            value:{
                ...this.rating
            }
        };
    };


    _on_change = (id:string,rate:number) => {
        this.rating[id] = rate;
    };

    _render_emotions = (item:any) => {
        return <EmotionIntensityRate noEmoji is_commitment rtl_label="Rate your commitment to this emotion:" defaultRate={this.props.rating[item.id]} onChange={this._on_change} {...item} key={item.id} />
    };
    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Embracing New Emotions</span>
                <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>
                Rate how committed you are to feeling or wanting to feel your selected emotions when you engage this circumstance in your new empowering way.
                <br />
                <br />
                Keep in mind that it is normal to not feel ready to embrace these emotions in this circumstance, even though you want to. We will help you address any obstacles that are blocking you from feeling this way at the end of this process.
                </span>
               
                <div className='pt-4 space-y-2'>
                    {this.options.map(this._render_emotions)}
                </div>

            </div>
        );
    };
};



const map_state_to_props = (state:RootState) => {
    return {
        emotions:state.app['recon_empowering_emotions']?.emotional_rating,
        rating:state.app['recon_empowering_emotions_rate']||{}
    };
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(EmpoweringEmotionRate);