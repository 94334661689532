import React from 'react';
import {useSpring,motion} from 'framer-motion';
import { useNavigate } from 'react-router-dom';


interface IProps {
    title?:string;
    light?:boolean;
    path?:string
    onClick?:()=>void;
    children?:any;
    className?:string
}

const Button = ({title,light,path,onClick,children,className}:IProps) => {

    const scale = useSpring(1);
    const navigate = useNavigate();


    const _down = () => {
        scale.set(1.1);
    };

    const _click = () => {
        onClick && onClick();
        setTimeout(()=>{
            scale.set(1);
        },100);
    };

    return (
        <motion.div onClick={_click} style={{scale}} onMouseUp={_down} className={`${!light?'bg-btn':'bg-sub-btn'} rounded-full ${children?'':'px-7 py-1 pt-[1.5px]'}  cursor-pointer ${className}`}>
         {children?children: <span className='font-quick text-xs text-text -mt-1 font-medium'>{title}</span>}
        </motion.div>
    );
};


export default Button;