import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gpt_steps, steps } from '../../../../utils/steps';
import { getValuesAfterItem } from '../utils';


interface PromptMetaState {
    [key:string]:any
};

const initialState:PromptMetaState = {
}

export const responseSlice = createSlice({
  name: 'prompt_meta',
  initialState,
  reducers: {
        update_prompt_state:(state,action:PayloadAction<{key:string,value:string}>)=>{
            const {key,value} = action.payload;
            if(typeof value === 'undefined') return;
            state[key as keyof PromptMetaState] = value;
        },

        clear_obslate_prompt:(state,action:PayloadAction<{current:string}>)=>{

            const current = action.payload.current;
            const gpt_keys = Object.keys(gpt_steps);
            // console.log(current)
            const obslete = getValuesAfterItem(steps,gpt_keys,current);
            // console.log(obslete);
            obslete.forEach(key=>{
                const items = gpt_steps[key as keyof typeof gpt_steps];
                items.forEach(item=>{
                    if(state[item as keyof PromptMetaState]){
                        delete state[item as keyof PromptMetaState];
                    };
                });
            });
        },


        clear_prompt_state:(state)=>{
            return initialState;
        }
        
  },
});

// Action creators are generated for each case reducer function
export const {update_prompt_state,clear_prompt_state,clear_obslate_prompt} = responseSlice.actions;

export default responseSlice.reducer;


