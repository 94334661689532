import React from 'react';
import EmojiCard from '../../utils/components/emojiCard';
import emojies from '../../utils/emoji/filterd_list.json';
import express_list from '../../utils/emoji/express_list.json';
import { connect } from 'react-redux';
import { RootState } from '../../../../db/redux/store';
import id_to_text from '../../utils/text/id_to_text';
import { arry_of_strings_to_text } from '../../utils/text';
import capitalize from 'lodash/capitalize';
import { findItemAndParentObject } from '../../../../utils';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';

class EmotionalRating extends React.PureComponent<any,any>{
    is_express: boolean;
    selected_parents: any;
    private _parent_container: any;

    constructor(props:any) {
        super(props);
        const df = this.props.selected?[...this.props.selected]:[]
        const ex = this.props.selected_express?{...this.props.selected_express}:{}
        this.state ={
            selected:df||[],
            selected_express:ex||{}
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself');
        this.selected_parents = {

        };
        if(!isEmpty(ex)){
            // df.map((item:string)=>{
            //     const parent = findItemAndParentObject(item, express_list);
            //     if(parent){
            //         this.selected_parents[parent.parent] = item;
            //     };
            // });
            Object.keys(ex).map((item:string)=>{
                this.selected_parents[item] = ex[item];
            });
        }

    };


    get_array_of_express_emotions = () => {
        let ar:string[] = [];
        Object.keys(this.state.selected_express).map((item:string)=>{
            ar.push(this.state.selected_express[item]);
        });
        return ar;
    };

    _set_parent_ref = (ref:any) => this._parent_container = ref;

    _next = () => {
        return {
            go: this.is_express? Object.keys(this.state.selected_express).length>=3:this.state.selected.length>=3,
            value:{
                selected:this.is_express?this.get_array_of_express_emotions():this.state.selected,
                selected_express:this.state.selected_express
            },
            msg:'Please select at least 3 emotions'
        }
    };

    _scroll_div_by_id_into_view = (id:string) => {
        const element = document.getElementById(`cover_${id}`);
        const offset = (element?.offsetTop||0) - this._parent_container.offsetTop;
        if(offset){
            this._parent_container.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        };
    };


    _on_select_emoji = (id:string,p?:string) => {
        // limit 3
        if(this.state.selected.length >= 3 && !this.state.selected.includes(id) && !this.is_express) return;

        let parent:any = {parent:''};
        if(this.is_express){
             parent = p? {parent:p}:findItemAndParentObject(id, express_list);
            this._scroll_div_by_id_into_view(parent.parent);
            if(this.selected_parents[parent.parent]){
                // console.log(this.selected_parents[parent.parent],parent.parent, this.state.selected);
                this.setState((prv:any)=>{
                    return{
                        selected:[...remove(prv.selected,(i:string)=>{;
                            return i!==this.selected_parents[parent.parent]
                        }),id],
                        selected_express:{
                            ...prv.selected_express,
                            [parent.parent]:id
                        },
                    };
                },()=>{
                  this.selected_parents[parent.parent] = id;
                  console.log(this.state);
                });
                return;
            };

            this.selected_parents[parent.parent] = id;
           
        }

        if(this.state.selected.includes(id)&&!this.is_express){
            // setSelected(selected.filter(item => item !== id));
            this.setState({
                selected:this.state.selected.filter((item:any) => item !== id)
            });
        }else{
            // setSelected([...selected,id]);
            this.setState((prv: { selected: any;selected_express:any })=>{
                return {
                    selected:[...prv.selected,id],
                    selected_express:{
                        ...prv.selected_express,
                        [parent.parent]:id
                    }
                }
            },()=>{
                console.log(this.state)
            })
        }



    };

    _render_grouped_emojies = (item: typeof express_list[0],index:number) => {
        return (
            <div key={item.id} id={`cover_${item.id}`} className='flex flex-col w-full'>
                <span className='font-quick text-text text-[13px] leading-[16px] font-semibold mt-5'>{item.label}</span>
                <div className={`flex flex-row flex-wrap justify-between pt-[5px]`}>
                {item.items.map(this._render_emojies)}
                </div>
            </div>
        )
    };

    _render_emojies = (item:typeof emojies[0]&{parent?:string},index:any) => {
        // smallFont={this.is_express}
        if(!item.id){
            return (
                <div className='w-[31%]'>

                </div>
            )  
        };
        return <EmojiCard  {...item}   key={`${item.id}`} selected={this.is_express ? this.state.selected_express[`${item.parent}`]===item.id : this.state.selected.includes(item.id)} onClick={this._on_select_emoji} />;
    };

    render(): React.ReactNode {
        return (
            <div ref={this._set_parent_ref} className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Emotional Impact</span>
                <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>
                "{this.is_express?`My ${this.props.theme} in a ${this.props.theme_parent} context relating to ${this.props.subtheme} that will happen in ${this.props.duration}.`:`${capitalize(this.props.domain)} relating to my ${this.props.subdomain} defined by themes: ${`${this.props.theme}`.toLowerCase()}, ${this.props.subtheme}.`}"
                <br />
                <br />
                {this.is_express?"What 3 disempowering emotions are you feeling most intensely?":"In your full experience of this circumstance, choose 3 of the most intense emotions you are feeling."}
                <br />
                <br />
                {this.is_express?null:"Later, you will have a chance to select more emotions."}</span>
                <div className='flex w-full flex-col mt-[18px]'>
                    <span className='flex font-raleway text-[12px] leading-[14px] text-title'>{this.is_express?'Choose 1 emotion per section.':`${this.state.selected.length} of 3 Emotions selected`}</span>
                    {this.is_express? express_list.map(this._render_grouped_emojies):
                    <div className={`flex flex-row flex-wrap justify-between pt-[19px]`}>
                        {emojies.map(this._render_emojies)}
                    </div>}
                </div>
            </div>
        );
    }
}



const map_state_to_props = (state:RootState) => {
    return {
        selected:state.app['circgen_select_emotion']?.selected,
        selected_express:state.app['circgen_select_emotion']?.selected_express,
        domain:id_to_text(state.app['circgen_vision_wheel']?.struggle_intense||''),
        subdomain:state.app['circgen_vision_wheel']?.focus?.replaceAll("_","-"),
        theme:id_to_text(state.app['circgen_theme']?.theme?.id||''),
        theme_parent:state.app?.circgen_type?.selected==='prepare_myself'?state.app['circgen_theme']?.theme?.parent:'',
        subtheme:arry_of_strings_to_text(id_to_text(state.app['circgen_theme']?.subtheme||'')),
        duration:id_to_text(state.app['circgen_duration_occurance']?.apprx[0]||''),
        circ:state.app?.circgen_type?.selected
    };
};

export default connect(map_state_to_props,null,null,{forwardRef:true})(EmotionalRating);