const arry_of_strings_to_text = (arr:string[]) => {
    if(arr.length===0){
        return '';
    };
    const join = arr.join(', ');
    // add & before last item
    const last = arr[arr.length-1];
    const last_index = join.lastIndexOf(last);
    const first = join.substring(0,last_index);
    const second = join.substring(last_index);
    return !first?second:`${first} & ${second}`.toLowerCase();
};

export {
    arry_of_strings_to_text
}