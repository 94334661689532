import React from 'react';
import RateText from '../../../utils/components/rate_text';
import { connect } from 'react-redux';
import { RootState } from '../../../../../db/redux/store';
import {motion} from 'framer-motion';
import { getHighestNumber } from '../../../../../utils/number/get_highest';
import { Dispatch } from 'redux';
import { update_response_state } from '../../../../../db/redux/features/gpt_response/response.slice';
import PromptLoader from '../../../../../common/componenets/prompt_loader';
import PromptError from '../../../../../common/componenets/prompt_error';
import { get_response } from '../../../../../utils/request/get';
import { format_rate_obj } from '../../../utils/formatters';
import { update_db } from '../../../../../utils/dynamoDB/fetch';
import { update_prompt_state } from '../../../../../db/redux/features/prompt_meta/prompt_meta.slice';
import { update_tools_state } from '../../../../../db/redux/features/tools/tools.slice';






class EmpoweringBelief extends React.PureComponent<any,any>{
    selected: any;
    is_express: boolean;


    constructor(props:any){
        super(props);
        const df = this.props.rating||{};
        const default_keys = Object.keys(df);
        this.state = {
            loading:this.props.belief_recon_fetched&&this.props.belief_recon_fetched.length>0?false:true,
            error:false,
            options:this.props.belief_recon_fetched||[],
            selected:[...default_keys],
            status_code:0
        };
        this.selected = { 
            ...df
        };  
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };

    componentDidMount(): void {
        if(!this.props.belief_recon_fetched||(this.props.belief_recon_fetched&&this.props.belief_recon_fetched.length===0)){
            this._fetch_beliefs();
        }
    };

    _retry = () => {
        if(this.state.loading){
            return;
        };
        this.selected = {};
        this.setState({selected:[]});
        this._fetch_beliefs();
    };


    _fetch_beliefs = async () => {
        try{
            if(this.state.error||!this.state.loading){
                this.setState({error:false,loading:true});
            };  
            const data:any = await get_response({type:'recon_beliefs',values:{
                ...this.props.api
            },include_prompt:true,isExpress:this.is_express});
            let options:any[] = [];
            data.res.map((txt:string)=>{
                options.push({
                    id:`${txt}`.replaceAll(' ','_'),
                    label:txt
                });
            });
            console.log(data);
            this.props.update_state(options,data.prompt,data.tools);
            update_db({value:options},this.props.session_id,'belief_recon_fetched','verve_demo_session_gpt_response')
            this.setState({options,loading:false,error:false});
            if(options.length===1){
                this._on_select(options[0].id);
            }
        }catch(e:any){
            this.setState({error:true,loading:false,status_code:e.statusCode||0});
        }
    }

    _on_change_rate = (id:string,rate:string) => {
        this.selected[id] = rate;
    };

    _on_select = (id:string) => {
        if(this.state.selected.includes(id)){
            this.setState((state:any)=>({
                selected:state.selected.filter((item:string)=>item!==id)
            }));
            delete this.selected[id];
        }else{
            this.setState((state:any)=>({
                selected:[...state.selected,id]
            }));
            this.selected[id] = 1;
        }
    };


    _back = () => {
        this.props.update_state([],null,[]);
        // update_db({value:[]},this.props.session_id,'belief_recon_fetched','verve_demo_session_gpt_response')

    };


    _next = () => {
        // const value = getHighestNumber(Object.values(this.selected));

        // if(!value){
        //     alert('Should only have one highest rated empowering belief.')
        //     return {
        //         go:false,
        //         value:{

        //         }
        //     };
        // };

        return {
            go:this.state.selected.length>0,
            value:{
                ...this.selected
            }
        }
    };
       
    _render_ratings = (item:any) => {
        return (
            <RateText is_commitment rl_text="Rate your commitment" onChangeRate={this._on_change_rate} defaultRate={this.selected&&this.selected[item.id]?this.selected[item.id]:1} {...item} key={item.id} selected={this.state.selected.includes(item.id)} onChange={this._on_select}/>
        )
    };

    render(): React.ReactNode {
        return (
            <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40 font-semibold">
                {
                    this.state.loading?
                    <PromptLoader message={this.is_express?'Based on your selected principles, here come new empowering beliefs to consider.':`Using your selected principles, we are now generating new empowering beliefs to replace the disempowering ones.`}/>
                    :
                    this.state.error?
                    <PromptError statusCode={this.state.status_code} onRetry={this._fetch_beliefs}/>
                    :
                <motion.div layout animate={{opacity:1}} initial={{opacity:0}}>
                    <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4">Embracing New Beliefs</span>
                    <span className='font-quick text-title text-[13px] leading-[16px] font-medium flex mb-4'>
                    {   this.is_express?
                        <>
                            Here are the beliefs that can help prepare you for your new, empowered approach.
                            <br />
                            <br />
                            Please select and rate your level of commitment to each empowering belief/s.
                        </>
                        :
                        <>
                        Here are the beliefs that can help empower you in this circumstance. 
                        <br />
                        <br />
                        Please select and rate your level of commitment based on how prepared and ready you are to embrace each of these empowering beliefs. 
                        <br />
                        <br />
                        If you are not rating them at full commitment, respecting your doubts and resistance will be the next important step to take and we will guide you as to how at the conclusion of this circumstance process. 
                    </>}
                    </span>
                    <span className='font-raleway font-medium text-[10px] leading-[12px] text-title flex pb-4'>Select all that apply.</span>
                    <motion.div layout className='space-y-2'>
                        {this.state.options.map(this._render_ratings)}
                    </motion.div>
                </motion.div>
                }
            </div>
        )
    }
};


const map_state_to_props = (state:RootState) => {
    return {
        rating:{
            ...state.app["recon_belief"],
        },
        api:{
            summary:state.response['summary_fetched']?.response||state.response['summary_fetched'],
            beliefs:format_rate_obj(state.app["decon_belief_rating"]),
            principals:format_rate_obj(state.app["decon_principle_deviation"]),
        },
        belief_recon_fetched:state.response['belief_recon_fetched']?.response||state.response['belief_recon_fetched'],
        session_id:state.app.session_seed,
        circ:state.app?.circgen_type?.selected
    }
};



const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {
         update_state:(value:any,prompt:string,tools:any)=>{
            dispatch(update_response_state({key:'belief_recon_fetched',value}));
            dispatch(update_prompt_state({key:'belief_recon_fetched',value:prompt}));
            dispatch(update_tools_state({key:'belief_recon_fetched',value:tools}));
         },
    }    
};

export default  connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(EmpoweringBelief);