import React, { useState } from 'react';
import DropDown from './dropDown';
import {motion} from 'framer-motion';
import IntensityRate from './intensityRate';


interface IProps {
    id: string;
    label: string;
    image: string 
    onImpactChange?:(id:string,value:string)=>void;
    onRateChange?:(id:string,value:number)=>void; 
    defaultImpact?:string;
    defaultRate?:number;
    dynamic?:boolean;
    newLayout?:boolean
};


const impact_timing = [
    {
        id:'no_impact',
        label: 'No Impact'
    },
    {
        id:'has_impacted_my_emotional_wellbeing',
        label: 'Has impacted my Emotional Well-being'
    },
    {
        id:'has_impacted_my_mental_wellbeing',
        label: 'Has impacted my Mental Well-being'
    },
    {
        id:'has_impacted_my_physical_wellbeing',
        label: 'Has impacted my Physical Well-being'
    },
];


const generate_dynamic_impact = (key:string) => {
    let to_return:any[] =[{   id:'no_impact',label: 'No Impact'}];
    const keys = ['Has previously impacted my','Is currently impacting my','Threatens to impact my'];
    keys.forEach((item)=>{
        to_return.push({
            id:`${item.replaceAll(' ','_')}_${key}`.toLowerCase(),
            label:`${item} ${key}`
        })
    });
    return to_return;
};



const AssessmentCard = ({id,label,image,onImpactChange,onRateChange,defaultImpact,defaultRate,dynamic,newLayout}:IProps) => {
    const options = dynamic?generate_dynamic_impact(label):impact_timing;
    const [selected,setSelected] = useState(defaultImpact||'no_impact');

    const get_default_value = (id:string) => {
        const val = options.find((item)=>item.id===id);
        return val||options[0];
    }

    const _on_rate_change = (rate:number) => {
        onRateChange && onRateChange(id,rate);
    };

    const _on_impact_change = (impact:string) => {
        if(impact==='no_impact'){
            onRateChange && onRateChange(id,0);
        };
        onImpactChange && onImpactChange(id,impact);
        setSelected(impact)
    };

    return (
        <motion.div layout className='rounded-2xl border border-br-gray flex flex-row'>
              {newLayout?null: <motion.img layout src={image} className="w-[72px] h-full rounded-tl-2xl rounded-bl-2xl object-cover"/>}
                <motion.div layout className='flex flex-col p-4 w-full'>
                    <span className='font-quick text-[13px] leading-[16px] text-text flex font-medium'>{label}</span>
                    <span className='font-raleway text-[10px] leading-[12px] text-text flex mt-2.5 font-medium'>Select the timing of impact:</span>
                    <div className='w-full flex mt-2.5 pb-2'>
                        <DropDown onValueChange={_on_impact_change} small  defaultValue={get_default_value(defaultImpact||'')} options={options}/>
                    </div>
                    {selected!=='no_impact'?<IntensityRate label={`Rate the impact of this situation to your ${label}`} noborder={newLayout} defaultRate={defaultRate} onChange={_on_rate_change} />:null}
                </motion.div>
        </motion.div>
    );
};







export default React.memo(AssessmentCard);