import React from 'react';
import AssesstmentCard from '../../utils/components/assesstmentCard';
import {motion} from 'framer-motion';
import { RootState } from '../../../../db/redux/store';
import { connect } from 'react-redux';
import { external_keys, subdomains } from '../../../../utils/vision_wheel';






class DeepDiveHighestRankingDomain extends React.PureComponent<any,any>{
    options: any[];
    selected: any;

    constructor(props:any) {
        super(props);
        this.options = subdomains[this.props.domain as keyof typeof subdomains]?subdomains[this.props.domain as keyof typeof subdomains]:[];
        this.selected = {
            ...this.props.item
        }
    };

    _next = () => {
        return {
            go: true,
            value:{
                ...this.selected
            }
        };
    };


    _on_impact_change = (id:string,impact:string) => {
        this.selected[id] = {
            ...this.selected[id],
            impact
        }
    };

    _on_rate_change = (id:string,rate:number) => {
        this.selected[id] = {
            ...this.selected[id],
            rate
        }
    };
    _render_lists = (item:typeof this.options[0]) => {
        return <AssesstmentCard newLayout={this.props.is_external} defaultImpact={this.props.item[item.id]?.impact} dynamic defaultRate={this.props.item[item.id]?.rate} onImpactChange={this._on_impact_change} onRateChange={this._on_rate_change} key={item.id} {...item}/>
    };


    render(): React.ReactNode {

    return (
        <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-96">
            <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">Looking Deeper</span>
            <span className='font-quick text-title text-[13px] leading-[16px] font-medium'>Now that you have completed evaluating the impact on your Inner Being and External World, let’s look even closer and improve your understanding of the impact.</span>
            <motion.div layout className='space-y-2 flex flex-col pt-[22px]'>
                    {this.options.map(this._render_lists)}
            </motion.div>
        </div>
    )
    }
};

const map_state_to_props = (state:RootState) =>{
    return {
        item:{
            ...state.app['circgen_deep_dive'],
        },
        domain:state.app['circgen_ranked_domain']?.selected,
        is_external:external_keys.includes(state.app['circgen_ranked_domain']?.selected as string)
    }
}

export default connect(map_state_to_props,null,null,{forwardRef:true})(DeepDiveHighestRankingDomain);