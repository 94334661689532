import { useState } from "react";
import {motion} from 'framer-motion';
interface IOption {
    id:string;
    label:string;
    image:string;
    description?:string
}

const HorizontalSlider = ({options,onChange,defaultValue,vertical=true}:{vertical?:boolean, defaultValue?:string|null,onChange?:(id:string|null)=>void,options:IOption[]}) => {

    const [selected,setSelected]=useState<null|string>(defaultValue||null);


    const _on_change = (id:string|null) => {
        onChange&&onChange(id);
        setSelected(id);
    }

    const _render_cards = (item:IOption,index:number) => {
        return <SliderCard vertical {...item} key={item.id} index={index} selected={selected===item.id} onChange={_on_change}/>
    };

    return (
        <div className="-mx-4">
            <div className={`w-full flex ${vertical?'flex-col':'flex-row overflow-x-scroll'} px-4  action_container`}>
                    <div className={`flex ${vertical?'flex-row flex-wrap justify-between':'flex-row space-x-2'} mt-4`}>
                            {options.map(_render_cards)}
                    </div>
            </div>
        </div>
   
    )
};

const SliderCard = ({id,onChange,label,selected,image,description,index,vertical}:IOption&{index:number,selected:boolean,onChange:(id:string|null)=>void,vertical:boolean})=>{

    const on_click = () => {
        if(selected)return;
        onChange(id);
    };

    return (
        <motion.div animate={{opacity:1,x:0,transition:{delay:.1*index}}} initial={{opacity:0,x:100}} layout onClick={on_click} className={`w-40 flex flex-col border cursor-pointer rounded-2xl border-br-gray relative ${selected?'border-br-green':''} ${vertical?'mb-2.5':''} flex-shrink-0 flex-grow-0`}>
            {!selected?null:<motion.img animate={{scale:1,opacity:1}} initial={{scale:0,opacity:0}} layout className="w-5 h-5 absolute -left-1.5 -top-1.5 rounded-full border border-white bg-white" src={require('../../../../assets/icons/selected_circle.png')}/>}
            <img src={image} className="w-full h-[72px] object-cover rounded-tl-2xl rounded-tr-2xl"/>
            <div className="p-2 pb-4">
                <span className="flex font-quick text-[13px] leading-[16px] text-text font-medium">{label}</span>
                {description?<span className="text-title font-raleway text-[10px] mt-2 flex leading-[12px] font-medium">{description}</span>:null}
            </div>
        </motion.div>
    )
};

export default HorizontalSlider;